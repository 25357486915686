// React
import { createContext, useState } from "react";

// Context
const InstantQuoteContext = createContext();

export const initializedUser = {
  attributes: { name: "None", email: "None" },
};

// Context Provider
const InstantQuoteProvider = (props) => {
  const itemPriceRounding = 25;

  const [userInputProgress, setUserInputProgress] = useState({
    isProjectNameSet: false,
    isProjectLocationSet: false,
    isEditProject: false,
    isSaveProject: false,
    isEditQuote: false,
  });

  const instantQuoteApi =
    `${process.env.REACT_APP_CONTRACT_ENGINE_API_URL}`;

  const [organizationHasBeenSelected, setOrganizationHasBeenSelected] =
    useState(false);

  const [isMultiFam, setIsMultiFam] = useState(false);

  const [sendQuoteModalSelected, setSendQuoteModalSelected] = useState(false);

  const [isQuoteView, setIsQuoteView] = useState(false);

  const [projectAddressList, setProjectAddressList] = useState();

  const [user, setUser] = useState(initializedUser);

  const [organizationName, setOrganizationName] = useState("");

  const [packageName, setPackageName] = useState("");

  const [userContentModalOpen, setUserContentModalOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const [existingProjectAddressOpen, setExistingProjectAddressOpen] =
    useState(false);

  const [projectHasMinimumRequiredInputs, setProjectHasMinimumRequiredInputs] =
    useState(false);

  const [distanceMatrixData, setDistanceMatrixData] = useState({
    distance: 0,
    time: 0,
  });

  const [projectId, setProjectId] = useState("");

  const [authorizerName, setAuthorizerName] = useState("");

  const [authorizerEmail, setAuthorizerEmail] = useState("");

  const [projectRecord, setProjectRecord] = useState(); // the datastore object

  const [projectName, setProjectName] = useState("");

  const [organization, setOrganization] = useState("");

  const [squareFootage, setSquareFootage] = useState({
    restaurant: 0,
    warehouse: 0,
    brewery: 0,
    office: 0,
    multiFamily: 0,
    uniqueLayouts: 1,
    totalSquareFootage: 0,
    medical: 0,
  });

  const [location, setLocation] = useState({
    lat: 37.52,
    lng: -77.489,
  });

  // For the GoogleMaps.js file to know the location has been changed
  const [changeInLocation, setChangeInLocation] = useState(false);

  // For the DonutChart.js file to know there is change in squareFootage field values
  const [changeInSquareFootage, setChangeInSquareFootage] = useState(false);

  // For the Category.js file to know there is change in package field values
  const [changeInPackageCalculation, setChangeInPackageCalculation] =
    useState(false);

  const [discipline, setDiscipline] = useState({
    mechanical: 0,
    electrical: 0,
    plumbing: 0,
  });

  const [address, setAddress] = useState("");

  const [constructionType, setConstructionType] = useState({
    isGroundUp: 1,
    isAdaptiveReuse: 0,
  });

  const [surveyType, setSurveyType] = useState({
    isVirtual: 0,
    isInPerson: 1,
  });

  const [adaptiveReuse, setAdaptiveReuse] = useState({
    cleanSlate: 0,
    recycledSystem: 1,
  })

  const calculation = {
    electrical: 0,
    plumbing: 0,
    mechanical: 0,
    baseFee: 0,
    subTotal: 0,
  };

  const packages = {
    gist: 0,
    minimalist: 0,
    collaborator: 0,
    perfectionist: 0,
    twoWeek: 0,
  };

  const contractPricingConfigurations = {
    projectSetupFee: 600,
    kitchenSetupFee: 750,
    brewerySetupFee: 300,

    percentProgressAtSchematicDesign: 0.2,
    percentProgressAtDesignDevelopment: 0.4,
    percentProgressAtConstructionDocuments: 0.4,
    percentForSchematicDesignIteration: 0.15,
    percentForDesignDevelopmentIteration: 0.3,

    minimumAdditionalServicesHoursPerTradeByPercent: 0.3,
    minimumAdditionalServicesHoursPerTrade: 2,
    percentageOfContingencyHoursExpedited: 0.1,

    pricePerUniqueMultiFamilyLayout: 750,

    adaptiveReusePremiumCleanSlate: 0.2,
    adaptiveReusePremiumRecycledSystem: 0.6,

    additionalServicesBillableRate: 250,
    expeditedRate: 500,

    inPersonSurveyFee: 950,
  };

  const deliverableQuantity = {
    gist: {
      schematicDesign: 1,
      preProductionMiniTasks: 0,
      constructionDocument: 0,
      designDevelopment: 0,
      inProductionRedesign: 0,
      planReviewComments: false,
      submittalReview: false,
    },
    minimalist: {
      schematicDesign: 1,
      preProductionMiniTasks: 1,
      constructionDocument: 1,
      designDevelopment: 1,
      inProductionRedesign: 0,
      planReviewComments: true,
      submittalReview: true,
    },
    collaborator: {
      schematicDesign: 1,
      preProductionMiniTasks: 4  ,
      constructionDocument: 1,
      designDevelopment: 1,
      inProductionRedesign: 1,
      planReviewComments: true,
      submittalReview: true,
    },
    perfectionist: {
      schematicDesign: 1,
      preProductionMiniTasks: 6,
      constructionDocument: 1,
      designDevelopment: 1,
      inProductionRedesign: 3,
      planReviewComments: true,
      submittalReview: true,
    },
  };

  const disciplinePricingConfigurations = [
    {
      discipline: "electrical",
      setupFees: 500,
      pricePerSquareFootBase: 0.28,
      baseSquareFootageMultiplier: [
        {
          category: "restaurant",
          multiplier: 1.5,
        },
        {
          category: "brewery",
          multiplier: 1.25,
        },
        {
          category: "office",
          multiplier: 1,
        },
        {
          category: "medical",
          multiplier: 4,
        },
        {
          category: "multiFamily",
          multiplier: [0.1181, 0.17629],
        },
        {
          category: "warehouse",
          multiplier: [0.04, 0.075],
        },
      ],
      specialtySetupFees: [
        {
          category: "restaurant",
          multiplier: 2500,
        },
        {
          category: "brewery",
          multiplier: 1000,
        },
      ],
    },
    {
      discipline: "plumbing",
      setupFees: 500,
      pricePerSquareFootBase: 0.28,
      baseSquareFootageMultiplier: [
        {
          category: "restaurant",
          multiplier: 1.5,
        },
        {
          category: "brewery",
          multiplier: 1.25,
        },
        {
          category: "office",
          multiplier: 0.5,
        },
        {
          category: "medical",
          multiplier: 4,
        },
        {
          category: "multiFamily",
          multiplier: [0.14, 0.2074],
        },
        {
          category: "warehouse",
          multiplier: [0.04, 0.075],
        },
      ],
      specialtySetupFees: [
        {
          category: "restaurant",
          multiplier: 2500,
        },
        {
          category: "brewery",
          multiplier: 1000,
        },
      ],
    },
    {
      discipline: "mechanical",
      setupFees: 750,
      pricePerSquareFootBase: 0.3,
      baseSquareFootageMultiplier: [
        {
          category: "restaurant",
          multiplier: 1.5,
        },
        {
          category: "brewery",
          multiplier: 1.25,
        },
        {
          category: "office",
          multiplier: 1,
        },
        {
          category: "medical",
          multiplier: 4,
        },
        {
          category: "multiFamily",
          multiplier: [0.1181, 0.17629],
        },
        {
          category: "warehouse",
          multiplier: [0.075, 0.095],
        },
      ],
      specialtySetupFees: [
        {
          category: "restaurant",
          multiplier: 750,
        },
        {
          category: "brewery",
          multiplier: 250,
        },
      ],
    },
  ];

  //To validate the email
  const validateEmail = (emailAddress) => {
    return /^\w+([.-]?\w+)@\w+([.-]?\w+)\.(\w{2,3})+$/.test(emailAddress);
  };

  // For calculating multi family per square feet
  const multiFamilyMultiplier = (a, b) => {
    const c = -squareFootage["multiFamily"] * 0.0001;
    return a * Math.exp(c) + b;
  };

  // For calculating warehouse per square feet
  const warehouseMultiplier = (a, b) => {
    const c = -squareFootage["warehouse"] * 0.000008;
    return a * Math.exp(c) + b;
  };

  // For calculating global project setup
  const calculateGlobalProjectSetup = () => {
    calculation["baseFee"] = contractPricingConfigurations["projectSetupFee"];
    if (squareFootage["restaurant"] > 0)
      calculation["baseFee"] +=
        contractPricingConfigurations["kitchenSetupFee"];
    if (squareFootage["brewery"] > 0)
      calculation["baseFee"] +=
        contractPricingConfigurations["brewerySetupFee"];
    return calculation["baseFee"];
  };

  function getDiscipline(arrObj, value) {
    return arrObj.filter((obj) => {
      return obj.discipline === value || obj.category === value;
    })[0];
  }

  // For calculating subTotal
  const calculateBaseSubTotal = () => {
    let totalNumberOfIncludedDiscipline = 0
    for (const trade in discipline) {
      if (discipline[trade] === 1) {
        totalNumberOfIncludedDiscipline ++
        const dis = getDiscipline(disciplinePricingConfigurations, trade);
        calculation[trade] =
          dis["setupFees"] +
          dis["pricePerSquareFootBase"] *
            (squareFootage["restaurant"] *
              getDiscipline(dis["baseSquareFootageMultiplier"], "restaurant")[
                "multiplier"
              ] +
              squareFootage["brewery"] *
                getDiscipline(dis["baseSquareFootageMultiplier"], "brewery")[
                  "multiplier"
                ] +
              squareFootage["medical"] *
                getDiscipline(dis["baseSquareFootageMultiplier"], "medical")[
                  "multiplier"
                ] +
              squareFootage["office"] *
                getDiscipline(dis["baseSquareFootageMultiplier"], "office")[
                  "multiplier"
                ]);
        // if (squareFootage["multiFamily"] > 0)
        //   calculation[trade] +=
        //     contractPricingConfigurations["pricePerUniqueMultiFamilyLayout"] *
        //     squareFootage["uniqueLayouts"];

        if (squareFootage["restaurant"] > 0)
          calculation[trade] += getDiscipline(
            dis["specialtySetupFees"],
            "restaurant"
          )["multiplier"];
        if (squareFootage["brewery"] > 0)
          calculation[trade] += getDiscipline(
            dis["specialtySetupFees"],
            "brewery"
          )["multiplier"];
        const multiFam = getDiscipline(
          dis["baseSquareFootageMultiplier"],
          "multiFamily"
        )["multiplier"];
        calculation[trade] +=
          multiFamilyMultiplier(multiFam[0], multiFam[1]) *
          squareFootage["multiFamily"];
        const warehouse = getDiscipline(
          dis["baseSquareFootageMultiplier"],
          "warehouse"
        )["multiplier"];
        calculation[trade] +=
          warehouseMultiplier(warehouse[0], warehouse[1]) *
          squareFootage["warehouse"];
        calculation[trade] = Math.round(calculation[trade])
      }
    }
    let multiFamLayoutSetUpFee = 0
    if (squareFootage["multiFamily"] > 0)
      multiFamLayoutSetUpFee = totalNumberOfIncludedDiscipline * contractPricingConfigurations["pricePerUniqueMultiFamilyLayout"] *
      squareFootage["uniqueLayouts"];
    calculation["subTotal"] =
      calculation["electrical"] +
      calculation["mechanical"] +
      calculation["plumbing"] + 
      multiFamLayoutSetUpFee;
    calculation["subTotal"] += calculateGlobalProjectSetup();
    if (constructionType["isAdaptiveReuse"] === 1){
      if (adaptiveReuse['cleanSlate'] === 1)
        calculation["subTotal"] *=
          1 + contractPricingConfigurations["adaptiveReusePremiumCleanSlate"];
      else
        calculation["subTotal"] *=
          1 + contractPricingConfigurations["adaptiveReusePremiumRecycledSystem"];
    }
    calculation["subTotal"] = Math.round(calculation["subTotal"]);
  };
  
  const deliverableItemPrice = {
    schematicDesign: 0,
    preProductionMiniTasks: 0,
    constructionDocument: 0,
    designDevelopment: 0,
    inProductionRedesign: 0,
    preliminaryFieldVisit: 0,
    meetings: 0,
    additionalServicesHours:
      contractPricingConfigurations["additionalServicesBillableRate"],
  };

  const roundOff = (value, roundOffValue) => {
    if (Math.round(value) % roundOffValue !== 0) {
      value =
        Math.round(value) +
        (roundOffValue - (Math.round(value) % roundOffValue));
    }
    return Math.round(value);
  };

  const calculateTotalContingencyHours = () => {
    let totalContingencyHours = 0
    for(const trade in discipline){
      var contingencyHours = 0
      if(discipline[trade] === 1){
          if(squareFootage['multiFamily'] > 0)
              contingencyHours = Math.ceil(Math.max(((squareFootage['uniqueLayouts'] * contractPricingConfigurations['pricePerUniqueMultiFamilyLayout']) + calculation[trade]) * 
              contractPricingConfigurations['minimumAdditionalServicesHoursPerTradeByPercent'] / contractPricingConfigurations['additionalServicesBillableRate'],
              contractPricingConfigurations["minimumAdditionalServicesHoursPerTrade"]))
          else
              contingencyHours = Math.ceil(Math.max(calculation[trade] * contractPricingConfigurations['minimumAdditionalServicesHoursPerTradeByPercent'] / 
              contractPricingConfigurations['additionalServicesBillableRate'],
              contractPricingConfigurations["minimumAdditionalServicesHoursPerTrade"]))
      }
      totalContingencyHours += contingencyHours
    }
    return totalContingencyHours
  }

  // For calculating total for each package
  // Calculation for Additional In-Person Surveys and Additional meetings (or virtual surveys) are not done
  const packagePriceTotal = () => {
    calculateBaseSubTotal();
    for (const pkg in packages) {
      if (pkg === "twoWeek") continue;
      deliverableItemPrice["schematicDesign"] = roundOff(
        calculation["subTotal"] *
          contractPricingConfigurations["percentProgressAtSchematicDesign"],
        itemPriceRounding
      );
      const schematicDesignPriceTotal =
        deliverableItemPrice["schematicDesign"] *
        deliverableQuantity[pkg]["schematicDesign"];
      deliverableItemPrice["designDevelopment"] = roundOff(
        calculation["subTotal"] *
          contractPricingConfigurations["percentProgressAtDesignDevelopment"],
        itemPriceRounding
      );
      const designDevelopmentPriceTotal =
        deliverableItemPrice["designDevelopment"] *
        deliverableQuantity[pkg]["designDevelopment"];
      deliverableItemPrice["constructionDocument"] = roundOff(
        calculation["subTotal"] *
          contractPricingConfigurations[
            "percentProgressAtConstructionDocuments"
          ],
        itemPriceRounding
      );
      const constructionDocumentsPriceTotal =
        deliverableItemPrice["constructionDocument"] *
        deliverableQuantity[pkg]["constructionDocument"];
      // contractPricingConfigurations sub total for each package
      const baseSubTotal =
        schematicDesignPriceTotal +
        designDevelopmentPriceTotal +
        constructionDocumentsPriceTotal;
      deliverableQuantity[pkg]["preliminaryFieldVisit"] =
        constructionType["isAdaptiveReuse"];
      if (surveyType["isVirtual"] === 1)
        deliverableItemPrice["preliminaryFieldVisit"] = roundOff(
          (discipline["electrical"] +
            discipline["mechanical"] +
            discipline["plumbing"]) *
            contractPricingConfigurations["additionalServicesBillableRate"],
          itemPriceRounding
        );
      else
        deliverableItemPrice["preliminaryFieldVisit"] = roundOff(
            contractPricingConfigurations["inPersonSurveyFee"],
            itemPriceRounding
          );
      const preliminaryFieldVisit =
        deliverableQuantity[pkg]["preliminaryFieldVisit"] *
        deliverableItemPrice["preliminaryFieldVisit"];
      deliverableItemPrice["preProductionMiniTasks"] = roundOff(
        baseSubTotal *
          contractPricingConfigurations["percentForSchematicDesignIteration"],
        itemPriceRounding
      );
      if (pkg === "gist")
        deliverableItemPrice["preProductionMiniTasks"] /= 0.4; //Currently roundoff not applied
      const preProductionMiniTasks =
        deliverableItemPrice["preProductionMiniTasks"] *
        deliverableQuantity[pkg]["preProductionMiniTasks"];
      deliverableItemPrice["inProductionRedesign"] = roundOff(
        baseSubTotal *
          contractPricingConfigurations["percentForDesignDevelopmentIteration"],
        itemPriceRounding
      );
      const inProductionRedesign =
        deliverableItemPrice["inProductionRedesign"] *
        deliverableQuantity[pkg]["inProductionRedesign"];
      deliverableItemPrice["meetings"] = roundOff(
        (discipline["electrical"] +
          discipline["mechanical"] +
          discipline["plumbing"]) *
          contractPricingConfigurations["additionalServicesBillableRate"],
        itemPriceRounding
      );
      deliverableQuantity[pkg]["meetings"] =
        deliverableQuantity[pkg]["schematicDesign"] +
        deliverableQuantity[pkg]["preProductionMiniTasks"] + 
        deliverableQuantity[pkg]["inProductionRedesign"];
      const meetings =
        deliverableItemPrice["meetings"] *
        deliverableQuantity[pkg]["meetings"];
      deliverableQuantity[pkg]["additionalServicesHours"] = calculateTotalContingencyHours()     
      if (pkg === "collaborator")
        deliverableQuantity[pkg]["additionalServicesHours"] *= 2;
      if (pkg === "perfectionist")
        deliverableQuantity[pkg]["additionalServicesHours"] *= 4;
      const totalGeneralUseHours =
        deliverableQuantity[pkg]["additionalServicesHours"] *
        roundOff(
          contractPricingConfigurations["additionalServicesBillableRate"],
          itemPriceRounding
        );
      let totalUrgentAdditionalServicesHours = 0
      if(pkg === "perfectionist")
        totalUrgentAdditionalServicesHours = 
          Math.round(deliverableQuantity[pkg]["additionalServicesHours"] *
          contractPricingConfigurations['percentageOfContingencyHoursExpedited'] *
          contractPricingConfigurations['expeditedRate'])
      const supportSubtotal =
        preliminaryFieldVisit +
        preProductionMiniTasks +
        inProductionRedesign +
        meetings +
        totalGeneralUseHours +
        totalUrgentAdditionalServicesHours;
      packages[pkg] = baseSubTotal + supportSubtotal;
      if (packages[pkg] > 30000 && packages[pkg] % 1000 !== 0)
        packages[pkg] = (parseInt(packages[pkg] / 1000) + 1) * 1000;
      else if (packages[pkg] % 100 !== 0)
        packages[pkg] = (parseInt(packages[pkg] / 100) + 1) * 100;
    }
    packages["twoWeek"] = packages["minimalist"] * 1.4;
    if (packages["twoWeek"] > 30000 && packages["twoWeek"] % 1000 !== 0)
      packages["twoWeek"] = (parseInt(packages["twoWeek"] / 1000) + 1) * 1000;
    else if (packages["twoWeek"] % 100 !== 0)
      packages["twoWeek"] = (parseInt(packages["twoWeek"] / 100) + 1) * 100;
    return packages;
  };

  function addCommaToPrice(price) {
    const numFormat = Intl.NumberFormat("en-US");
    let formattedPrice = numFormat.format(price);
    return "$ " + formattedPrice;
  }

  const contextData = {
    instantQuoteApi,
    projectName,
    setProjectName,
    deliverableItemPrice,
    deliverableQuantity,
    location,
    setLocation,
    changeInLocation,
    setChangeInLocation,
    squareFootage,
    setSquareFootage,
    changeInSquareFootage,
    setChangeInSquareFootage,
    discipline,
    setDiscipline,
    address,
    setAddress,
    setConstructionType,
    constructionType,
    packagePriceTotal,
    packages,
    setChangeInPackageCalculation,
    changeInPackageCalculation,
    surveyType,
    setSurveyType,
    contractPricingConfigurations,
    disciplinePricingConfigurations,
    projectId,
    setProjectId,
    projectHasMinimumRequiredInputs,
    setProjectHasMinimumRequiredInputs,
    organizationName,
    setOrganizationName,
    projectRecord,
    setProjectRecord,
    packageName,
    setPackageName,
    user,
    setUser,
    userInputProgress,
    setUserInputProgress,
    sendQuoteModalSelected,
    setSendQuoteModalSelected,
    addCommaToPrice,
    setOrganization,
    organization,
    setOrganizationHasBeenSelected,
    organizationHasBeenSelected,
    setIsMultiFam,
    isMultiFam,
    authorizerName,
    setAuthorizerName,
    authorizerEmail,
    setAuthorizerEmail,
    projectAddressList,
    setProjectAddressList,
    userContentModalOpen,
    setUserContentModalOpen,
    existingProjectAddressOpen,
    setExistingProjectAddressOpen,
    validateEmail,
    index,
    setIndex,
    isQuoteView,
    setIsQuoteView,
    distanceMatrixData,
    setDistanceMatrixData,
    adaptiveReuse, 
    setAdaptiveReuse
  };

  return (
    <InstantQuoteContext.Provider value={contextData}>
      {props.children}
    </InstantQuoteContext.Provider>
  );
};

export { InstantQuoteContext, InstantQuoteProvider };
