// React
import { useEffect, useContext, useState } from "react";

// Third-party libraries
import Swal from "sweetalert2";
// Amplify
import { Flex, Autocomplete } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";

// Gadget Engineering libraries
import QuoteSnapshotCardCollection from "../ui-components/QuoteSnapshotCardCollection";
import { Quote, Organization, User } from "../models";
import { InstantQuoteContext } from "../Context/ProposalContext";
import QuoteViewCard from "./QuoteViewCard";

export default function PreviousQuotesView(props) {
  // Props
  const { setOpen, ...rest } = props;

  const { user, addCommaToPrice, setIsQuoteView, userContentModalOpen } =
    useContext(InstantQuoteContext);

  useEffect(() => {
    getOrg();
    selectedQuote(orgId, userId)
    if (user["signInUserSession"]) {

      const userGroup =
        user["signInUserSession"]["idToken"]["payload"]["cognito:groups"];
      if (userGroup.includes("Admin")) {
        setIsAdmin(true);
      }
    }
  }, [userContentModalOpen]);

  const [isAdmin, setIsAdmin] = useState(false);
  const [orgList, setOrgList] = useState();
  const [userList, setUserList] = useState();
  const [userName, setUserName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgId, setOrgId] = useState("");
  const [userId, setUserId] = useState("");
  const [quoteList, setQuoteList] = useState();
  const [quoteOpen, setQuoteOpen] = useState(false);

  const [indexOfItem, setIndexOfItem] = useState(-1);

  const numberOfDays = 30;

  async function getOrg() {
    const org = await DataStore.query(Organization);
    var orgOption = [];
    for (let orgObj of org)
      orgOption.push({ id: orgObj.id, label: orgObj.name });
    setOrgList(orgOption);
    const users = await DataStore.query(User);
    var userOption = [];
    for (let userObj of users)
      userOption.push({ id: userObj.id, label: userObj.email });
    setUserList(userOption);
  }

  async function selectedOrg(e) {
    setOrgName(e.label);
    setOrgId(e.id);
    selectedQuote(e.id, userId);
  }

  async function selectedUser(e) {
    setUserName(e.label);
    setUserId(e.id);
    selectedQuote(orgId, e.id);
  }

  async function selectedQuote(orgId, userId) {
    var quotes;
    if (orgId && userId) {
      quotes = await DataStore.query(Quote, (c) =>
        c.and((c) => [
          c.organizationQuotesId.eq(orgId),
          c.userQuotesId.eq(userId),
        ])
      );
    } else if (orgId) {
      quotes = await DataStore.query(Quote, (c) =>
        c.organizationQuotesId.eq(orgId)
      );
    } else if (userId) {
      quotes = await DataStore.query(Quote, (c) => c.userQuotesId.eq(userId));
    } else {
      quotes = await DataStore.query(Quote);
    }
    quotes.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    setQuoteList(quotes);
  }

  async function noUser() {
    setUserName("");
    setUserId("");
    selectedQuote(orgId, null);
  }

  async function noOrg() {
    setOrgName("");
    setOrgId("");
    selectedQuote(null, userId);
  }

  async function deleteQuote(quote) {
    const currentQuote = await DataStore.query(Quote, quote.id);
    await DataStore.delete(currentQuote).then(() => {
      Swal.fire({
        icon: "success",
        title: "Quote Deleted!",
        text: "Quote was deleted successfully!",
      }).then(() => window.location.reload());
    });
  }

  function textReveal(name, index, maxLength) {
    if (index === indexOfItem) return [index, name];
    if (name.length > maxLength) {
      const val = name.slice(0, maxLength - 3) + "...";
      return [index, val];
    } else return [index, name];
  }

  function saveQuoteId(id) {
    if (localStorage.getItem("quoteId")) localStorage.removeItem("quoteId");
    if (localStorage.getItem("projectId")) localStorage.removeItem("projectId");
    localStorage.setItem("quoteId", id);
    window.location.reload();
  }

  // States for the QuotesViewCard
  const [quoteDetails, setQuoteDetails] = useState({
    name: "",
    OrgName: "",
    address: "",
    totalValue: "",
    constructionType: "",
    surveyType: "",
    preferredContractPackage: "",
    authorizerName: "",
    authorizerEmail: "",
    sd: "",
    dd: "",
    cd: "",
    caHours: "",
  });

  const [quoteSqft, setQuoteSqft] = useState({
    restaurant: 0,
    warehouse: 0,
    brewery: 0,
    office: 0,
    multiFamily: 0,
    uniqueLayouts: 1,
    totalSquareFootage: 0,
    medical: 0,
  });
  const [quoteDisciplines, setQuoteDisciplines] = useState({
    mechanical: 0,
    electrical: 0,
    plumbing: 0,
  });

  async function getQuoteDetails(quote) {
    setIsQuoteView(true);

    const org = await DataStore.query(Organization, quote.organizationQuotesId);
    quoteDetails["OrgName"] = org.name;
    quoteDetails["name"] = quote.name;
    quoteDetails["address"] = quote.projectInputs?.projectLocation.address;
    quoteDetails["preferredContractPackage"] =
      quote.projectInputs.preferredContractPackage;
    quoteDetails["totalValue"] = quote.totalValue;
    quoteDetails["constructionType"] = quote.projectInputs.constructionType;
    quoteDetails["surveyType"] = quote.projectInputs.surveyType;
    quoteDetails["authorizerName"] = quote.authorizerName;
    quoteDetails["authorizerEmail"] = quote.authorizerEmail;
    quoteDetails["sd"] = quote.quotedSd;
    quoteDetails["dd"] = quote.quotedDd;
    quoteDetails["cd"] = quote.quotedCd;
    quoteDetails["caHours"] = quote.quotedCAHours;

    const SqftName = {
      brewerySqf: "brewery",
      multifamilySqf: "multiFamily",
      officeAndRetailSqf: "office",
      restaurantSqf: "restaurant",
      uniqueMultifamilyLayouts: "uniqueLayouts",
      warehouseSqf: "warehouse",
      medicalSqf: "medical",
      totalSqf: "totalSquareFootage",
    };

    for (let sqft in SqftName) {
      if (quote.projectInputs[sqft])
        quoteSqft[SqftName[sqft]] = quote.projectInputs[sqft];
      else quoteSqft[SqftName[sqft]] = 0;
    }
    setQuoteSqft(quoteSqft);

    const tradeDisciplineName = {
      mechanical: "MECH",
      electrical: "ELEC",
      plumbing: "PLUMB",
    };
    for (let trade in tradeDisciplineName) {
      if (quote.tradeSupervisions.includes(tradeDisciplineName[trade]))
        quoteDisciplines[trade] = 1;
      else quoteDisciplines[trade] = 0;
    }
    setQuoteDisciplines(quoteDisciplines);
    setQuoteDetails(quoteDetails);
    setQuoteOpen(true);
  }

  function changeDateFormat(date) {
    const [year, month, day] = date.split("-");
    const newFormat = [month, day, year].join("/");
    return newFormat;
  }

  function getLocalDate(date, numberOfDays) {
    let localDateTime = new Date(date);
    if (numberOfDays) localDateTime.setDate(localDateTime.getDate() + (numberOfDays));
    let localDay = "" + localDateTime.getDate();
    let localMonth = "" + (localDateTime.getMonth() + 1);
    let localYear = localDateTime.getFullYear();
    if (localDay.length < 2) localDay = "0" + localDay;
    if (localMonth.length < 2) localMonth = "0" + localMonth;
    let localDate = [localYear, localMonth, localDay].join(
      "-");
    return localDate
  }

  function isExpired(date) {
    const warningDay = 7;
    let today = new Date().toJSON().slice(0, 10);
    let expirationDate = getLocalDate(date, numberOfDays);
    let difference = new Date(expirationDate) - new Date(today);
    let daysLeft = difference / (1000 * 3600 * 24);
    if (daysLeft < 0) return "expired";
    else if (daysLeft <= warningDay) return "warning";
    else return "info";
  }

  function isDeletable(quote) {
    return isAdmin || (quote.editors && quote.editors.includes(user.username))
  }

  const alertBoxDetails = {
    expired: {
      heading: "Expired on ",
      variation: "error",
      children: "Review associated project to request new quote!",
    },
    warning: {
      heading: "Expires soon!",
      variation: "warning",
      children: "Quote expires on ",
    },
    info: {
      heading: "Expires on ",
      variation: "info",
      children: "",
    },
  };

  const Css = `
  .swal2-container.swal2-center.swal2-backdrop-show {
    z-index:9999;
}
 .amplify-pagination__item-button, .amplify-pagination__item-button--link{
  color: black;
}
.amplify-pagination__item-button:hover{
  color: black;
}

.amplify-flex {
  justify-content:center;
}
.amplify-flex.amplify-collection-search {
  justify-content:center;
}

.iconHoverToChange:hover{
  background: lightblue;
  cursor: pointer;
 border-radius: 10px;
  transition:0.2s ease-out;
}
`;

  return (
    <>
      <style>{Css}</style>
      <Flex margin="20px 0">
        <Autocomplete
          label="Organization"
          options={orgList}
          placeholder="Filter by Organization"
          onSelect={(e) => selectedOrg(e)}
          defaultValue={orgName}
          onChange={(e) => {
            noOrg();
          }}
          onClear={() => {
            noOrg();
          }}
        />
      </Flex>
      {isAdmin && (
        <Flex margin="20px 0">
          <Autocomplete
            label="User"
            options={userList}
            placeholder="Filter by User"
            onSelect={(e) => selectedUser(e)}
            defaultValue={userName}
            onChange={(e) => {
              noUser();
            }}
            onClear={() => {
              noUser();
            }}
          />
        </Flex>
      )}
      <Flex
        direction={{
          base: "column",
          small: "column",
          medium: "column",
          large: "row",
          xl: "row",
        }}
      >
        <QuoteSnapshotCardCollection
          searchPlaceholder="Search Name or Address…"
          items={quoteList}
          maxWidth={"850px"}
          itemsPerPage={4}
          overrideItems={({ item, index }) => ({
            overrides: {
              FrameOfReference: {
                // height: "auto",
              },
              proposalExpirationWarning: {
                height: "auto",
                heading:
                  isExpired(item.createdAt) === "warning"
                    ? alertBoxDetails["warning"]["heading"]
                    : `${alertBoxDetails[isExpired(item.createdAt)]["heading"]
                    }${changeDateFormat(getLocalDate(item.createdAt, numberOfDays))}`,
                variation:
                  alertBoxDetails[isExpired(item.createdAt)]["variation"],
                display: "block",
                children:
                  isExpired(item.createdAt) === "warning"
                    ? `${alertBoxDetails[isExpired(item.createdAt)]["children"]
                    }${changeDateFormat(getLocalDate(item.createdAt, numberOfDays))}`
                    : alertBoxDetails[isExpired(item.createdAt)]["children"],
              },
              ProjectName: {
                children:
                  textReveal(item.name, index)[0] === index &&
                  textReveal(item.name, index)[1],
                onMouseEnter: () => setIndexOfItem(index),
                onMouseLeave: () => setIndexOfItem(-1),
              },
              ProjectAddress: {
                children:
                  textReveal(item.projectInputs?.projectLocation?.address, index, 50)[0] ===
                  index &&
                  textReveal(item.projectInputs?.projectLocation?.address, index, 50)[1],
                onMouseEnter: () => setIndexOfItem(index),
                onMouseLeave: () => setIndexOfItem(-1),
              },
              QuoteCreatedDate: {
                children: `Quote Created on: ${changeDateFormat(
                  getLocalDate(item.createdAt)
                )}`,
              },
              packageTypeBadge: {
                children: item.projectInputs.preferredContractPackage,
                display: !item.projectInputs.preferredContractPackage && "none",
              },
              TotalFee: {
                children: item.totalValue
                  ? addCommaToPrice(item.totalValue)
                  : "$ 0",
              },
              GadgetIcons44595014: {
                title: "View",
                className: "iconHoverToChange",
                onClick: () => {
                  getQuoteDetails(item);
                },
                // display: isExpired(item.createdAt)=== 'expired' ? "none" : "block",
              },
              GadgetIcons44595015: {
                title: "View",
                className: "iconHoverToChange",
                display: isAdmin ? "block" : "none",
                onClick: () =>
                  Swal.fire({
                    icon: "info",
                    title: "Coming Soon !",
                    text: "PermitZIP Admins will be able to edit estimates and customize pricing in an upcoming release!",
                  }),
              },
              GadgetIcons44595016: {
                title: "View",
                className: "iconHoverToChange",
                display: isDeletable(item) ? 'block' : 'none',
                onClick: () =>
                  Swal.fire({
                    title: `Do you want to delete your existing quote for ${item.name}?`,
                    // text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#d33",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: `Yes, delete ${item.name}`,
                    reverseButtons: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteQuote(item);
                    }
                  }),
              },
            },
          })}
        />
      </Flex>
      <QuoteViewCard
        setQuoteOpen={setQuoteOpen}
        quoteDetails={quoteDetails}
        quoteDisciplines={quoteDisciplines}
        quoteSqft={quoteSqft}
        quoteOpen={quoteOpen}
      />
    </>
  );
}