// React
import { useEffect, useContext, useState } from "react";

// Third-party libraries
import Swal from "sweetalert2";

import axios from "axios";

// Amplify
import { Flex, Autocomplete } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";

// Gadget Engineering libraries
import ProjectSnapshotCardCollection from "../ui-components/ProjectSnapshotCardCollection";
import { Project, Organization, User } from "../models";
import { InstantQuoteContext } from "../Context/ProposalContext";

export default function ModalForm(props) {
  // Props
  const { setOpen, ...rest } = props;

  const { user, addCommaToPrice, instantQuoteApi, userContentModalOpen } =
    useContext(InstantQuoteContext);

  useEffect(() => {
    getOrg();
    if (user["signInUserSession"]) {
      const userGroup =
        user["signInUserSession"]["idToken"]["payload"]["cognito:groups"];
      if (userGroup.includes("Admin")) {
        setIsAdmin(true);
      }
    }
  }, [userContentModalOpen]);

  const [isAdmin, setIsAdmin] = useState(false);
  const [orgList, setOrgList] = useState();
  const [userList, setUserList] = useState();
  const [userName, setUserName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgId, setOrgId] = useState("");
  const [userId, setUserId] = useState("");
  const [projectList, setProjectList] = useState();

  const [indexOfItem, setIndexOfItem] = useState(-1);

  async function getOrg() {
    const org = await DataStore.query(Organization);
    var orgOption = [];
    for (let orgObj of org)
      orgOption.push({ id: orgObj.id, label: orgObj.name });
    setOrgList(orgOption);
    const users = await DataStore.query(User);
    var userOption = [];
    for (let userObj of users)
      userOption.push({ id: userObj.id, label: userObj.email });
    setUserList(userOption);
  }

  async function selectedOrg(e) {
    setOrgName(e.label);
    setOrgId(e.id);
    selectedProject(e.id, userId);
  }

  async function selectedUser(e) {
    setUserName(e.label);
    setUserId(e.id);
    selectedProject(orgId, e.id);
  }

  async function selectedProject(orgId, userId) {
    var projects;
    if (orgId && userId) {
      projects = await DataStore.query(Project, (c) =>
        c.and((c) => [
          c.organizationProjectsId.eq(orgId),
          c.userProjectsId.eq(userId),
        ])
      );
    } else if (orgId) {
      projects = await DataStore.query(Project, (c) =>
        c.organizationProjectsId.eq(orgId)
      );
    } else if (userId) {
      projects = await DataStore.query(Project, (c) =>
        c.userProjectsId.eq(userId)
      );
    } else {
      projects = await DataStore.query(Project);
    }
    setProjectList(projects);
  }

  async function noUser() {
    setUserName("");
    setUserId("");
    selectedProject(orgId, null);
  }

  async function noOrg() {
    setOrgName("");
    setOrgId("");
    selectedProject(null, userId);
  }

  async function deleteProject(item) {
    const currentProject = await DataStore.query(Project, item.id);
    if (currentProject.pipedriveID) {
      const sendEmailFields = {
        pipedriveID: currentProject.pipedriveID,
        userEmail: user.attributes.email,
        isDeleteProject: true,
      };
      Swal.fire({
        icon: "info",
        title: "Deleting your project...",
        // text: "Once the email is sent, the confirmation message will be shown",
        showConfirmButton: false,
      });
      try {
        await axios
          .post(`${instantQuoteApi}/proposals/pipedrive`, sendEmailFields)
          .then((res) => {
            if (res.data.errorMessage)
              Swal.fire({
                icon: "error",
                title: "Something went wrong!",
                text: "Check the entered mail ID's",
              });
          });
      } catch (error) {
        console.log(error);
      }
    }
    await DataStore.delete(currentProject).then(() => {
      Swal.fire({
        icon: "success",
        title: "Project Deleted!",
        text: "Project was deleted successfully!",
      }).then(() => window.location.reload());
    });
  }

  function textReveal(name, index, maxLength) {
    if (index === indexOfItem) return [index, name];
    if (name.length > maxLength) {
      const val = name.slice(0, maxLength - 3) + "...";
      return [index, val];
    } else return [index, name];
  }

  function saveProjectId(id) {
    if (localStorage.getItem("projectId")) localStorage.removeItem("projectId");
    if (localStorage.getItem("quoteId")) localStorage.removeItem("quoteId");
    localStorage.setItem("projectId", id);
    window.location.reload();
  }

  function isAuthor(project) {
    return (
      isAdmin || (project.editors && project.editors.includes(user.username))
    );
  }

  const Css = `
  .swal2-container.swal2-center.swal2-backdrop-show {
    z-index:9999;
}

 .amplify-pagination__item-button, .amplify-pagination__item-button--link{
  color: black;
}
.amplify-pagination__item-button:hover{
  color: black;
}

.amplify-flex {
  justify-content:center;
}
.amplify-flex.amplify-collection-search {
  justify-content:center;
}

.iconHoverToChange:hover{
  background: lightblue;
  cursor: pointer;
 border-radius: 10px;
  transition:0.2s ease-out;
}
`;

  return (
    <>
      <style>{Css}</style>
      {/* <ScrollView  borderRadius="10px"  padding="25px"> */}
      <Flex margin="20px 0">
        <Autocomplete
          label="Organization"
          options={orgList}
          placeholder="Filter by Organization"
          onSelect={(e) => selectedOrg(e)}
          defaultValue={orgName}
          onChange={(e) => {
            noOrg();
          }}
          onClear={() => {
            noOrg();
          }}
        />
      </Flex>
      {isAdmin && (
        <Flex margin="20px 0">
          <Autocomplete
            label="User"
            options={userList}
            placeholder="Filter by User"
            onSelect={(e) => selectedUser(e)}
            defaultValue={userName}
            onChange={(e) => {
              noUser();
            }}
            onClear={() => {
              noUser();
            }}
          />
        </Flex>
      )}
      <Flex
        direction={{
          base: "column",
          small: "column",
          medium: "column",
          large: "row",
          xl: "row",
        }}
      >
        <ProjectSnapshotCardCollection
          searchPlaceholder="Search Name or Address…"
          items={projectList}
          maxWidth={"900px"}
          itemsPerPage={4}
          overrideItems={({ item, index }) => ({
            overrides: {
              proposalExpirationWarning: {
                display: item.createdAt ? "none" : "block",
                children: 'Saving operation is in progress...',
                heading:"Project not saved",
              },
              FrameOfReference: {
                height: "auto",
                // maxHeight:'250px',
                width: "100%",
                maxWidth: "360px",
                // margin:'10px'
              },
              ProjectName: {
                children:
                  textReveal(item.name, index)[0] === index &&
                  textReveal(item.name, index)[1],
                onMouseEnter: () => setIndexOfItem(index),
                onMouseLeave: () => setIndexOfItem(-1),
              },
              ProjectAddress: {
                children:
                  textReveal(
                    item.projectInputs.projectLocation?.address,
                    index,
                    50
                  )[0] === index &&
                  textReveal(
                    item.projectInputs.projectLocation?.address,
                    index,
                    50
                  )[1],
                onMouseEnter: () => setIndexOfItem(index),
                onMouseLeave: () => setIndexOfItem(-1),
              },
              packageTypeBadge: {
                children: item.projectInputs.preferredContractPackage,
                display: !item.projectInputs.preferredContractPackage && "none",
              },
              TotalFee: {
                children: item.totalValue
                  ? addCommaToPrice(item.totalValue)
                  : "$ 0",
              },
              GadgetIcons44605254: {
                className: "iconHoverToChange",
                display: isAuthor(item) ? "block" : "none",
                onClick: () =>
                  Swal.fire({
                    title: `Do you want to edit your existing project for ${item.name}?`,
                    // text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Yes, edit ${item.name}`,
                    reverseButtons: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      saveProjectId(item.id);
                    }
                  }),
              },
              GadgetIcons44605255: {
                className: "iconHoverToChange",
                display: isAuthor(item) ? "block" : "none",
                onClick: () =>
                  Swal.fire({
                    title: `Do you want to delete your existing project for ${item.name}?`,
                    html: `<div style='color:red;'><b> WARNING: Deleting a Project will delete any associated Quotes from your account.</b> <br/>Are you sure you want to continue?</div>`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#d33",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: `Yes, delete ${item.name}`,
                    reverseButtons: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteProject(item);
                    }
                  }),
              },
            },
          })}
        />
      </Flex>
      {/* </ScrollView> */}
    </>
  );
}
