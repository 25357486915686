// React
import { useContext } from "react";

// Amplify

// Joy
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";

// Third-party libraries

// Amplify
import { Flex, View, Image, Heading } from "@aws-amplify/ui-react";

// Gadget Engineering libraries
import QuoteRecipientsCard from "./QuoteRecipientsCard";
import QuoteSummaryCard from "./QuoteSummaryCard";

import { InstantQuoteContext } from "../Context";

export default function ModalForm(props) {
  // Props
  const { open, setOpen, projectDetails, ...rest } = props;
  const { squareFootage, discipline } =
    useContext(InstantQuoteContext);

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        {...rest}
      >
        <View
          display="flex"
          backgroundColor="#f3f3f3"
          borderRadius="10px"
          direction="column"
          alignItems="center"
          maxHeight={{
            base: "600px",
            small: "650px",
            medium: "680px",
            large: "650px",
            xl: "800px",
          }}
          margin="10px"
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/90 * var(--IconButton-size))",
              right: "calc(-1/90 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.body",
            }}
          />

          <Flex
            alignItems={"center"}
            padding="10px"
            backgroundColor={"white"}
            borderRadius="10px 10px 0px 0px"
            width="100%"
          >
            <Image
              alt="PermitZip logo"
              src={require("../Assets/permitZIP.png")}
              height="55px"
              margin={"0px 10px"}
            />
            <Heading level="4"> Your Project Estimate Summary</Heading>
          </Flex>
          <View
            overflow={{
              base: "scroll-y",
              small: "scroll",
              medium: "scroll",
              large: "scroll",
              xl: "none",
            }}
            borderRadius="10px"
          >
            <Flex
              direction={{
                base: "column",
                small: "column",
                medium: "column",
                large: "row",
                xl: "row",
              }}
            >
              <QuoteSummaryCard
                quoteDetails={projectDetails}
                quoteDisciplines={discipline}
                quoteSqft={squareFootage}
              />
              <QuoteRecipientsCard setOpen={setOpen} />
            </Flex>
          </View>
        </View>
      </Modal>
    </>
  );
}
