/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Flex,
  StepperField,
  Text,
  TextField,
  View,
} from "@aws-amplify/ui-react";
export default function SquareFootageInputCard(props) {
  const { donutChartPlaceholder, tooltip, overrides, ...rest } = props;
  return (
    <Flex
      gap="24px"
      direction="column"
      width="260px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      borderRadius="10px"
      padding="16px 16px 16px 16px"
      backgroundColor="rgba(0,64,77,1)"
      {...getOverrideProps(overrides, "SquareFootageInputCard")}
      {...rest}
    >
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="8px 8px 8px 8px"
        {...getOverrideProps(overrides, "HeaderRow")}
      >
        <Text
          fontFamily="Inter"
          fontSize="24px"
          fontWeight="600"
          color="rgba(255,255,255,1)"
          lineHeight="30px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Real Time Pricing Calculator"
          {...getOverrideProps(overrides, "HeaderText")}
        ></Text>
        <View
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          children={tooltip}
          {...getOverrideProps(overrides, "tooltip")}
        ></View>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(255,255,255,1)"
        lineHeight="24px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.01px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Choose the category that best describes your project needs and we will show your prices in real time!"
        {...getOverrideProps(overrides, "BodyText")}
      ></Text>
      <TextField
        width="unset"
        height="unset"
        label="Brewery, Coffee or Similar"
        justifyContent="flex-end"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        placeholder="Placeholder"
        size="default"
        isDisabled={false}
        labelHidden={true}
        variation="quiet"
        {...getOverrideProps(overrides, "brewery")}
      ></TextField>
      <TextField
        width="unset"
        height="unset"
        label="Shell or Warehouse"
        justifyContent="flex-end"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        placeholder="Placeholder"
        size="default"
        isDisabled={false}
        labelHidden={true}
        variation="quiet"
        {...getOverrideProps(overrides, "shell")}
      ></TextField>
      <TextField
        width="unset"
        height="unset"
        label="Multifamily"
        justifyContent="flex-end"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        placeholder="Placeholder"
        size="default"
        isDisabled={false}
        labelHidden={true}
        variation="quiet"
        {...getOverrideProps(overrides, "multifam")}
      ></TextField>
      <StepperField
        width="unset"
        height="unset"
        label="Unique Unit Layouts"
        justifyContent="flex-end"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        size="default"
        isDisabled={false}
        labelHidden={true}
        variation="quiet"
        {...getOverrideProps(overrides, "uniqueLayouts")}
      ></StepperField>
      <TextField
        width="unset"
        height="unset"
        label="Office or Religious"
        justifyContent="flex-end"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        placeholder="Placeholder"
        size="default"
        isDisabled={false}
        labelHidden={true}
        variation="quiet"
        {...getOverrideProps(overrides, "office")}
      ></TextField>
      <TextField
        width="unset"
        height="unset"
        label="Restaurant"
        justifyContent="flex-end"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        placeholder="Placeholder"
        size="default"
        isDisabled={false}
        labelHidden={true}
        variation="quiet"
        {...getOverrideProps(overrides, "restaurant")}
      ></TextField>
      <TextField
        width="unset"
        height="unset"
        label="Medical"
        justifyContent="flex-end"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        placeholder="Placeholder"
        size="default"
        isDisabled={false}
        labelHidden={true}
        variation="quiet"
        {...getOverrideProps(overrides, "medical")}
      ></TextField>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="353.5px"
        justifyContent="center"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        border="1px SOLID rgba(239,240,240,1)"
        borderRadius="10px"
        padding="19px 19px 19px 19px"
        backgroundColor="rgba(239,240,240,1)"
        children={donutChartPlaceholder}
        {...getOverrideProps(overrides, "DonutChartPlaceholder")}
      ></Flex>
    </Flex>
  );
}
