// React
import { useEffect, useContext } from "react";

// Amplify
import { DataStore } from "aws-amplify";
import { View } from "@aws-amplify/ui-react";

import { Project } from "../../models";

// Gadget Engineering Imports
import { InstantQuoteContext } from "../../Context/ProposalContext";
import { DesignPackages, QuoteEmailTemplate } from "..";

export default function Body(props) {

  const { ...rest } = props;

  // Context
  const { projectId, setProjectRecord } = useContext(InstantQuoteContext);

  // State

  // Constants

  // Function Expressions

  // Arrow Functions

  // React Effects
  useEffect(
    () => {
      const projectSubscription = DataStore.observeQuery(
        Project,
        (project) => project.id.eq(projectId)
      ).subscribe(({ items }) => {
        setProjectRecord(items[0]);
      });

      return () => {
        projectSubscription.unsubscribe(); // this invokes on teardown, before next render
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // React Memos

  // Function Declarations
  return (
    // Do not delete the parent View wrapper. Set props for it at the app.jsx level
    <View {...rest}>
      <DesignPackages id="DesignPackages" />
      <QuoteEmailTemplate />
    </View>
  );
}
