export const schema = {
    "models": {
        "User": {
            "name": "User",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": true,
                    "attributes": []
                },
                "picture": {
                    "name": "picture",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "cognitoUserPoolID": {
                    "name": "cognitoUserPoolID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "cognitoUserID": {
                    "name": "cognitoUserID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "organizations": {
                    "name": "organizations",
                    "isArray": true,
                    "type": {
                        "model": "UserOrganizationRelationships"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "user"
                    }
                },
                "projects": {
                    "name": "projects",
                    "isArray": true,
                    "type": {
                        "model": "Project"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "userProjectsId"
                    }
                },
                "quotes": {
                    "name": "quotes",
                    "isArray": true,
                    "type": {
                        "model": "Quote"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "userQuotesId"
                    }
                },
                "contracts": {
                    "name": "contracts",
                    "isArray": true,
                    "type": {
                        "model": "Contract"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "userContractsId"
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Users",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "cognito:username"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Organization": {
            "name": "Organization",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "billingEmail": {
                    "name": "billingEmail",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": false,
                    "attributes": []
                },
                "address": {
                    "name": "address",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "picture": {
                    "name": "picture",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "tagLine": {
                    "name": "tagLine",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Members": {
                    "name": "Members",
                    "isArray": true,
                    "type": {
                        "model": "UserOrganizationRelationships"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "organization"
                    }
                },
                "projects": {
                    "name": "projects",
                    "isArray": true,
                    "type": {
                        "model": "Project"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "organizationProjectsId"
                    }
                },
                "quotes": {
                    "name": "quotes",
                    "isArray": true,
                    "type": {
                        "model": "Quote"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "organizationQuotesId"
                    }
                },
                "contracts": {
                    "name": "contracts",
                    "isArray": true,
                    "type": {
                        "model": "Contract"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "organizationContractsId"
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Organizations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "allow": "private",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CurrentContractPricingConfiguration": {
            "name": "CurrentContractPricingConfiguration",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "contractPricingConfiguration": {
                    "name": "contractPricingConfiguration",
                    "isArray": false,
                    "type": {
                        "nonModel": "ContractPricingConfiguration"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "disciplinePricingConfiguration": {
                    "name": "disciplinePricingConfiguration",
                    "isArray": true,
                    "type": {
                        "nonModel": "DisciplinePricingConfiguration"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "contractPackage": {
                    "name": "contractPackage",
                    "isArray": false,
                    "type": {
                        "enum": "ContractPackageName"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "dateTimeSet": {
                    "name": "dateTimeSet",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "CurrentContractPricingConfigurations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin"
                                ],
                                "operations": [
                                    "read",
                                    "create",
                                    "update",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Project": {
            "name": "Project",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "userEmail": {
                    "name": "userEmail",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": true,
                    "attributes": []
                },
                "authorizerEmail": {
                    "name": "authorizerEmail",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": false,
                    "attributes": []
                },
                "authorizerName": {
                    "name": "authorizerName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "tradeSupervisions": {
                    "name": "tradeSupervisions",
                    "isArray": true,
                    "type": {
                        "enum": "TradeSupervision"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "scopeSummary": {
                    "name": "scopeSummary",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "privateNoteFromSales": {
                    "name": "privateNoteFromSales",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "ContractStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "qboEstimateID": {
                    "name": "qboEstimateID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "totalHours": {
                    "name": "totalHours",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "totalValue": {
                    "name": "totalValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "projectInputs": {
                    "name": "projectInputs",
                    "isArray": false,
                    "type": {
                        "nonModel": "ProjectInputs"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "contractPricingConfigurationSnapshot": {
                    "name": "contractPricingConfigurationSnapshot",
                    "isArray": false,
                    "type": {
                        "nonModel": "ContractPricingConfigurationSnapshot"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "pipedriveID": {
                    "name": "pipedriveID",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "editors": {
                    "name": "editors",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "userProjectsId": {
                    "name": "userProjectsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "organizationProjectsId": {
                    "name": "organizationProjectsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Projects",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "gsi-User.projects",
                        "fields": [
                            "userProjectsId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "gsi-Organization.projects",
                        "fields": [
                            "organizationProjectsId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "editors",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Quote": {
            "name": "Quote",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "userEmail": {
                    "name": "userEmail",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": true,
                    "attributes": []
                },
                "authorizerEmail": {
                    "name": "authorizerEmail",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": true,
                    "attributes": []
                },
                "authorizerName": {
                    "name": "authorizerName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "additionalServicesHours": {
                    "name": "additionalServicesHours",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "tradeSupervisions": {
                    "name": "tradeSupervisions",
                    "isArray": true,
                    "type": {
                        "enum": "TradeSupervision"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "scopeSummary": {
                    "name": "scopeSummary",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "privateNoteFromSales": {
                    "name": "privateNoteFromSales",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "metadata": {
                    "name": "metadata",
                    "isArray": false,
                    "type": {
                        "nonModel": "ContractMetadata"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "totalHours": {
                    "name": "totalHours",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "totalValue": {
                    "name": "totalValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "projectInputs": {
                    "name": "projectInputs",
                    "isArray": false,
                    "type": {
                        "nonModel": "ProjectInputs"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "contractPricingConfigurationSnapshot": {
                    "name": "contractPricingConfigurationSnapshot",
                    "isArray": false,
                    "type": {
                        "nonModel": "ContractPricingConfigurationSnapshot"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "contract": {
                    "name": "contract",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "project": {
                    "name": "project",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "editors": {
                    "name": "editors",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "viewers": {
                    "name": "viewers",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "userQuotesId": {
                    "name": "userQuotesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "organizationQuotesId": {
                    "name": "organizationQuotesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Quotes",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "gsi-User.quotes",
                        "fields": [
                            "userQuotesId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "gsi-Organization.quotes",
                        "fields": [
                            "organizationQuotesId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "editors",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "delete"
                                ],
                                "identityClaim": "cognito:username"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "viewers",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "cognito:username"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Contract": {
            "name": "Contract",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "quote": {
                    "name": "quote",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "project": {
                    "name": "project",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "Deliverables": {
                    "name": "Deliverables",
                    "isArray": true,
                    "type": {
                        "model": "Deliverable"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "contractDeliverablesId"
                    }
                },
                "userEmail": {
                    "name": "userEmail",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "ContractStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "changesFromQuote": {
                    "name": "changesFromQuote",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "userContractsId": {
                    "name": "userContractsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "organizationContractsId": {
                    "name": "organizationContractsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Contracts",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "gsi-User.contracts",
                        "fields": [
                            "userContractsId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "gsi-Organization.contracts",
                        "fields": [
                            "organizationContractsId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Deliverable": {
            "name": "Deliverable",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "contract": {
                    "name": "contract",
                    "isArray": false,
                    "type": {
                        "model": "Contract"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "contractDeliverablesId"
                    }
                },
                "isBillable": {
                    "name": "isBillable",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "isInDefault": {
                    "name": "isInDefault",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "totalValue": {
                    "name": "totalValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "itemPrice": {
                    "name": "itemPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "itemQuantity": {
                    "name": "itemQuantity",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "productVariation": {
                    "name": "productVariation",
                    "isArray": false,
                    "type": {
                        "nonModel": "ProductVariation"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "dependencies": {
                    "name": "dependencies",
                    "isArray": true,
                    "type": {
                        "nonModel": "Dependency"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "estimatedHours": {
                    "name": "estimatedHours",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "dueDate": {
                    "name": "dueDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Deliverables",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserOrganizationRelationships": {
            "name": "UserOrganizationRelationships",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "user": {
                    "name": "user",
                    "isArray": false,
                    "type": {
                        "model": "User"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "userID"
                    }
                },
                "organization": {
                    "name": "organization",
                    "isArray": false,
                    "type": {
                        "model": "Organization"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "organizationID"
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "UserOrganizationRelationships",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUser",
                        "fields": [
                            "userID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrganization",
                        "fields": [
                            "organizationID"
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "SurveyType": {
            "name": "SurveyType",
            "values": [
                "VIRTUAL",
                "IN_PERSON"
            ]
        },
        "ConstructionType": {
            "name": "ConstructionType",
            "values": [
                "GROUND_UP",
                "ADAPTIVE_REUSE_CLEAN_SLATE",
                "ADAPTIVE_REUSE_RECYCLED_SYSTEMS"
            ]
        },
        "ContractPackageName": {
            "name": "ContractPackageName",
            "values": [
                "GIST",
                "MINIMALIST",
                "COLLABORATOR",
                "PERFECTIONIST",
                "TWO_WEEK",
                "MINI"
            ]
        },
        "DesignCategory": {
            "name": "DesignCategory",
            "values": [
                "RESTAURANT",
                "BREWERY",
                "OFFICE",
                "MULTIFAMILY",
                "MEDICAL",
                "WAREHOUSE"
            ]
        },
        "ContractStatus": {
            "name": "ContractStatus",
            "values": [
                "OPEN",
                "AUTHORIZED",
                "PAUSED",
                "DEFAULT"
            ]
        },
        "TradeSupervision": {
            "name": "TradeSupervision",
            "values": [
                "MECH",
                "ELEC",
                "PLUMB"
            ]
        }
    },
    "nonModels": {
        "ProjectLocation": {
            "name": "ProjectLocation",
            "fields": {
                "latitude": {
                    "name": "latitude",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "longitude": {
                    "name": "longitude",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "address": {
                    "name": "address",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "driveDistance": {
                    "name": "driveDistance",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "driveDuration": {
                    "name": "driveDuration",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ContractPricingConfiguration": {
            "name": "ContractPricingConfiguration",
            "fields": {
                "projectSetupFee": {
                    "name": "projectSetupFee",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "kitchenSetupFee": {
                    "name": "kitchenSetupFee",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "brewerySetupFee": {
                    "name": "brewerySetupFee",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "percentProgressAtSchematicDesign": {
                    "name": "percentProgressAtSchematicDesign",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "percentProgressAtDesignDevelopment": {
                    "name": "percentProgressAtDesignDevelopment",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "percentProgressAtConstructionDocuments": {
                    "name": "percentProgressAtConstructionDocuments",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "percentForSchematicDesignIteration": {
                    "name": "percentForSchematicDesignIteration",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "percentForDesignDevelopmentIteration": {
                    "name": "percentForDesignDevelopmentIteration",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "minimumAdditionalServicesHoursPerTradeByPercent": {
                    "name": "minimumAdditionalServicesHoursPerTradeByPercent",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "minimumAdditionalServicesHoursPerTrade": {
                    "name": "minimumAdditionalServicesHoursPerTrade",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "percentageOfContingencyHoursExpedited": {
                    "name": "percentageOfContingencyHoursExpedited",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "pricePerUniqueMultiFamilyLayout": {
                    "name": "pricePerUniqueMultiFamilyLayout",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "adaptiveReusePremiumCleanSlate": {
                    "name": "adaptiveReusePremiumCleanSlate",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "adaptiveReusePremiumRecycledSystem": {
                    "name": "adaptiveReusePremiumRecycledSystem",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "additionalServicesBillableRate": {
                    "name": "additionalServicesBillableRate",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "expeditedRate": {
                    "name": "expeditedRate",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "inPersonSurveyFee": {
                    "name": "inPersonSurveyFee",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Dependency": {
            "name": "Dependency",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "clickUpID": {
                    "name": "clickUpID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ProductVariation": {
            "name": "ProductVariation",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ContractMetadata": {
            "name": "ContractMetadata",
            "fields": {
                "clickUp": {
                    "name": "clickUp",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "QBO": {
                    "name": "QBO",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "dropBox": {
                    "name": "dropBox",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "frontApp": {
                    "name": "frontApp",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "goToMeeting": {
                    "name": "goToMeeting",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "blueBeam": {
                    "name": "blueBeam",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "revit": {
                    "name": "revit",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ProjectInputs": {
            "name": "ProjectInputs",
            "fields": {
                "restaurantSqf": {
                    "name": "restaurantSqf",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "warehouseSqf": {
                    "name": "warehouseSqf",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "brewerySqf": {
                    "name": "brewerySqf",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "multifamilySqf": {
                    "name": "multifamilySqf",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "officeAndRetailSqf": {
                    "name": "officeAndRetailSqf",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "medicalSqf": {
                    "name": "medicalSqf",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "projectLocation": {
                    "name": "projectLocation",
                    "isArray": false,
                    "type": {
                        "nonModel": "ProjectLocation"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "tradeSupervisions": {
                    "name": "tradeSupervisions",
                    "isArray": true,
                    "type": {
                        "enum": "TradeSupervision"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "totalSqf": {
                    "name": "totalSqf",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "uniqueMultifamilyLayouts": {
                    "name": "uniqueMultifamilyLayouts",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "constructionType": {
                    "name": "constructionType",
                    "isArray": false,
                    "type": {
                        "enum": "ConstructionType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "surveyType": {
                    "name": "surveyType",
                    "isArray": false,
                    "type": {
                        "enum": "SurveyType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "preferredContractPackage": {
                    "name": "preferredContractPackage",
                    "isArray": false,
                    "type": {
                        "enum": "ContractPackageName"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "CategoryMultiplier": {
            "name": "CategoryMultiplier",
            "fields": {
                "category": {
                    "name": "category",
                    "isArray": false,
                    "type": {
                        "enum": "DesignCategory"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "multiplierConstant": {
                    "name": "multiplierConstant",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "exponentialCoefficient": {
                    "name": "exponentialCoefficient",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "DisciplinePricingConfiguration": {
            "name": "DisciplinePricingConfiguration",
            "fields": {
                "discipline": {
                    "name": "discipline",
                    "isArray": false,
                    "type": {
                        "enum": "TradeSupervision"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "setUpFees": {
                    "name": "setUpFees",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "pricePerSquareFootBaseFee": {
                    "name": "pricePerSquareFootBaseFee",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "baseSquareFootageMultiplier": {
                    "name": "baseSquareFootageMultiplier",
                    "isArray": true,
                    "type": {
                        "nonModel": "CategoryMultiplier"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "specialtySetupFees": {
                    "name": "specialtySetupFees",
                    "isArray": true,
                    "type": {
                        "nonModel": "CategoryMultiplier"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "ContractPricingConfigurationSnapshot": {
            "name": "ContractPricingConfigurationSnapshot",
            "fields": {
                "contractPricingConfiguration": {
                    "name": "contractPricingConfiguration",
                    "isArray": false,
                    "type": {
                        "nonModel": "ContractPricingConfiguration"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "disciplinePricingConfiguration": {
                    "name": "disciplinePricingConfiguration",
                    "isArray": true,
                    "type": {
                        "nonModel": "DisciplinePricingConfiguration"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "contractPackage": {
                    "name": "contractPackage",
                    "isArray": false,
                    "type": {
                        "nonModel": "ContractPackage"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "contractInputs": {
                    "name": "contractInputs",
                    "isArray": false,
                    "type": {
                        "nonModel": "ProjectInputs"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ContractPackage": {
            "name": "ContractPackage",
            "fields": {
                "meetings": {
                    "name": "meetings",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "virtualSurveys": {
                    "name": "virtualSurveys",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "additionalServicesHours": {
                    "name": "additionalServicesHours",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": {
                        "enum": "ContractPackageName"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        }
    },
    "codegenVersion": "3.3.6",
    "version": "93aae455ae32753226a92b2828ca2703"
};