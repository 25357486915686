// React
import { useContext, useEffect, useState } from "react";

// Joy
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";

// Other Third Party Libraries
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

// Gadget Engineering Libraries
import { InstantQuoteContext } from "../Context/ProposalContext";

export default function DesignSquareFootageSummary(props) {
  const { ...rest } = props;

  // Context
  const { squareFootage, changeInSquareFootage } =
    useContext(InstantQuoteContext);

  // State
  const [pieData, setPieData] = useState([]);

  // Constants
  const COLORS = ["#8884d8", "#82ca9d", "#FFBB28", "#FF8042", "#AF19FF"];

  const RADIAN = Math.PI / 180;

  // Function Expressions (to be passed as arguments or as closures) -----------
  const renderCustomizedLabel = function ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.21;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const CustomToolTip = function ({ active, payload, label }) {
    if (active) {
      return (
        <Card variant="soft">
          <Typography
            id="member"
            sx={{
              // textTransform: "uppercase",
              fontSize: "xs",
              letterSpacing: "lg",
              fontWeight: "lg",
              color: "text.primary",
              mb: 0,
            }}
          >
          {`${payload[0].name} : ${payload[0].value} Sq.ft`
            /* {`${payload[0].name} : ${(
              (payload[0].value / squareFootage["totalSquareFootage"]) *
              100
            ).toFixed(0)}%`} */
          }
          </Typography>
        </Card>
      );
    }
    return null;
  };

  const updateDonutChartData = function () {
    const SquareField = {
      restaurant: "Restaurant",
      warehouse: "Shell/Warehouse",
      brewery: "Brewery",
      office: "Office",
      multiFamily: "Multifamily",
      medical: "Medical",
    };

    let graph_data = [];
    for (const field in squareFootage) {
      if (
        field !== "uniqueLayouts" &&
        field !== "totalSquareFootage" &&
        squareFootage[field]
      )
        graph_data.push({
          name: SquareField[field],
          value: squareFootage[field],
        });
    }
    setPieData(graph_data);
  };

  // Arrow Functions (to lexically bind the 'this' value) ----------------------
  // TODO: Replace this line with arrow functions when needed

  // React Effects (execute after render completion) ---------------------------
  useEffect(updateDonutChartData, [changeInSquareFootage]);

  // Memos

  // Function Declarations

  return (
    <Card
      {...rest}
      // sx={{py: 1, px: 2, mx: 2, }}
    >
      <Typography>
        <Typography level="h4">Building Area </Typography>
        <Typography textColor="neutral.500" level="h4">
          Summary
        </Typography>
      </Typography>

      <div align="center">
        <Typography level="h5">
        {squareFootage["totalSquareFootage"]} Total Sqft
        </Typography>
        <PieChart width={280} height={330}>
          <Pie
            data={pieData}
            color="#000000"
            dataKey="value"
            nameKey="name"
            cx="48%"
            cy="50%"
            startAngle={360}
            endAngle={0}
            label={renderCustomizedLabel}
            labelLine={false}
            paddingAngle={5}
            cornerRadius={3}
            outerRadius={110}
            innerRadius={55}
            legendType="circle"
            fill="#8884d8"
          >
            {pieData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                strokeWidth={1.5}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomToolTip />} />
          <Legend layout="horizontal" align="center" verticalAlign="bottom" />
        </PieChart>
      </div>
    </Card>
  );
}
