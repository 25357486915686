import React from "react";

// Third party imports
import { Tooltip, Typography } from "@mui/joy";

// Amplify UI
import { View } from "@aws-amplify/ui-react";

// Gadget Enginering imports
import { GadgetIcons } from "../ui-components";

const InfoTooltip = ({...rest}) => {
  const Css = `
    .JoyTooltip, .css-1r881y3-JoyTooltip-root{
    inset: 'auto auto 14px auto';
  }
  .JoyTooltip-arrow, .css-87qfk0-JoyTooltip-arrow{
    color:rgb(102,0,102,1);
  }
    `;
  return (
    <>
      <style>{Css}</style>
      <Tooltip
        sx={{
          bgcolor: "rgb(102,0,102,1)",
          maxWidth: "400px",
          wordBreak: "break-word",
          textAlign: "match-parent",
        }}
        title={
          <Typography sx={{ m: 1, borderRadius: "12px", fontSize: "18px", color:"#fff" }}>
            {rest.message}
          </Typography>
        }
        placement="top-start"
        arrow
        
        variant="solid"
        size="lg"
      >
        <View>
        {rest.element}

        {rest.visibility &&  <GadgetIcons
          {...rest}
            type="info"
            overrides={{
              GadgetIcons: {
                paths: [
                  {
                    d: "M9 5L11 5L11 7L9 7L9 5ZM9 9L11 9L11 15L9 15L9 9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z",
                    fill: rest.color,
                    fillRule: "nonzero",
                    style: { transform: "translate(8.33%, 8.33%)" },
                  },
                ],
              },
            }}
          />}
        </View>
      </Tooltip>
    </>
  );
};

export default InfoTooltip;