import { useContext } from "react";

import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";

// Amplify
import {
  Flex,
  View,
  Text,
  Image,
  Heading,
  Tabs,
  TabItem,
} from "@aws-amplify/ui-react";

import { InstantQuoteContext } from "../Context/ProposalContext";
import ExistingProjectView from "./ExistingProjectsView";
import PreviousQuotesView from "./PreviousQuotesView";

const UserContentModal = (props) => {
  const { open, setOpen, ...rest } = props;

  const { index, setIndex } = useContext(InstantQuoteContext);
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      {...rest}
    >
      <View
        display="flex"
        backgroundColor="lightblue"
        borderRadius="10px"
        direction="column"
        alignItems="center"
        width="100%"
        maxWidth="1000px"
        height="100%"
        maxHeight="95%"
        margin="10px"
      >
        <ModalClose
          variant="outlined"
          sx={{
            top: "calc(-1/90 * var(--IconButton-size))",
            right: "calc(-1/90 * var(--IconButton-size))",
            boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
            borderRadius: "50%",
            bgcolor: "background.body",
          }}
        />
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          padding="10px"
          backgroundColor={"white"}
          borderRadius="10px 10px 0px 0px"
          width="100%"
        >
          <Image
            alt="PermitZip logo"
            src={require("../Assets/permitZIP.png")}
            height="55px"
            margin={"0px 10px"}
          />
          <Heading level="4">
            Review Your Existing Projects, Estimates, and Contracts
          </Heading>
        </Flex>
        <View width="100%" height="100%">
          <Tabs
            spacing="equal"
            justifyContent="flex-start"
            currentIndex={index}
            onChange={(i) => setIndex(i)}
          >
            <TabItem title="Projects">
              <ExistingProjectView setOpen={setOpen} />
            </TabItem>
            <TabItem title="Estimates">
              <PreviousQuotesView setOpen={setOpen} />
            </TabItem>
            <TabItem title="Contracts">
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                height={"40vh"}
              >
                <Text
                  textAlign={"center"}
                  width={"50%"}
                  letterSpacing={"1px"}
                  fontSize={"20px"}
                >
                  Coming Soon: Generate RFPs, sign documents, and track
                  authorized projects!
                </Text>
              </Flex>
            </TabItem>
          </Tabs>
        </View>
      </View>
    </Modal>
  );
};

export default UserContentModal;
