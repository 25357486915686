import { useEffect, useContext } from "react";
import { InstantQuoteContext } from "../Context/ProposalContext";

const ChangeTabTitle = () => {
  const { projectName, userInputProgress } = useContext(InstantQuoteContext);

  useEffect(() => {
    document.title = userInputProgress.isProjectLocationSet || userInputProgress.isEditProject
      ? projectName
      : "Instant-Quote";

    //  return () => { document.title = oldTitle}
  }, [userInputProgress.isProjectLocationSet, userInputProgress.isEditProject]);

  return null;
};

export default ChangeTabTitle;
