// React
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

// Local
import './index.css';
import '@aws-amplify/ui-react/styles.css';
import App from './app';
import { InstantQuoteProvider } from './Context/ProposalContext';

ReactDOM.createRoot(document.querySelector('#root')!).render(
  <React.StrictMode>
    <InstantQuoteProvider>
      <App id='permitzip-prime' />
    </InstantQuoteProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
