// React
import { useEffect, useContext } from "react";

// Amplify
import { DataStore } from "aws-amplify";
import { Project } from "../models";
import { SquareFootageInputCard } from "../ui-components";

// Gadget Engineering Imports
import { InstantQuoteContext } from "../Context";
import { DesignSquareFootageSummary } from ".";
import InfoTooltip from "./InfoTooltip";

export default function BuildingTypeAndSizeSelector(props) {
  // Props
  const { ...rest } = props;

  // Context
  const {
    squareFootage,
    setSquareFootage,
    setChangeInSquareFootage,
    changeInPackageCalculation,
    setChangeInPackageCalculation,
    changeInSquareFootage,
    projectRecord,
    setProjectRecord,
    projectId,
    setIsMultiFam,
    isMultiFam
  } = useContext(InstantQuoteContext);

  // State

  // Constants
  const removeArrowCss = `
  .amplify-field-group__outer-end, .amplify-field-group__outer-start{
    z-index:0;
  }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  `

  // Function Expressions
  const calculateResultsAndSetState = function (e) {
    if (e.target.value < 0) e.target.value = 0;
    else setSquareFootageValue(e.target.name, e.target.value);

  };

  // Arrow Functions

  // Effects
  useEffect(() => {
    const projectSubscription = DataStore.observeQuery(Project, (project) =>
      project.id.eq(projectId)
    ).subscribe(({ items }) => {
      setProjectRecord(items[0]);
    });

    return () => {
      projectSubscription.unsubscribe(); // this invokes on teardown, before next render
    };
  }, []);

  // Memos

  // Function Declarations
  function setSquareFootageValue(name, value) {
    if (value < 0 || value === "") value = 0;
    if (name !== "uniqueLayouts") {
      squareFootage["totalSquareFootage"] -= squareFootage[name];
      squareFootage["totalSquareFootage"] += parseFloat(value);
    }

    squareFootage[name] = parseFloat(value);
    setSquareFootage(squareFootage);
    setChangeInSquareFootage(!changeInSquareFootage);
    setChangeInPackageCalculation(!changeInPackageCalculation);
    squareFootage['multiFamily'] > 0
      ? setIsMultiFam(true)
      : setIsMultiFam(false);

    setProjectRecord(
      Project.copyOf(projectRecord, (projectCopy) => {
        projectCopy.projectInputs = {
          ...projectCopy.projectInputs,
          restaurantSqf: squareFootage["restaurant"],
          warehouseSqf: squareFootage["warehouse"],
          brewerySqf: squareFootage["brewery"],
          medicalSqf: squareFootage["medical"],
          multifamilySqf: squareFootage["multiFamily"],
          officeAndRetailSqf: squareFootage["office"],
          uniqueMultifamilyLayouts: squareFootage["uniqueLayouts"],
          totalSqf: squareFootage["totalSquareFootage"],
        };
      })
    );
  }

  const tooltipMessage = 'Add square footage below into one or more of the categories below that best matches your scope of work. Mixed use projects will require multiple categories with the respective square footage of each type.'
  return (
    <>
      <style>{removeArrowCss}</style>
      <SquareFootageInputCard
        donutChartPlaceholder={<DesignSquareFootageSummary />}
        display="flex"
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        borderRadius="10px"
        padding="16px 16px 16px 16px"
        backgroundColor="rgba(0,85,102,1)"
        variant="secondary-scheme"
        id="RealTimeSquareFootageCalculatorCard"
        tooltip={<InfoTooltip visibility={true} message ={tooltipMessage} color={'#fff'} />}
        overrides={{
          HeaderText:{
            children:'Square Footage'
          },
          BodyText:{
            children:''
          },
          brewery: {
            labelHidden: false,
            placeholder: "Enter the Square Footage",
            type: "number",
            name: "brewery",
            value: squareFootage.brewery > 0 ? squareFootage.brewery: '',
            onChange: (e) => calculateResultsAndSetState(e),
            onWheel: (e) => e.target.blur()
          },
          shell: {
            labelHidden: false,
            placeholder: "Enter the Square Footage",
            type: "number",
            name: "warehouse",
            value: squareFootage.warehouse > 0 ? squareFootage.warehouse : '',
            onChange: (e) => calculateResultsAndSetState(e),
            onWheel: (e) => e.target.blur()
          },
          multifam: {
            labelHidden: false,
            type: "number",
            placeholder: "Enter the Square Footage",
            name: "multiFamily",
            value: squareFootage.multiFamily > 0 ? squareFootage.multiFamily : '',
            onChange: (e) => calculateResultsAndSetState(e),
            onWheel: (e) => e.target.blur()
          },
          uniqueLayouts: {
            labelHidden: false,
            min: 2,
            disabled: true,
            value: squareFootage.uniqueLayouts,
            display: isMultiFam ? "block" : "none",
            onStepChange: (value) => {
              setSquareFootageValue("uniqueLayouts", value);
            },
          },
          office: {
            labelHidden: false,
            type: "number",
            placeholder: "Enter the Square Footage",
            name: "office",
            value: squareFootage.office > 0 ? squareFootage.office : '',
            onChange: (e) => calculateResultsAndSetState(e),
            onWheel: (e) => e.target.blur()
          },
          restaurant: {
            placeholder: "Enter the Square Footage",
            labelHidden: false,
            type: "number",
            name: "restaurant",
            value: squareFootage.restaurant > 0 ? squareFootage.restaurant : '',
            onChange: (e) => calculateResultsAndSetState(e),
            onWheel: (e) => e.target.blur()
          },
          medical: {
            placeholder: "Enter the Square Footage",
            labelHidden: false,
            type: "number",
            name: "medical",
            value: squareFootage.medical > 0 ? squareFootage.medical : '',
            onChange: (e) => calculateResultsAndSetState(e),
            onWheel: (e) => e.target.blur()
          },
          DonutChartPlaceholder:{
            padding:'0px',
            height:'auto'
          }
        }}
      />
    </>
  );
}
