/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, SwitchField, Text, View } from "@aws-amplify/ui-react";
export default function ConstructionTypeAndSurveySelectionCard(props) {
  const { tooltip, surveyTooltip, overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="300px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      borderRadius="10px"
      padding="20px 10px 20px 10px"
      backgroundColor="rgba(0,85,102,1)"
      {...getOverrideProps(overrides, "ConstructionTypeAndSurveySelectionCard")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "isGroundUp")}
      >
        <View
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          children={tooltip}
          {...getOverrideProps(overrides, "tooltip41164521")}
        ></View>
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          lineHeight="25px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Is this a ground up project?"
          {...getOverrideProps(overrides, "HeaderText39214091")}
        ></Text>
        <SwitchField
          width="75px"
          height="unset"
          label="Yes"
          shrink="0"
          size="small"
          defaultChecked={true}
          isDisabled={false}
          labelPosition="end"
          {...getOverrideProps(overrides, "SwitchField39214238")}
        ></SwitchField>
      </Flex>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "isVirtualSurvey")}
      >
        <View
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          children={surveyTooltip}
          {...getOverrideProps(overrides, "tooltip41164562")}
        ></View>
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          lineHeight="25px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Virtual field visit?"
          {...getOverrideProps(overrides, "HeaderText39214285")}
        ></Text>
        <SwitchField
          width="77px"
          height="unset"
          label="No"
          shrink="0"
          size="small"
          defaultChecked={false}
          isDisabled={false}
          labelPosition="end"
          {...getOverrideProps(overrides, "SwitchField39214286")}
        ></SwitchField>
      </Flex>
    </Flex>
  );
}
