import React from "react";
import { useContext } from "react";
import { BsBuilding, BsGeoAlt } from "react-icons/bs";

// Amplify

// Gadget Engineering Imports
import { InstantQuoteContext } from "../Context";
import package_perfectionist from "../Assets/package_perfectionist.jpg";
import package_gist from "../Assets/package_gist.jpg";
import package_minimalist from "../Assets/package_minimalist.jpg";
import package_collaborator from "../Assets/package_collaboratorV1.jpg";

const QuoteEmailTemplate = (props) => {

  const {
    organizationName,
    projectRecord,
    squareFootage,
    discipline,
    addCommaToPrice,
  } = useContext(InstantQuoteContext);

  const buildingName = {
    restaurant: "Restaurant",
    warehouse: "Shell or Warehouse",
    brewery: "Brewery, Coffee or Similar",
    office: "Office or Religious",
    multiFamily: "Multifamily",
    medical: "Medical"
  };

  // Imported Images from Assets folder

  const packageImage = {
    GIST: package_gist,
    MINIMALIST: package_minimalist,
    COLLABORATOR: package_collaborator,
    PERFECTIONIST: package_perfectionist,
  };

  // Css styles for the Card template

  const QuoteSummaryTemplateCard = {
    maxWidth: "600px",
    backgroundColor: "rgb(82,82,82)",
    width: "100%",
    margin: "10px 0px",
    border: "12px solid rgb(32,32,32)",
    borderRadius: "17px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    textAlign: "center",
    color: "#ffffff",
  };

  const PackageAndPriceFlex = {
    backgroundColor: "#2b2b2b",
    display: "inline-block",
    textAlign: "center",
    padding: "13px",
    margin: "12px 10px 0px 12px",
    width: "-webkit-fill-available",
    borderRadius: "12px",
    fontSize: "21px",
    fontWeight: "bold",
    minWidth: "175px",
  };

  const ProjectDetailsFlex = {
    backgroundColor: "#2b2b2b",
    textAlign: "center",
    padding: "12px",
    margin: "2px 10px 3px 10px",
    width: "auto",
    borderRadius: "12px",
    fontSize: "21px",
    fontWeight: "bold",
  };

  const ProjectDetailsElements = {
    margin: "12px 0px",
  };

  const DiciplinesFlex = {
    backgroundColor: "#2b2b2b",
    textAlign: "center",
    padding: "8px",
    margin: "8px",
    width: "auto",
    borderRadius: "12px",
    fontSize: "20px",
    fontWeight: "bold",
  };

  const DisciplineBadge = {
    backgroundColor: "#ff703b",
    textAlign: "center",
    padding: "10px",
    margin: "10px",
    borderRadius: "20px",
    fontSize: "18px",
    fontWeight: "600",
  };

  const ConstructionTypeFlex = {
    display: "inline-block",
    textAlign: "center",
    padding: "12px",
    borderRadius: "12px",
    fontSize: "19px",
    fontWeight: "bold",
  };

  const ConstructionTypeBadge = {
    backgroundColor: "#ff703b",
    textAlign: "center",
    padding: "10px",
    margin: "10px 15px 0px 20px",
    borderRadius: "20px",
    fontSize: "18px",
    fontWeight: "600",
  };

  const SquareFootageFlex = {
    backgroundColor: "#2b2b2b",
    textAlign: "center",
    padding: "8px",
    margin: "8px",
    width: "auto",
    borderRadius: "12px",
    fontSize: "20px",
    wordBreak: "break-word",
    fontWeight: "bold",
    whiteSpace: "no-wrap",
  };

  const SquareFootageBadge = {
    backgroundColor: "#ff703b",
    textAlign: "center",
    padding: "10px",
    margin: "10px",
    borderRadius: "20px",
    fontSize: "18px",
    fontWeight: "600",
  };

  const TotalEstimateFlex = {
    backgroundColor: "#2b2b2b",
    textAlign: "center",
    padding: "8px",
    margin: "8px",
    width: "auto",
    borderRadius: "12px",
    fontSize: "20px",
    wordBreak: "break-word",
    fontWeight: "bold",
    whiteSpace: "no-wrap",
  };

  const TotalEstimateBadge = {
    backgroundColor: "#ff703b",
    textAlign: "center",
    padding: "10px",
    margin: "10px",
    borderRadius: "20px",
    fontSize: "24px",
    fontWeight: "600",
  };

  // Function Declarations

  function properNaming(field) {
    if (field) {
      const putSpace = field.split("_").join(" ")
      let name = ''
      for (const word of putSpace.split(" ")) {
        name += word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + ' '
      }
      return (name)
    }
    else return ''
  }

  return (
    <div style={{ display: 'none' }}>
      {/* <div> */}
      <div
        style={{ textAlign: "-webkit-center", width: "100%", padding: "10px" }}
        className="quoteEmailBody"
      >
        <div style={{ margin: "2px 8px" }}>
          <div
            style={{ textAlign: "left", margin: "2px 8px", fontSize: "20px" }}
          >
            Greetings from the PermitZIP Sales Team!
          </div>
          <p
            style={{ textAlign: "left", margin: "12px 8px", fontSize: "18px" }}
          >
            Thanks for using the new PermitZIP Prime application for your project estimate!
            <b> Please note this program is still in beta, and we would love feedback if you
              encounter bugs or would like new features! </b>
          </p>
          <p
            style={{ textAlign: "left", margin: "0px 8px", fontSize: "18px" }}
          >
            See below for a summary of your project information, including a
            pricing estimate. Please note that this is not a final price, nor
            is it a contract; an authorized contract signed by PermitZIP and
            your organization is required before we can begin.
          </p>
        </div>
        <div style={QuoteSummaryTemplateCard}>
          <div style={PackageAndPriceFlex}>
            <span style={{ float: "left", margin: "2px 8px" }}>
              Requested Engineering Level: {projectRecord?.projectInputs.preferredContractPackage}
            </span>
            <span style={{ float: "right", margin: "2px 8px" }}>

            </span>
          </div>

          <div style={ProjectDetailsFlex}>
            <div style={ProjectDetailsElements}>{projectRecord?.name}</div>
            <div style={ProjectDetailsElements}>
              <BsBuilding />
              {organizationName}
            </div>
            <div style={ProjectDetailsElements}>
              <BsGeoAlt />
              {projectRecord?.projectInputs.projectLocation.address}
            </div>
          </div>

          <div style={DiciplinesFlex}>
            <div>Disciplines</div>
            <div style={DiciplinesFlex}>
              {Object.keys(discipline).map((department) => {
                if (discipline[department] === 1)
                  return (
                    <span style={DisciplineBadge}>
                      {properNaming(department)}
                    </span>
                  );
              })}
            </div>
          </div>
          <div style={DiciplinesFlex}>
            <div style={ConstructionTypeFlex}>
              <div>
                <span style={{ float: "left" }}>Construction Type : </span>
                <span style={ConstructionTypeBadge}>
                  {properNaming(projectRecord?.projectInputs.constructionType)}
                </span>
              </div>
            </div>
            {projectRecord?.projectInputs.surveyType && (
              <div style={ConstructionTypeFlex}>
                <div>
                  <span style={{ float: "left" }}>Site Visit : </span>
                  <span style={ConstructionTypeBadge}>
                    {properNaming(projectRecord?.projectInputs.surveyType)}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div style={SquareFootageFlex}>
            <div>Square Footage</div>
            <div style={SquareFootageFlex}>
              {Object.keys(buildingName).map((building) => {
                if (squareFootage[building] > 0)
                  if (building === "multiFamily")
                    return (
                      <div
                        style={SquareFootageBadge}
                      >{`${buildingName[building]} (${squareFootage[building]} sq.ft) (${squareFootage["uniqueLayouts"]} Unique Layouts)`}</div>
                    );
                  else
                    return (
                      <div
                        style={SquareFootageBadge}
                      >{`${buildingName[building]} (${squareFootage[building]} sq.ft)`}</div>
                    );
              })}
            </div>
          </div>
          <div style={TotalEstimateFlex}>
            <div>Total Estimate :</div>
            <div style={TotalEstimateFlex}>
              <div style={TotalEstimateBadge}>
                {addCommaToPrice(projectRecord?.totalValue)}
              </div>
            </div>
          </div>
        </div>
        <p
          style={{ textAlign: "left", margin: "0px 8px", fontSize: "18px" }}
        >
          Please reply to this email or contact us at sales@permitzip.com
          to discuss or authorize this proposal for production! <b>We will
            work with you to finalize pricing and special contract terms.</b>
        </p>
        <p
          style={{ textAlign: "left", margin: "25px 8px", fontSize: "20px", fontWeight: 'bold' }}
        >
          Thank you for using PermitZIP Prime!
        </p>
      </div>
    </div>
  );
};

export default QuoteEmailTemplate;
