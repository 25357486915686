// React
import { useContext, useState } from "react";

// Amplify
import {
  useAuthenticator,
  Authenticator,
  useTheme,
  ThemeProvider,
  Grid,
  Image,
  Text,
  View,
  Button,
  Heading,
} from "@aws-amplify/ui-react";
import { ToolTipModal } from "./ui-components";
import { Amplify, AuthModeStrategyType } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import awsmobile from "./aws-exports";

// Gadget Engineering Libraries
import { InstantQuoteContext } from "./Context";
import {
  AppBody,
  Footer,
  SideNavigation,
  Header,
  gadgetTheme,
} from "./GadgetComponents";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    window.location.hostname === "[::1]" || // [::1] is the IPv6 localhost address
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    ) // 127.0.0.1/8 is considered localhost for IPv4.
);

const [localRedirectSignIn, productionRedirectSignIn] =
  awsmobile.oauth.redirectSignIn.split(",");

const [localRedirectSignOut, productionRedirectSignOut] =
  awsmobile.oauth.redirectSignOut.split(",");

const authDomainName = `auth.${process.env.REACT_APP_HOSTING_URL}`
console.log(authDomainName)
const updatedAwsConfig = {
  ...awsmobile,
  oauth: {
    ...awsmobile.oauth,
    domain: authDomainName,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  }
};

Amplify.configure(updatedAwsConfig);

export default function AppAuth(props) {
  const { ...rest } = props;
  const [showStartLivePricingModal, setShowStartLivePricingModal] =
    useState("flex");
  const { userInputProgress, projectHasMinimumRequiredInputs, setUser } =
    useContext(InstantQuoteContext);

  const { tokens } = useTheme();

  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            height={"50%"}
            width={"50%"}
            alt="PermitZIP Logo"
            src={require("./Assets/permitZIP.png")}
          />
        </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign in to your account
          </Heading>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toResetPassword}
              size="small"
              variation="link"
            >
              Reset Password
            </Button>
          </View>
        );
      },
    },

    SignUp: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Create a new account
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    SetupTOTP: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      // Footer() {
      //   return <Text>Footer Information</Text>;
      // },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      // Footer() {
      //   return <Text>Footer Information</Text>;
      // },
    },
    ResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      // Footer() {
      //   return <Text>Footer Information</Text>;
      // },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      // Footer() {
      //   return <Text>Footer Information</Text>;
      // },
    },
  };

  const formFields = {
    signIn: {
      username: {
        placeholder: "Enter your email",
      },
    },
    signUp: {
      email: {
        order: 1,
      },
      name: {
        order: 2,
      },
      password: {
        label: "Password:",
        placeholder: "Enter your Password:",
        isRequired: false,
        order: 3,
      },
      confirm_password: {
        label: "Confirm Password:",
        order: 4,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: "Enter your Password:",
      },
    },
    resetPassword: {
      username: {
        placeholder: "Enter your email:",
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: "Enter your Confirmation Code:",
        label: "Confirmation code",
        isRequired: false,
      },
      confirm_password: {
        placeholder: "Enter your Password Please:",
      },
    },
    setupTOTP: {
      QR: {
        totpIssuer: "test issuer",
        totpUsername: "amplify_qr_test_user",
      },
      confirmation_code: {
        label: "Confirmation code",
        placeholder: "Enter your Confirmation Code:",
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: "Confirmation code",
        placeholder: "Enter your Confirmation Code:",
        isRequired: false,
      },
    },
  };

  return (
    <Authenticator
      formFields={formFields}
      components={components}
      socialProviders={["google", "facebook"]}
    >

      {({ user }) =>
      {
        setUser(user)
        console.log(user)
        return(
        <>
          <ThemeProvider theme={gadgetTheme}>
            {/* <GadgetAuthorizer /> */}
            <Grid
              id="mainAppGrid"
              templateColumns={{
                base: "1fr",
                large: "auto 1fr",
              }}
              templateRows={{
                base: "auto auto 1fr auto",
                large: "auto 1fr auto",
              }}
              templateAreas={{
                base: '"header" "user-input" "main" "footer"',
                large: '"header header" "user-input main" "footer footer"',
              }}
              gap={tokens.space.medium}
              backgroundColor={tokens.colors.neutral[20]}
            >
              <ToolTipModal
                id="UnlockInstantPricingModal"
                position="absolute"
                display={{
                  base: "none",
                  large:
                    userInputProgress.isProjectNameSet ||
                    userInputProgress.isEditProject
                      ? "none"
                      : showStartLivePricingModal,
                }}
                overrides={{
                  GadgetIcons40677575: {
                    onClick: () => setShowStartLivePricingModal("none"),
                  },
                }}
              />
              <Header
                id="header"
                width="100%"
                columnSpan={{ base: 1, large: 2 }}
                backgroundColor={tokens.colors.neutral[20]}
              />
              <SideNavigation
                overflow="auto"
                id="sideNavigationBar"
                marginBottom={{ base: "10px", large: "100px" }}
                padding="0px 10px 0px 10px"
                backgroundColor={tokens.colors.neutral[20]}
                height={{ base: "100%", large: "100vh" }}
                maxWidth={{ base: "500px", large: "400px" }}
              />
              <AppBody
                height={{ base: "100%", large: "100vh" }}
                marginBottom="100px"
                overflow="auto"
                className={
                  userInputProgress.isEditProject || projectHasMinimumRequiredInputs
                    ? ""
                    : "blur"
                }
              />
              <Footer
                id="footer"
                position={"fixed"}
                width="100%"
                bottom="0px"
                columnSpan={{ base: 1, large: 2 }}
                backgroundColor={tokens.colors.neutral[20]}
              />
            </Grid>
          </ThemeProvider>
        </>
      )}}
    </Authenticator>
  );
}
