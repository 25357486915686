import { createTheme } from '@aws-amplify/ui-react';
import { studioTheme } from '../ui-components';

export default createTheme(
  {
    name: 'gadgetTheme',
    tokens: {
      components: {
        textfield: { color: studioTheme.tokens.colors.font.inverse.value},
        pagination: { current: { alignItems: 'center' } },
        stepperfield: { input: { color: 'white' }, button: { color: 'white' } },
        field:{label:{color: studioTheme.tokens.colors.font.inverse.value} },

      },
    },
    breakpoints: {
      values: {
        base: 0,
        small: 480,
        medium: 768,
        large: 992,
        xl: 1280,
        xxl: 1586,
      },
      defaultBreakpoint: "base",
    },
  },
  studioTheme
);
