/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Divider,
  Flex,
  Image,
  Text,
  View,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import GadgetIcons from "./GadgetIcons";
export default function DesignPackageCard(props) {
  const {
    packageImage,
    packageDescription,
    contractDetailsDisplay = "none",
    packageName,
    packagePrice,
    iconSlot,
    packageFeaturesFrame,
    overrides: overridesProp,
    ...restProp
  } = props;
  const variants = [
    {
      overrides: {
        MarketingGraphic: {},
        DesignPackageName: {},
        Icon: {},
        iconSlot: {},
        NameAndInfoFrame: {},
        PackageDetailsText39192951: {},
        PackageDetailsFrame39192950: {},
        PackageDetailsFrame39192946: {},
        Price: {},
        Divider: {},
        GadgetIcons: {},
        PriceAndCallToActionFrame: {},
        PackageIntroCard: {},
        PackageDetailsText46658281: {},
        PackageDetailsFrame46658280: {},
        MarketingDescriptionFrame: {},
        DesignFeaturesCard39192957: {},
        DesignFeaturesCard39192958: {},
        DesignFeaturesCard39192959: {},
        DesignFeaturesCard39192960: {},
        PackageFeaturesFrame: {},
        FrameOfReference: {},
        DesignPackageCard: {},
      },
      variantValues: { variation: "default", breakpoint: "large" },
    },
    {
      overrides: {
        MarketingGraphic: {},
        DesignPackageName: {},
        Icon: {},
        iconSlot: {},
        NameAndInfoFrame: {},
        PackageDetailsText39192951: {},
        PackageDetailsFrame39192950: { display: "none" },
        PackageDetailsFrame39192946: {},
        Price: {},
        Divider: {},
        GadgetIcons: {},
        PriceAndCallToActionFrame: {},
        PackageIntroCard: {},
        PackageDetailsText46658281: {},
        PackageDetailsFrame46658280: {},
        MarketingDescriptionFrame: {},
        DesignFeaturesCard39192957: {},
        DesignFeaturesCard39192958: {},
        DesignFeaturesCard39192959: {},
        DesignFeaturesCard39192960: {},
        PackageFeaturesFrame: {},
        FrameOfReference: {},
        DesignPackageCard: {},
      },
      variantValues: { variation: "default", breakpoint: "medium" },
    },
    {
      overrides: {
        MarketingGraphic: {},
        DesignPackageName: {},
        Icon: {},
        iconSlot: {},
        NameAndInfoFrame: {},
        PackageDetailsText39192951: {},
        PackageDetailsFrame39192950: { display: "none" },
        PackageDetailsFrame39192946: {},
        Price: {},
        Divider: {},
        GadgetIcons: {},
        PriceAndCallToActionFrame: {},
        PackageIntroCard: {},
        PackageDetailsText46658281: {},
        PackageDetailsFrame46658280: { width: "230px", shrink: "0" },
        MarketingDescriptionFrame: {},
        DesignFeaturesCard39192957: {},
        DesignFeaturesCard39192958: {},
        DesignFeaturesCard39192959: {},
        DesignFeaturesCard39192960: {},
        PackageFeaturesFrame: { display: "none" },
        FrameOfReference: {},
        DesignPackageCard: {},
      },
      variantValues: { variation: "default", breakpoint: "small" },
    },
    {
      overrides: {
        MarketingGraphic: {},
        DesignPackageName: {},
        Icon: {},
        iconSlot: {},
        NameAndInfoFrame: {},
        PackageDetailsText39192951: {},
        PackageDetailsFrame39192950: {},
        PackageDetailsFrame39192946: {},
        Price: {},
        Divider: {},
        GadgetIcons: {},
        PriceAndCallToActionFrame: {},
        PackageIntroCard: {
          border: "2px SOLID rgba(0,0,0,1)",
          borderRadius: "11px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        },
        PackageDetailsText46658281: { color: "rgba(255,255,255,1)" },
        PackageDetailsFrame46658280: {},
        MarketingDescriptionFrame: {},
        DesignFeaturesCard39192957: { border: "2px SOLID rgba(255,255,255,1)" },
        DesignFeaturesCard39192958: { border: "2px SOLID rgba(255,255,255,1)" },
        DesignFeaturesCard39192959: { border: "2px SOLID rgba(255,255,255,1)" },
        DesignFeaturesCard39192960: { border: "2px SOLID rgba(255,255,255,1)" },
        PackageFeaturesFrame: {},
        FrameOfReference: {},
        DesignPackageCard: { backgroundColor: "rgba(102,0,102,1)" },
      },
      variantValues: { variation: "focus", breakpoint: "large" },
    },
    {
      overrides: {
        MarketingGraphic: {},
        DesignPackageName: {},
        Icon: { type: "info" },
        iconSlot: {},
        NameAndInfoFrame: {},
        PackageDetailsText39192951: {},
        PackageDetailsFrame39192950: { display: "none" },
        PackageDetailsFrame39192946: {},
        Price: {},
        Divider: {},
        GadgetIcons: {},
        PriceAndCallToActionFrame: {},
        PackageIntroCard: {
          border: "2px SOLID rgba(0,0,0,1)",
          borderRadius: "11px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        },
        PackageDetailsText46658281: { color: "rgba(255,255,255,1)" },
        PackageDetailsFrame46658280: {},
        MarketingDescriptionFrame: {},
        DesignFeaturesCard39192957: { border: "2px SOLID rgba(255,255,255,1)" },
        DesignFeaturesCard39192958: { border: "2px SOLID rgba(255,255,255,1)" },
        DesignFeaturesCard39192959: { border: "2px SOLID rgba(255,255,255,1)" },
        DesignFeaturesCard39192960: { border: "2px SOLID rgba(255,255,255,1)" },
        PackageFeaturesFrame: {},
        FrameOfReference: {},
        DesignPackageCard: { backgroundColor: "rgba(102,0,102,1)" },
      },
      variantValues: { variation: "focus", breakpoint: "medium" },
    },
    {
      overrides: {
        MarketingGraphic: {},
        DesignPackageName: { color: "rgba(13,26,38,1)" },
        Icon: { type: "info" },
        iconSlot: {},
        NameAndInfoFrame: {},
        PackageDetailsText39192951: {},
        PackageDetailsFrame39192950: { display: "none" },
        PackageDetailsFrame39192946: {},
        Price: {},
        Divider: {},
        GadgetIcons: {},
        PriceAndCallToActionFrame: {},
        PackageIntroCard: {
          border: "2px SOLID rgba(0,0,0,1)",
          borderRadius: "11px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        },
        PackageDetailsText46658281: { color: "rgba(255,255,255,1)" },
        PackageDetailsFrame46658280: {},
        MarketingDescriptionFrame: {},
        DesignFeaturesCard39192957: { border: "2px SOLID rgba(255,255,255,1)" },
        DesignFeaturesCard39192958: { border: "2px SOLID rgba(255,255,255,1)" },
        DesignFeaturesCard39192959: { border: "2px SOLID rgba(255,255,255,1)" },
        DesignFeaturesCard39192960: { border: "2px SOLID rgba(255,255,255,1)" },
        PackageFeaturesFrame: { display: "none" },
        FrameOfReference: {},
        DesignPackageCard: { backgroundColor: "rgba(102,0,102,1)" },
      },
      variantValues: { variation: "focus", breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    small: "small",
    medium: "medium",
    medium: "medium",
    large: "large",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="300px"
      height="unset"
      justifyContent="center"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      border="1px SOLID rgba(174,179,183,1)"
      borderRadius="20px"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "DesignPackageCard")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        borderRadius="10px"
        padding="15px 15px 15px 15px"
        display="flex"
        {...getOverrideProps(overrides, "FrameOfReference")}
      >
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          border="1px SOLID rgba(0,0,0,1)"
          borderRadius="10px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          {...getOverrideProps(overrides, "PackageIntroCard")}
        >
          <Image
            width="unset"
            height="155px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            {...getOverrideProps(overrides, "MarketingGraphic")}
          ></Image>
          <Flex
            gap="5px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="10px 20px 10px 20px"
            display="flex"
            {...getOverrideProps(overrides, "PackageDetailsFrame39192946")}
          >
            <Flex
              gap="5px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "NameAndInfoFrame")}
            >
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="700"
                color="rgba(48,64,80,1)"
                lineHeight="25px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={packageName}
                {...getOverrideProps(overrides, "DesignPackageName")}
              ></Text>
              <View
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                children={iconSlot}
                {...getOverrideProps(overrides, "iconSlot")}
              ></View>
            </Flex>
            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="10px"
              padding="0px 0px 0px 0px"
              display={contractDetailsDisplay}
              {...getOverrideProps(overrides, "PackageDetailsFrame39192950")}
            >
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="400"
                color="rgba(92,102,112,1)"
                lineHeight="21px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="This package is the greatest of all because it's so awesome and wonderful and fun and exciting we can't wait to engineer for you!"
                {...getOverrideProps(overrides, "PackageDetailsText39192951")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="14px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-end"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            border="2px SOLID rgba(0,0,0,1)"
            borderRadius="0px 0px 10px 10px"
            padding="8px 18px 8px 18px"
            backgroundColor="rgba(239,240,240,1)"
            display="flex"
            {...getOverrideProps(overrides, "PriceAndCallToActionFrame")}
          >
            <Text
              fontFamily="Inter"
              fontSize="32px"
              fontWeight="500"
              color="rgba(13,26,38,1)"
              lineHeight="40px"
              textAlign="right"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={packagePrice}
              {...getOverrideProps(overrides, "Price")}
            ></Text>
            <Divider
              height="50px"
              shrink="0"
              size="small"
              orientation="vertical"
              {...getOverrideProps(overrides, "Divider")}
            ></Divider>
            <GadgetIcons
              width="24px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              type="send"
              {...getOverrideProps(overrides, "GadgetIcons")}
            ></GadgetIcons>
          </Flex>
        </Flex>
        <Flex
          gap="14px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          borderRadius="0px 0px 10px 10px"
          padding="10px 4px 10px 4px"
          display="flex"
          {...getOverrideProps(overrides, "MarketingDescriptionFrame")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="10px"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "PackageDetailsFrame46658280")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="21px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={packageDescription}
              {...getOverrideProps(overrides, "PackageDetailsText46658281")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="20px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          borderRadius="17px"
          padding="0px 0px 0px 0px"
          display="flex"
          children={packageFeaturesFrame}
          {...getOverrideProps(overrides, "PackageFeaturesFrame")}
        ></Flex>
      </Flex>
    </Flex>
  );
}
