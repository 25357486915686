// React
import { useContext, useState, useEffect } from "react";

// Amplify
import { DataStore } from "aws-amplify";
import {
  Project,
  ProjectLocation,
  Quote,
  User,
  UserOrganizationRelationships,
} from "../models";

// Third-party libraries
import { encode as base64_encode } from "base-64";
import axios from "axios";
import Swal from "sweetalert2";

// Amplify
import {
  Button,
  Card,
  TextField,
  CheckboxField,
  Flex,
  Text,
} from "@aws-amplify/ui-react";

// Gadget Engineering Imports
import { InstantQuoteContext } from "../Context";

export default function QuoteRecipientsCard(props) {
  // Props
  const { setOpen, ...rest } = props;

  // Context
  const {
    instantQuoteApi,
    user,
    organizationName,
    squareFootage,
    discipline,
    constructionType,
    surveyType,
    projectRecord,
    address,
    projectId,
    organization,
    projectName,
    authorizerName,
    setAuthorizerName,
    authorizerEmail,
    setAuthorizerEmail,
    validateEmail,
  } = useContext(InstantQuoteContext);

  // State
  const [optionalEmail, setOptionalEmail] = useState("");
  const [checked, setChecked] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  // eslint-disable-next-line

  // Constants

  // Arrow Functions

  // Effects
  useEffect(() => {
    if (user["signInUserSession"]) {
      const userGroup =
        user["signInUserSession"]["idToken"]["payload"]["cognito:groups"];
      if (userGroup.includes("Admin")) {
        setIsAdmin(true);
      }
    }
  }, [setOpen]);

  // Memos

  // Function Declarations

  async function checkUser() {
    const userList = await DataStore.query(User, (person) =>
      person.and((person) => [
        person.cognitoUserID.eq(user.attributes.sub),
        person.cognitoUserPoolID.eq(user["pool"]["userPoolId"]),
      ])
    );
    if (userList.length === 0)
      await DataStore.save(
        new User({
          email: user.attributes.email,
          name: user.attributes.name,
          cognitoUserID: user.attributes.sub,
          cognitoUserPoolID: user.pool.userPoolId,
        })
      );
  }

  async function sendQuote() {
    const encodedQuoteEmailBody = base64_encode(
      document.body.getElementsByClassName("quoteEmailBody")[0].outerHTML
    );
    if (
      authorizerName.length > 0 &&
      validateEmail(authorizerEmail) &&
      (optionalEmail.length === 0 || validateEmail(optionalEmail))
    ) {
      checkUser();
      setOpen(false);
      Swal.fire({
        icon: "info",
        title: "Preparing your Quote...",
        showConfirmButton: false,
      });

      const updateProject = await DataStore.query(Project, projectId);
      const userList = await DataStore.query(User, (person) =>
        person.and((person) => [
          person.cognitoUserID.eq(user.attributes.sub),
          person.cognitoUserPoolID.eq(user["pool"]["userPoolId"]),
        ])
      );
      const userOrgList = await DataStore.query(User, (person) =>
        person.and((person) => [
          person.cognitoUserID.eq(user.attributes.sub),
          person.cognitoUserPoolID.eq(user["pool"]["userPoolId"]),
          person.organizations.organization.id.eq(organization?.id),
        ])
      );
      if (userOrgList.length === 0)
        await DataStore.save(
          new UserOrganizationRelationships({
            userID: userList[0]?.id,
            organizationID: organization?.id,
          })
        );
      const sendEmailFields = {
        userName: user.attributes.name,
        address: address,
        authorizerName: authorizerName,
        authorizerEmail: authorizerEmail.toLowerCase(),
        ccEmail: optionalEmail.toLowerCase(),
        encodedQuoteEmailBody: encodedQuoteEmailBody,
        packagePrice: projectRecord.totalValue,
        squareFootage: squareFootage,
        discipline: discipline,
        constructionType: constructionType,
        surveyType: surveyType,
        userEmail: user.attributes.email,
        organizationDetails: organization,
        projectName: projectName,
        pipedriveID: updateProject.pipedriveID,
        projectId: projectId,
        isNewQuote: isAdmin ? !checked : true,
        isDeleteProject: false,
      };
let msg;
      try {
        await axios
          .post(`${instantQuoteApi}/proposals/email`, sendEmailFields)
          .then((res) => {
            console.log(res);
            msg = res.data.msg;
          });
      } catch (error) {
        console.log(error);
      }
      try {
        await axios
          .post(`${instantQuoteApi}/proposals/pipedrive`, sendEmailFields)
          .then((res) => {
            console.log(res);
            saveProject(res.data.deal_id, msg);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function saveProject(pipedriveID, msg) {
    // TODO add another storage event that updates local and saves remote with authorizer data

    const updateProject = await DataStore.query(Project, projectId);
    await DataStore.save(
      Project.copyOf(updateProject, (projectCopy) => {
        projectCopy.name = projectName;
        projectCopy.organizationProjectsId = organization?.id;
        projectCopy.tradeSupervisions = [...projectRecord.tradeSupervisions];
        projectCopy.totalValue = projectRecord.totalValue;
        projectCopy.projectInputs = { ...projectRecord.projectInputs };
        projectCopy.contractPricingConfigurationSnapshot = {
          ...projectRecord.contractPricingConfigurationSnapshot,
        };
        projectCopy.pipedriveID = pipedriveID;
        projectCopy.authorizerEmail = authorizerEmail.toLowerCase();
        projectCopy.authorizerName = authorizerName;
      })
    ).then(() => {
      if (msg === "Error")
        Swal.fire({
          icon: "error",
          title: "Something went wrong!",
          text: "Check the entered mail ID's",
        });
      else createQuote();
    });
  }

  async function createQuote() {
    // TODO add another storage event that updates local and saves remote with authorizer data

    const userList = await DataStore.query(User, (person) =>
      person.and((person) => [
        person.cognitoUserID.eq(user.attributes.sub),
        person.cognitoUserPoolID.eq(user["pool"]["userPoolId"]),
      ])
    );

    await DataStore.save(
      new Quote({
        userEmail: user.attributes.email,
        authorizerEmail: authorizerEmail.toLowerCase(),
        authorizerName: authorizerName,
        name: projectName,
        tradeSupervisions: [...projectRecord.tradeSupervisions],
        totalValue: projectRecord.totalValue,
        projectInputs: { ...projectRecord.projectInputs },
        contractPricingConfigurationSnapshot: {
          ...projectRecord.contractPricingConfigurationSnapshot,
        },
        organizationQuotesId: organization?.id,
        userQuotesId: userList[0]?.id,
        project: projectRecord.id,
        editors: [`${user.attributes.sub}::${user.username}`]
      })
    ).then((res) => {
      Swal.fire({
        icon: "success",
        title: "Package Sent!",
        text: "The details were sent to your Inbox",
      }).then(() => window.location.reload());
    });
  }

  return (
    <>
      <Card
        padding={"15px"}
        margin="10px"
        borderRadius="15px"
        // width="100%"
        maxWidth={"500px"}
        // maxHeight={"600px"}
        height="auto"
        border="1px solid gray"
      >
        <Flex direction={"column"}>
          <Text>
            You are creating a proposal for:
            <Text fontWeight="bold">{organizationName}</Text>
          </Text>
          <Text maxWidth={"600px"} fontWeight="bold">
            Please enter the contact information for the party that would be
            responsible for signing and authorizing this proposal. Adding the
            signer’s name and email does not obligate you to hire PermitZip:
          </Text>
          <TextField
            variation="quiet"
            size="default"
            style={{ color: "black" }}
            value={authorizerName ? authorizerName : null}
            placeholder="Authorized Signer's Name..."
            errorMessage="required"
            onChange={(e) => {
              setAuthorizerName(e.target?.value);
            }}
            hasError={authorizerName.length === 0}
          />
          <TextField
            variation="quiet"
            size="default"
            value={authorizerEmail ? authorizerEmail : null}
            style={{ color: "black" }}
            placeholder="Authorized Signer's Email..."
            errorMessage={
              authorizerEmail.length === 0
                ? "required"
                : !validateEmail(authorizerEmail) &&
                  "enter a valid email address"
            }
            onChange={(e) => {
              setAuthorizerEmail(e.target?.value);
            }}
            hasError={
              authorizerEmail.length === 0 || !validateEmail(authorizerEmail)
            }
          />
          <Text>
            You are logged in as
            <Text fontWeight={"bold"}>{user.attributes.email}</Text>
          </Text>
          <Text maxWidth={"500px"}>
            If you would like to CC this proposal to an additional email
            address, please enter below:
          </Text>
          <TextField
            variation="quiet"
            size="default"
            style={{ color: "black" }}
            placeholder="Additional email address..."
            errorMessage="enter a valid email address"
            onChange={(e) => {
              setOptionalEmail(e.target.value);
            }}
            hasError={!validateEmail(optionalEmail) && optionalEmail.length > 0}
          />
          {isAdmin && (
            <CheckboxField
              name="subscribe-controlled"
              value="yes"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              label="Exclude the email"
            />
          )}
          <Button
            width={"100%"}
            maxWidth={"600px"}
            fontSize="18px"
            fontWeight="600"
            color={"#fff"}
            backgroundColor={"brand.primary.80"}
            onClick={() => {
              sendQuote();
            }}
          >
            Send my Project Estimate
          </Button>
        </Flex>
      </Card>
    </>
  );
}
