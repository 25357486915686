// React
import { useContext, useEffect, useState } from "react";

// Gadget Engineering Libraries
import { InstantQuoteContext } from "../Context/ProposalContext";

export default function DesignPackagesComparison(props) {
  // Constants and Context -----------------------------------------------------
  const { ...rest } = props;
  const {
    packageName,
    constructionType,
    surveyType,
    packagePriceTotal,
    changeInPackageCalculation,
    addCommaToPrice,
  } = useContext(InstantQuoteContext);

  const [packagePrices, setPackages] = useState({
    gist: 0,
    minimalist: 0,
    collaborator: 0,
    perfectionist: 0,
    twoWeek: 0,
  });
  const imageStyle = {
    height: "20px",
    width: "20px",
    borderRadius: "12px",
    background: "#fff",
  };
  const checkBoxIcon = (
    <img
      style={imageStyle}
      src="https://cdn-icons-png.flaticon.com/512/2767/2767140.png"
      alt=""
    />
  );

  const design = [
    {
      name: "Minimalist",
      sd: [1, " SD", "1 Schematic Design (SD)"],
      dd: [1, " DD", "1 Design Development (DD)"],
      cd: [1, " CD", "1 Construction Documents Set (CD)"],
      planReview: [null, checkBoxIcon, "Plan Review Comments"],
      submittalReview: [null, checkBoxIcon, "Submittal Review"],
    },
    {
      name: "Collaborator",
      sd: [4, " SD", "1 Schematic Design (SD)"],
      dd: [1, " DD", "1 Design Development (DD)"],
      cd: [1, " CD", "1 Construction Documents Set (CD)"],
      planReview: [null, checkBoxIcon, "Plan Review Comments"],
      submittalReview: [null, checkBoxIcon, "Submittal Review"],
    },
    {
      name: "Perfectionist",
      sd: [5, " SD", "1 Schematic Design (SD)"],
      dd: [3, " DD", "1 Design Development (DD)"],
      cd: [1, " CD", "1 Construction Documents Set (CD)"],
      planReview: [null, checkBoxIcon, "Plan Review Comments"],
      submittalReview: [null, checkBoxIcon, "Submittal Review"],
    },
  ];

  const support = [
    {
      name: "Minimalist",
      sdMeeting: [1, "1 SD Meeting"],
      virtualSurvey: setVirtualSurvey(1),
      supportHrs: [
        "11*",
        "Minimum Engineering Support Hours (Included in Base Contract)",
      ],
      addHrs: [0, "Additional Engineering Support Hours"],
      price: packagePrices.minimalist,
    },
    {
      name: "Collaborator",
      sdMeeting: [5, "1 Kick-Off, 4 SD"],
      virtualSurvey: setVirtualSurvey(1),
      supportHrs: [
        "11*",
        "Minimum Engineering Support Hours (Included in Base Contract)",
      ],
      addHrs: [40, "Additional Engineering Support Hours"],
      price: packagePrices.collaborator,
    },
    {
      name: "Perfectionist",
      sdMeeting: [10, "1 Kick-Off, 4 SD, 3 DD, 3 AOC"],
      virtualSurvey: setVirtualSurvey(2),
      supportHrs: [
        "11*",
        "Minimum Engineering Support Hours (Included in Base Contract)",
      ],
      addHrs: [160, "Additional Engineering Support Hours"],
      price: packagePrices.perfectionist,
    },
  ];

  const designPackagesEmailTemplateContainer = {
    background: "#000",
    borderRadius: "12px",
    padding: "0px",
    textAlign: "center",
  };

  const designPackagesEmailTemplate = {
    minWidth: "920px",
    maxWidth: "1000px",
    background: "rgb(32,32,32)",
    borderRadius: "12px",
    textAlign: "center",
    padding: "0px",
    margin: "0",
  };

  const packageTitle = {
    position: "sticky",
    top: "0px",
    width: "100%",
  };
  const packageTitleUl = {
    display: "inline-block",
    color: "#fff",
    padding: "0px",
  };
  const packageTitleList = {
    display: "inline-block",
    listStyle: "none",
    textAlign: "center",
    padding: "12px",
    borderRadius: "10px",
    background: "rgb(26 26 27)",
    color: "#fff",
    fontSize: "23px",
    fontWeight: "bold",
    minWidth: "205px",
  };
  const packageTitleListActive = {
    display: "inline-block",
    listStyle: "none",
    textAlign: "center",
    padding: "12px",
    borderRadius: "10px",
    background: "#ff703b",
    color: "rgb(241 241 241)",
    fontSize: "23px",
    fontWeight: "bold",
    minWidth: "205px",
  };

  const designCover = {
    minWidth: "920px",
    maxWidth: "1000px",
    display: "inline-block",
    alignItems: "stretch",
    justifyContent: "space-evenly",
  };

  const priceCardUl = {
    display: "inline-block",
    borderRadius: "10px",
    background: "rgb(26 26 27)",
    color: "#fff",
    padding: "15px",
    margin: "10px 8px",
  };

  const priceCardUlActive = {
    display: "inline-block",
    borderRadius: "10px",
    background: "#ff703b",
    color: "rgb(241 241 241)",
    padding: "15px",
    margin: "10px 8px",
  };

  const priceCardList = {
    listStyle: "none",
    textAlign: "center",
    padding: "10px",
    height: "60px",
    width: "160px",
  };

  const designCardUl = {
    display: "inline-block",
    borderRadius: "10px",
    background: "rgb(26 26 27)",
    color: "#fff",
    padding: "15px",
    margin: "10px 8px",
    height: "contain",
  };

  const designCardUlActive = {
    display: "inline-block",
    borderRadius: "10px",
    background: "#ff703b",
    color: "rgb(241 241 241)",
    padding: "15px",
    margin: "10px 8px",
    height: "contain",
  };

  const designCardList = {
    listStyle: "none",
    padding: "20px 10px",
    width: "160px",
    minHeight: "155px",
  };

  const heading = {
    fontSize: "23px",
    fontWeight: "bold",
  };

  const subHeading = {
    fontSize: "18px",
    fontWeight: "400",
    wordBreak: "all",
  };

  const priceHeading = {
    fontSize: "23px",
    wordBreak: "all",
    fontWeight: "bold",
  };

  // React Effects (execute after render completion) ---------------------------
  // (tip: return a closure if cleanup after executing the effect)
  // (tip: pass a list of state variables to watch to limit invocation frequency)
  // (more: https://reactjs.org/docs/hooks-effect.html)
  // TODO: Replace this line with react effects when needed
  useEffect(
    () => {
      setPackages(packagePriceTotal());
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeInPackageCalculation]
  );
  // React Memos (functions to execute and cache calculated values) ------------
  // (note: functions should be pure (i.e. not accept arguments))
  // (note: functions should return any type)
  // (tip: pass a list of state variables to watch to limit memo calc frequency)
  // TODO: Replace this line with react memos when needed

  // Function Expressions (to be passed as arguments or as closures) -----------
  // TODO: Replace this line with function expressions when needed

  // Arrow Functions (to lexically bind the 'this' value) ----------------------
  // TODO: Replace this line with arrow functions when needed

  // Function Declarations (can be invoked from location in the scope) ----------
  // (note: function declarations are hoisted to top scope by interpreter)

  function setVirtualSurvey(number) {
    if (constructionType["reUse"] === 1) {
      if (surveyType["virtualSurvey"] === 1)
        return [number, number + " Preliminary Field Visit(Virtual Survey)"];
      else
        return [number, number + " Preliminary Field Visit(In Person Survey)"];
    } else return [0, "Preliminary Field Visit"];
  }

  return (
    <div {...rest} style={designPackagesEmailTemplateContainer}>
      <div
        className="DesignPackagesComparison"
        style={{ textAlign: "-webkit-center", width: "100%" }}
      >
        <div style={designPackagesEmailTemplate}>
          <div style={designCover}>
            <div style={packageTitle}>
              <ul style={packageTitleUl}>
                {design.map((value, index) => (
                  <li
                    key={index}
                    style={
                      value.name !== packageName
                        ? packageTitleList
                        : packageTitleListActive
                    }
                  >
                    {value.name}
                  </li>
                ))}
              </ul>
            </div>

            <div style={{ fontSize: "11px", color: "#fff" }}>
              <h1>Design</h1>
              <hr></hr>
            </div>
            {design.map((value, index) => (
              <ul
                key={index}
                style={
                  value.name === packageName ? designCardUlActive : designCardUl
                }
              >
                <li style={designCardList}>
                  <div style={heading}>
                    {value.sd[0] < 1 ? "NA" : value.sd[0] + value.sd[1]}
                  </div>
                  <div style={subHeading}>
                    {value.sd[0] < 1
                      ? "Not Included"
                      : value.sd[0] < 2
                        ? value.sd[2]
                        : value.sd[2] +
                        "\n" +
                        (value.sd[0] - 1 + value.sd[1]) +
                        " iterations"}
                  </div>
                </li>
                <li style={designCardList}>
                  <div style={heading}>
                    {value.dd[0] < 1 ? "NA" : value.dd[0] + value.dd[1]}
                  </div>
                  <div style={subHeading}>
                    {value.dd[0] < 1
                      ? "Not Included"
                      : value.dd[0] < 2
                        ? value.dd[2]
                        : value.dd[2] +
                        "\n" +
                        (value.dd[0] - 1 + value.dd[1]) +
                        " iterations"}
                  </div>
                </li>
                <li style={designCardList}>
                  <div style={heading}>
                    {value.cd[0] < 1 ? "NA" : value.cd[0] + value.cd[1]}
                  </div>
                  <div style={subHeading}>
                    {value.cd[0] < 1
                      ? "Not Included"
                      : value.cd[0] < 2
                        ? value.cd[2]
                        : value.cd[2] +
                        "\n" +
                        (value.cd[0] - 1 + value.cd[1]) +
                        " iterations"}
                  </div>
                </li>
                <li style={designCardList}>
                  <div style={heading}>
                    {value.planReview[0] == null ? value.planReview[1] : "NA"}
                  </div>
                  <div style={subHeading}>
                    {value.planReview[0] == null
                      ? value.planReview[2]
                      : "Not Included"}
                  </div>
                </li>
                <li style={designCardList}>
                  <div style={heading}>
                    {value.submittalReview[0] == null
                      ? value.submittalReview[1]
                      : "NA"}
                  </div>
                  <div style={subHeading}>
                    {value.submittalReview[0] == null
                      ? value.submittalReview[2]
                      : "Not Included"}
                  </div>
                </li>
              </ul>
            ))}
          </div>
          <div style={designCover}>
            <div style={{ fontSize: "11px", color: "#fff" }}>
              <h1>Support</h1>
              <hr></hr>
            </div>
            {support.map((value, index) => (
              <ul
                key={index}
                style={
                  value.name === packageName ? designCardUlActive : designCardUl
                }
              >
                <li style={designCardList}>
                  <div style={heading}>{value.sdMeeting[0]}</div>
                  <div style={subHeading}>{value.sdMeeting[1]}</div>
                </li>
                <li style={designCardList}>
                  <div style={heading}>{value.virtualSurvey[0]}</div>
                  <div style={subHeading}>{value.virtualSurvey[1]}</div>
                </li>
                <li style={designCardList}>
                  <div style={heading}>{value.supportHrs[0]}</div>
                  <div style={subHeading}>{value.supportHrs[1]}</div>
                </li>
                <li style={designCardList}>
                  <div style={heading}>{value.addHrs[0]}</div>
                  <div style={subHeading}>{value.addHrs[1]}</div>
                </li>
              </ul>
            ))}
          </div>

          <div>
            <div style={designCover}>
              <div
                style={{
                  fontSize: "11px",
                  color: "#fff",
                }}
              >
                <h1>Package Total</h1>
                <hr></hr>
              </div>

              {support.map((value, index) => (
                <ul
                  key={index}
                  style={
                    value.name === packageName ? priceCardUlActive : priceCardUl
                  }
                >
                  <li style={priceCardList}>
                    <div style={priceHeading}>
                      {addCommaToPrice(value.price)}
                    </div>
                  </li>
                </ul>
              ))}
            </div>
          </div>
          <div style={{ color: "#fff", margin: "0px", paddingBottom: "12px" }}>
            <p>
              * The minimum allowed hours for additional supervision for
              PermitZIP to accept the responsibility as Engineer of Record.
            </p>
          </div>
        </div>
        <p
          style={{ textAlign: "left", margin: "20px 8px", fontSize: "18px" }}
        >
          To edit or authorize this proposal for production, simply reply to this email or reach out to sales@permitzip.com.
        </p>
        <p
          style={{ textAlign: "left", margin: "25px 8px", fontSize: "20px", fontWeight: 'bold' }}
        >
          Thank you for using PermitZIP Instant Quote!
        </p>
      </div>
    </div>
  );
}
