/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import GadgetIcons from "./GadgetIcons";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function ToolTipModal(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-end"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "ToolTipModal")}
      {...rest}
    >
      <GadgetIcons
        width="81px"
        height="81px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="arrow-left"
        {...getOverrideProps(overrides, "GadgetIcons40677572")}
      ></GadgetIcons>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        position="relative"
        border="5px SOLID rgba(77,0,77,1)"
        boxShadow="0px 4px 8px rgba(0, 0, 0, 0.10000000149011612)"
        borderRadius="10px"
        padding="11px 11px 11px 11px"
        backgroundColor="rgba(149,4,149,1)"
        {...getOverrideProps(overrides, "ColoredBackgroundText")}
      >
        <Text
          fontFamily="Inter"
          fontSize="24px"
          fontWeight="600"
          color="rgba(255,255,255,1)"
          lineHeight="30px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="326px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Configure your project here to unlock live pricing estimates!"
          {...getOverrideProps(
            overrides,
            "Configure your project here to unlock live pricing estimates!"
          )}
        ></Text>
        <GadgetIcons
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="close"
          {...getOverrideProps(overrides, "GadgetIcons40677575")}
        ></GadgetIcons>
      </Flex>
    </Flex>
  );
}
