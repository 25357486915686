// React
import { useEffect, useContext, useState } from "react";

// Amplify
import { DataStore } from "aws-amplify";

import Swal from "sweetalert2";

import axios from "axios";

import {
  Flex,
  Divider,
  Text,
  View,
  useTheme,
  TextField,
  Button,
} from "@aws-amplify/ui-react";
import { Project, Organization } from "../../models";
import { ProjectSnapshotCardCollection } from "../../ui-components";

// Gadget Engineering Imports
import { InstantQuoteContext } from "../../Context/ProposalContext";
import ChangeTabTitle from "../ChangeTabTitle";
import {
  FieldWorkSelector,
  NameAndAddressSelector,
  DepartmentSelector,
  CompanySelector,
  SquareFootageSelector,
} from "..";
import InfoTooltip from "../InfoTooltip";
import UserContentModal from "../UserContentModal";

export default function NavigationBar(props) {
  const { ...rest } = props;
  // Context
  const {
    projectId,
    setProjectRecord,
    userInputProgress,
    projectHasMinimumRequiredInputs,
  } = useContext(InstantQuoteContext);



  // Subscribe to Remote Datastore Changes
  useEffect(
    () => {
      const projectSubscription = DataStore.observeQuery(Project, (project) =>
        project.id.eq(projectId)
      ).subscribe(({ items }) => {
        setProjectRecord(items[0]);
      });

      return () => {
        projectSubscription.unsubscribe(); // tear down when done
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    // Do not delete the parent View wrapper. Set props for it at the app.jsx level
    <View {...rest}>
    
      <Flex
        gap="10px"
        direction="column"
        width="100%"
        height="unset"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="stretch"
        overflow="hidden"
        position="relative"
        borderRadius="10px"
        padding="10px 10px 10px 10px"
        backgroundColor="rgba(255,255,255,1)"
        id="SideNavigationBar"
      >
        { (userInputProgress.isEditProject ||
          !(
            userInputProgress.isProjectNameSet &&
            userInputProgress.isProjectLocationSet
          )) && <NameAndAddressSelector id="NameAndAddressSelector" />}
          <ChangeTabTitle />
        {(userInputProgress.isEditProject ||
          (userInputProgress.isProjectNameSet &&
            userInputProgress.isProjectLocationSet)) && (
          <>
            {(userInputProgress.isEditProject ||
              !projectHasMinimumRequiredInputs) && (
              <CompanySelector id="CompanySelector" />
            )}
            {(userInputProgress.isEditProject ||
              projectHasMinimumRequiredInputs) && (
              <>
                {userInputProgress.isEditProject && <AuthorizerInfoCard />}
                <DepartmentSelector is="DepartmentSelector" />
                <FieldWorkSelector id="FieldWorkSelector" />
                <SquareFootageSelector id="SquareFootageSelector" />
                <Divider></Divider>
              </>
            )}
          </>
        )}
        <ContractCollectionWrapper />
      </Flex>
    </View>
  );
}

function AuthorizerInfoCard() {
  const {
    authorizerName,
    setAuthorizerName,
    authorizerEmail,
    setAuthorizerEmail,
    validateEmail,
  } = useContext(InstantQuoteContext);
  const { tokens } = useTheme();

  const tooltipMessage =
    "The contact information of the party that would be responsible for signing and authorizing this proposal";
  return (
    <>
      <View
        display={{ base: "none", large: "flex" }}
        gap="24px"
        direction="column"
        width="100%"
        height="unset"
        justifyContent="flex-start"
        alignItems="stretch"
        overflow="hidden"
        shrink="1"
        grow="1"
        position="relative"
        border="1px SOLID rgba(174,179,183,1)"
        borderRadius="10px"
        padding="16px"
        backgroundColor={tokens.colors.brand.secondary[90]}
      >
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Text
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="600"
            color="rgba(255,255,255,1)"
            lineHeight="30px"
            textAlign="left"
            padding="0px 0px 0px 5px"
            whiteSpace="pre-wrap"
          >
            Authorizer's Details
          </Text>
          <InfoTooltip
            visibility="true"
            message={tooltipMessage}
            color={"#fff"}
          />
        </Flex>
        <TextField
          variation="quiet"
          size="default"
          style={{ color: "white" }}
          placeholder="Authorized Signer's Name..."
          value={authorizerName ? authorizerName : ""}
          onChange={(e) => {
            setAuthorizerName(e.target.value);
          }}
        />
        <TextField
          variation="quiet"
          size="default"
          style={{ color: "white" }}
          placeholder="Authorized Signer's Email..."
          errorMessage={
            !validateEmail(authorizerEmail) && "enter a valid email address"
          }
          value={authorizerEmail ? authorizerEmail : ""}
          onChange={(e) => {
            setAuthorizerEmail(e.target.value);
          }}
          hasError={
            authorizerEmail.length > 0 && !validateEmail(authorizerEmail)
          }
        />
      </View>
    </>
  );
}

function ContractCollectionWrapper() {
  const {
    instantQuoteApi,
    user,
    addCommaToPrice,
    setProjectName,
    setAddress,
    setChangeInLocation,
    userInputProgress,
    setUserInputProgress,
    setProjectRecord,
    setOrganization,
    setOrganizationHasBeenSelected,
    setDiscipline,
    discipline,
    setConstructionType,
    constructionType,
    setSurveyType,
    surveyType,
    squareFootage,
    setSquareFootage,
    setIsMultiFam,
    setProjectId,
    setPackageName,
    setOrganizationName,
    setChangeInPackageCalculation,
    changeInPackageCalculation,
    setAuthorizerName,
    setAuthorizerEmail,
    setChangeInSquareFootage,
    changeInSquareFootage,
    userContentModalOpen,
    setUserContentModalOpen,
    setIndex,
    adaptiveReuse, 
    setAdaptiveReuse
  } = useContext(InstantQuoteContext);

  

  useEffect(() => {
    if (localStorage.getItem("projectId")) {
      setProjectId(localStorage.getItem("projectId"));
      editProject(localStorage.getItem("projectId"));
      localStorage.removeItem("projectId");
    }
 
  }, []);

  const [indexOfItem, setIndexOfItem] = useState(-1);

  function textReveal(name, index, maxLength) {
    if (index === indexOfItem) return [index, name];
    if (name.length > maxLength) {
      const val = name.slice(0, maxLength - 3) + "...";
      return [index, val];
    } else return [index, name];
  }

  async function deleteProject(project) {
    const currentProject = await DataStore.query(Project, project.id);
    if (currentProject.pipedriveID) {
      const sendEmailFields = {
        pipedriveID: currentProject.pipedriveID,
        userEmail: user.attributes.email,
        isDeleteProject: true,
      };
      Swal.fire({
        icon: "info",
        title: "Deleting your project...",
        // text: "Once the email is sent, the confirmation message will be shown",
        showConfirmButton: false,
      });
      try {
        await axios.post(`${instantQuoteApi}/proposals/pipedrive`, sendEmailFields).then((res) => {
          if (res.data.errorMessage)
            Swal.fire({
              icon: "error",
              title: "Something went wrong!",
              text: "Check the entered mail ID's",
            });
        });
      } catch (error) {
        console.log(error);
      }
    }
    await DataStore.delete(currentProject).then(() => {
      Swal.fire({
        icon: "success",
        title: "Project Deleted!",
        text: "Project was deleted successfully!",
      }).then(() => window.location.reload());
    });
  }

  function saveProjectId(id) {
    if (localStorage.getItem("projectId")) localStorage.removeItem("projectId");
    if (localStorage.getItem("quoteId")) localStorage.removeItem("quoteId");
    localStorage.setItem("projectId", id);
    window.location.reload();
  }

  async function editProject(id) {
    const currentProject = await DataStore.query(Project, id);
    setProjectRecord(currentProject);
    userInputProgress.isEditProject = true;
    setUserInputProgress(userInputProgress);

    setProjectName(currentProject.name);

    setAddress(currentProject.projectInputs?.projectLocation.address);
    setChangeInLocation(true);

    const org = await DataStore.query(
      Organization,
      currentProject.organizationProjectsId
    );
    setOrganization(org);
    setOrganizationHasBeenSelected(true);
    setOrganizationName(org.name);

    const SqftName = {
      brewerySqf: "brewery",
      multifamilySqf: "multiFamily",
      officeAndRetailSqf: "office",
      restaurantSqf: "restaurant",
      uniqueMultifamilyLayouts: "uniqueLayouts",
      warehouseSqf: "warehouse",
      medicalSqf: "medical",
      totalSqf: "totalSquareFootage",
    };
    if (currentProject.projectInputs["multifamilySqf"] > 0) setIsMultiFam(true);
    for (let sqft in SqftName) {
      if (currentProject.projectInputs[sqft])
        squareFootage[SqftName[sqft]] = currentProject.projectInputs[sqft];
      else squareFootage[SqftName[sqft]] = 0;
    }
    setSquareFootage(squareFootage);

    if (currentProject.authorizerEmail) {
      setAuthorizerEmail(currentProject.authorizerEmail);
    } else {
      setAuthorizerEmail("");
    }
    if (currentProject.authorizerName) {
      setAuthorizerName(currentProject.authorizerName);
    } else {
      setAuthorizerName("");
    }

    const tradeDisciplineName = {
      mechanical: "MECH",
      electrical: "ELEC",
      plumbing: "PLUMB",
    };
    for (let trade in tradeDisciplineName) {
      if (currentProject.tradeSupervisions.includes(tradeDisciplineName[trade]))
        discipline[trade] = 1;
      else discipline[trade] = 0;
    }
    setDiscipline(discipline);

    if (currentProject.projectInputs.constructionType === "GROUND_UP") {
      constructionType.isGroundUp = 1;
      constructionType.isAdaptiveReuse = 0;
    }
    else{
      constructionType.isAdaptiveReuse = 1;
      constructionType.isGroundUp = 0;
    } 
    setConstructionType(constructionType);

    setSurveyType(surveyType);

    if (currentProject.projectInputs.constructionType === "ADAPTIVE_REUSE_CLEAN_SLATE") {
      adaptiveReuse.cleanSlate = 1;
      adaptiveReuse.recycledSystem = 0;
    }
    else{
      adaptiveReuse.recycledSystem = 1;
      adaptiveReuse.cleanSlate = 0;
    } 
    setAdaptiveReuse(adaptiveReuse);

    if (currentProject.projectInputs.preferredContractPackage)
      setPackageName(
        currentProject.projectInputs.preferredContractPackage.charAt(0) +
          currentProject.projectInputs.preferredContractPackage
            .slice(1)
            .toLowerCase()
      );
    else setPackageName("");

    setChangeInSquareFootage(!changeInSquareFootage);

    setChangeInPackageCalculation(!changeInPackageCalculation);
  }


  const { tokens } = useTheme();
  const Css = `
 .amplify-pagination__item-button, .amplify-pagination__item-button--link{
  color: white;
}
.amplify-pagination__item-button:hover{
  color: white;
}

.iconHoverToChange:hover{
  background: lightblue;
  cursor: pointer;
 border-radius: 10px;
  transition:0.2s ease-out;
}
`;

  const tooltipMessage =
    "This is a record of your previously configured projects. Here you can edit project details, create new quotes, and soon be able to create and track entire contracts!";
  return (
    <>
      <style>{Css}</style>
      <View
        display={{ base: "none", large: "flex" }}
        gap="24px"
        direction="column"
        width="100%"
        height="unset"
        justifyContent="flex-start"
        alignItems="stretch"
        overflow="hidden"
        shrink="1"
        grow="1"
        position="relative"
        border="1px SOLID rgba(174,179,183,1)"
        borderRadius="10px"
        padding="16px"
        backgroundColor={tokens.colors.brand.secondary[90]}
      >
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Text
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="600"
            color="rgba(255,255,255,1)"
            lineHeight="30px"
            textAlign="left"
            padding="0px 0px 0px 5px"
            whiteSpace="pre-wrap"
          >
            Existing Projects
          </Text>
          <InfoTooltip
            visibility="true"
            message={tooltipMessage}
            color={"#fff"}
          />
        </Flex>
        <Button
          onClick={() => {
            setUserContentModalOpen(true);
            setIndex(0);
          }}
          color={"white"}
        >
          View All
        </Button>
        <ProjectSnapshotCardCollection
          type="list"
          itemsPerPage={3}
          overrideItems={({ item, index }) => ({
            overrides: {
              proposalExpirationWarning: {
                display: item.createdAt ? "none" : "block",
                children: 'Saving operation is in progress...',
                heading:"Project not saved",
              },
              FrameOfReference: {
                height:'auto',
              },
              ProjectName: {
                children:
                  textReveal(item.name, index, 18)[0] === index &&
                  textReveal(item.name, index, 18)[1],
                onMouseEnter: () => setIndexOfItem(index),
                onMouseLeave: () => setIndexOfItem(-1),
              },
              ProjectAddress: {
                children:
                  textReveal(item.projectInputs.projectLocation?.address, index, 50)[0] ===
                    index &&
                  textReveal(item.projectInputs.projectLocation?.address, index, 50)[1],
                onMouseEnter: () => setIndexOfItem(index),
                onMouseLeave: () => setIndexOfItem(-1),
              },
              packageTypeBadge: {
                children: item.projectInputs.preferredContractPackage,
                display: !item.projectInputs.preferredContractPackage && "none",
              },
              TotalFee: {
                children: item.totalValue
                  ? addCommaToPrice(item.totalValue)
                  : "$ 0",
              },
              GadgetIcons44605254: {
                className: "iconHoverToChange",
                onClick: () =>
                  Swal.fire({
                    title: `Do you want to edit your existing project for ${item.name}?`,
                    // text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Yes, edit ${item.name}`,
                    reverseButtons: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      saveProjectId(item.id);
                    }
                  }),
              },
              GadgetIcons44605255: {
                className: "iconHoverToChange",
                onClick: () =>
                  Swal.fire({
                    title: `Do you want to delete your existing project for ${item.name}?`,
                    html: `<div style='color:red;'><b> WARNING: Deleting a Project will delete any associated Quotes from your account.</b> <br/>Are you sure you want to continue?</div>`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#d33",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: `Yes, delete ${item.name}`,
                    reverseButtons: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      deleteProject(item);
                    }
                  }),
              },
            },
          })}
        />
      </View>
      <UserContentModal
        open={userContentModalOpen}
        setOpen={setUserContentModalOpen}
      />
    </>
  );
}
