// React
import { useContext, useEffect, useState } from "react";

// Amplify Studio Figma Components
import { DesignPackageCard} from "../ui-components";
import {
  View,
  ScrollView,
  useTheme,
  Text,
  Alert,
  Flex,
  Heading,
  Card,
} from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import {
  Project,
  ContractPricingConfiguration,
  DisciplinePricingConfiguration,
  ContractPackageName,
  TradeSupervision,
  CategoryMultiplier,
  ContractPricingConfigurationSnapshot,
} from "../models";

// Gadget Engineering Libraries
import InfoTooltip from "./InfoTooltip";
import { InstantQuoteContext } from "../Context/ProposalContext";
import { DesignPackagesComparison } from ".";
import Swal from "sweetalert2";
import { FaHandPointRight } from "react-icons/fa";

export default function DesignPackageComparisonsCollection(props) {
  // Props
  const { className, ...rest } = props;

  // Context
  const {
    changeInPackageCalculation,
    setChangeInPackageCalculation,
    disciplinePricingConfigurations,
    contractPricingConfigurations,
    projectId,
    packagePriceTotal,
    projectRecord,
    setProjectRecord,
    packageName,
    setPackageName,
    projectHasMinimumRequiredInputs,
    addCommaToPrice,
    userInputProgress,
  } = useContext(InstantQuoteContext);

  // State
  const [packageSelectionAlertVisibility, setPackageSelectionAlertVisibility] =
    useState("flex");
  const [packagePrices, setPackages] = useState({
    gist: 0,
    minimalist: 0,
    collaborator: 0,
    perfectionist: 0,
    twoWeek: 0,
  });

  // Constants
  const { tokens } = useTheme();

  const imageStyle = {
    height: "20px",
    width: "20px",
    borderRadius: "12px",
    background: "#fff",
  };

  const checkBoxIcon = (
    <img
      style={imageStyle}
      src="https://cdn-icons-png.flaticon.com/512/2767/2767140.png"
      alt=""
    />
  );
  const designPackageOffers = [
    {
      name: "Minimalist",
      description:
        "Based on your project details, this is the minimum budget PermitZIP will require to be approved to begin work. This estimate should be enough to cover basic, no-frills engineering, with minimal design iterations or changes.",
      basePrice: packagePrices.minimalist ? packagePrices.minimalist : 0,
      imagePath: () => {
        return require("../Assets/package_minimalist.jpg");
      },
      imageAlt: "Minimalist contract option.",
      features: {
        sd: ["1 SD", "1 Schematic Design(SD)"],
        dd: ["1 DD", "1 Design Development (DD)"],
        cd: ["1 CD", "1 Construction Documents Set (CD)"],
        planReview: [checkBoxIcon, "Plan Review Comments"],
        submittalReview: [checkBoxIcon, "Submittal Review"],
      },
    },
    {
      name: "Collaborator",
      description:
        "Based on your project details, this estimate should cover some more intense work than the Minimalist estimate. If you are an experienced professional but need more input during design, we recommend selecting this option.",
      basePrice: packagePrices.collaborator ? packagePrices.collaborator : 0,
      imagePath: () => {
        return require("../Assets/package_collaboratorV1.jpg");
      },
      imageAlt: "Collaborator contract option.",
      features: {
        sd: ["4 SD", "1 Schematic Design(SD)\n3 Schematic Iterations"],
        dd: ["1 DD", "1 Design Development (DD)"],
        cd: ["1 CD", "1 Construction Documents Set (CD)"],
        planReview: [checkBoxIcon, "Plan Review Comments"],
        submittalReview: [checkBoxIcon, "Submittal Review"],
      },
    },
    {
      name: "Perfectionist",
      description:
        "For those that are keen on the details and like to explore all possible options, this estimate will cover more extensive research and design development. Recommended for those with lower levels of construction experience or for skilled professionals that desire high levels of support during design as well as during construction.",
      basePrice: packagePrices.perfectionist ? packagePrices.perfectionist : 0,
      imagePath: () => {
        return require("../Assets/package_perfectionist.jpg");
      },
      imageAlt: "Perfectionist contract option.",
      features: {
        sd: ["5 SD", "1 Schematic Design(SD)\n4 Schematic Iterations"],
        dd: ["3 DD", "1 Design Development(DD)\n3 DD Iterations"],
        cd: ["1 CD", "1 Construction Documents Set (CD)"],
        planReview: [checkBoxIcon, "Plan Review Comments"],
        submittalReview: [checkBoxIcon, "Submittal Review"],
      },
    },
    // {
    //   name: "PZ Mini",
    //   description: "Get your drawings in one week!",
    //   basePrice: 30000,
    //   imagePath: () => {
    //     return require("../Assets/b5.png");
    //   },
    //   imageAlt: "5 Business Days",
    //   features: {},
    // },
    {
      name: "PZ Two-Week",
      description: "Get your drawings in two weeks!",
      basePrice: packagePrices.twoWeek,
      imagePath: () => {
        return require("../Assets/b10.png");
      },
      imageAlt: "10 Business Days",
      features: {},
    },
  ];

  // Function Expressions

  // Function Declarations
  async function updateProject() {
    let disciplinePricingConfiguration = [];

    for (const disciplineConfig of disciplinePricingConfigurations) {
      let baseSquareFootageMultiplier = [];
      for (const disciplinePerCategory of disciplineConfig[
        "baseSquareFootageMultiplier"
      ]) {
        let categoryMultiplier = new CategoryMultiplier({
          category: disciplinePerCategory["category"].toUpperCase(),
          multiplierConstant:
            typeof disciplinePerCategory["multiplier"] === "number"
              ? disciplinePerCategory["multiplier"]
              : disciplinePerCategory["multiplier"][1],
          exponentialCoefficient:
            typeof disciplinePerCategory["multiplier"] === "number"
              ? 0
              : disciplinePerCategory["multiplier"][0],
        });
        baseSquareFootageMultiplier.push(categoryMultiplier);
      }

      let specialtySetupFees = [];
      for (const disciplinePerCategory of disciplineConfig[
        "specialtySetupFees"
      ]) {
        let categoryMultiplier = new CategoryMultiplier({
          category: disciplinePerCategory["category"].toUpperCase(),
          multiplierConstant:
            typeof disciplinePerCategory["multiplier"] === "number"
              ? disciplinePerCategory["multiplier"]
              : disciplinePerCategory["multiplier"][1],
          exponentialCoefficient:
            typeof disciplinePerCategory["multiplier"] === "number"
              ? 0
              : disciplinePerCategory["multiplier"][0],
        });
        specialtySetupFees.push(categoryMultiplier);
      }

      let obj = new DisciplinePricingConfiguration({
        discipline: TradeSupervision[disciplineConfig["discipline"]],
        setUpFees: disciplineConfig["setupFees"],
        pricePerSquareFootBaseFee: disciplineConfig["pricePerSquareFootBase"],
        baseSquareFootageMultiplier: baseSquareFootageMultiplier,
        specialtySetupFees: specialtySetupFees,
      });

      disciplinePricingConfiguration.push(obj);
    }

    setProjectRecord(
      Project.copyOf(projectRecord, (projectCopy) => {
        projectCopy.projectInputs.preferredContractPackage =
          ContractPackageName[packageName.toUpperCase()];
        projectCopy.totalValue = packagePriceTotal()[packageName.toLowerCase()];
        projectCopy.contractPricingConfigurationSnapshot =
          new ContractPricingConfigurationSnapshot({
            contractPricingConfiguration: new ContractPricingConfiguration({
              ...contractPricingConfigurations,
            }),
            contractInputs: {
              ...projectCopy.projectInputs,
              preferredContractPackage:
                ContractPackageName[packageName.toUpperCase()],
            },
            disciplinePricingConfiguration: [...disciplinePricingConfiguration],
          });
      })
    );
  }

  // Arrow Functions

  // Effects

  useEffect(
    () => {
      const projectSubscription = DataStore.observeQuery(Project, (project) =>
        project.id.eq(projectId)
      ).subscribe(({ items }) => {
        setProjectRecord(items[0]);
      });

      return () => {
        projectSubscription.unsubscribe(); // this invokes on teardown, before next render
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (projectId) {
        setPackages(packagePriceTotal());
        updateProject();
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeInPackageCalculation]
  );


  // Memos

  const Css = `
  .cursorPointer{
    background-color:#fff;
    cursor:pointer;
  }
  `;
  return (
    <View {...rest}>
      <style>{Css}</style>
      <Heading
        level={3}
        margin="16px"
        display={{ base: "block", large: "none" }}
      >
        Packages
      </Heading>
      <Alert
        variation="info"
        isDismissible={true}
        display={packageSelectionAlertVisibility}
      >
        <Flex alignItems="center" justifyContent="flex-start">
          Select any package level below for your project
          <FaHandPointRight style={{ rotate: "90deg", fontSize: "25px" }} />
          {/* <GadgetIcons type="arrow-right" transform={'rotate(90deg)'}/> */}
        </Flex>
      </Alert>
      <ScrollView
        width="100%"
        maxWidth={"1550px"}
        id="DesignPackagesScrollView"
        display={"flex"}
        direction={{ base: "column", small: "row", large: "row" }}
        padding={"15px"}
        margin={'10px'}
        borderRadius="25px"
        backgroundColor={tokens.colors.neutral[40]}
      >
        {designPackageOffers.map((packageItem, packageIndex) => (
          <DesignPackageCard
            // Css Props
            key={packageIndex}
            height={ packageItem.name === "PZ Two-Week" && 'fit-content'}
            width={"100%"}
            minWidth="300px"
            maxWidth={"360px"}
            className="cursorPointer"
            marginRight={"20px"}
            variation={packageName === packageItem.name ? "focus" : undefined}
            // Overrides
            overrides={{
              DesignPackageCard: {
                opacity:
                  packageItem.name === "PZ Mini" ||
                    packageItem.name === "PZ Two-Week"
                    ? "0.6"
                    : "1",
                onClick: () => {
                  if (
                    !(
                      userInputProgress.isEditProject ||
                      projectHasMinimumRequiredInputs
                    )
                  )
                    return;
                  if (packageIndex <= 2) {
                    setPackageName(packageItem.name);
                    setChangeInPackageCalculation(!changeInPackageCalculation);
                    setPackageSelectionAlertVisibility("none");
                  } else {
                    Swal.fire({
                      icon: "info",
                      title: "Coming Soon !",
                      text: "Stay tuned for these awesome packages!",
                    });
                  }
                },
              },
              Icon: {
                display: "none",
              },

              MarketingGraphic: {
                onDragStart: (e) => {
                  e.preventDefault();
                  return false;
                },
                src: packageItem.imagePath(),
                opacity:
                  packageItem.name === "PZ Mini" ||
                    packageItem.name === "PZ Two-Week"
                    ? "0.8"
                    : "1",
              },
              Divider: {
                display: "none",
              },
              GadgetIcons: {
                display: "none",
              },
            }}
            // Component's Default Props
            contractDetailsDisplay="none"
            packagePrice={addCommaToPrice(packageItem.basePrice)}
            packageDescription={packageItem.description}
            packageName={packageItem.name}
            iconSlot={
              projectHasMinimumRequiredInputs && (
                <InfoTooltip
                  visibility="true"
                  message={packageItem.description}
                  color={"black"}
                />
              )
            }
      
          />
        ))}
      </ScrollView>

      <Card margin={"25px 10px"} borderRadius={"15px"} maxWidth={"1550px"}>
        <View margin={"10px"}>
          <Heading level="2">Engineering Services Overview</Heading>
          <Text margin={'8px 0px'} letterSpacing={'0.8px'}>
            Below is a summary of typical ways PermitZIP approaches engineering
            consulting. Not all of the items listed will be able to be provided
            within the Estimate’s budget. The Consultant will attempt to
            communicate the available budget for engineering tasks from the
            Estimate and guide the Client through which of the methods below
            best fit the project needs.
          </Text>
        </View>
        <View margin={"10px"}>
          <Heading level="3">Virtual Survey</Heading>
          <Text margin={'8px 0px'} letterSpacing={'0.8px'}>
            The Client or Client's Representative hosts a virtual meeting while
            on site and is guided  through the documentation process by the
            Consultant. Site visits are not an as-built documentation process.
            The Client is responsible for providing as-built information to the
            Consultant. The Consultant may ask the Client to revisit the site to
            capture additional information. Virtual Site Visits have a smaller
            impact on the Estimate budget than in-person site visits.
          </Text>
        </View>
        <View margin={"10px"}>
          <Heading level="3">In-Person Survey</Heading>
          <Text margin={'8px 0px'} letterSpacing={'0.8px'}>
            Principal-level staff conduct in-person site visits at their
            billable rate. In-person site visits will be billed per-person for
            travel expenses, drive time, and on-site time.
            <br />
            The Consultant visits the site to observe readily visible
            conditions. Site visits are not an as-built documentation process.
            The Client is responsible for providing as-built information to the
            Consultant. The Client may be asked to revisit the site to capture
            additional information.
          </Text>
        </View>
        <View margin={"10px"}>
          <Heading level="3">Schematic Design Phase</Heading>
          <Text margin={'8px 0px'} letterSpacing={'0.8px'}>
            During this phase, Principal level consultants may approach the
            design through various methods. The consulting might include
            conference calls, phone calls, sketches, emails, or similar
            correspondence.
            <br />
            The schematic phase is the Consultant’s time to focus on preliminary
            code studies, load estimates, schematic diagrams, design basis
            equipment, design options, and other similar engineering tasks.
          </Text>
        </View>
        <View margin={"10px"}>
          <Heading level="3">Design Development Phase</Heading>
          <Text margin={'8px 0px'} letterSpacing={'0.8px'}>
            In the Design Development phase, schematic design ideas begin to be
            formalized and the documents prepared for use in permitting and
            construction.
            <br />
            Design changes at this phase are more likely to cause schedule
            delays and Estimate increases.
          </Text>
        </View>
        <View margin={"10px"}>
          <Heading level="3">Construction Documents</Heading>
          <Text margin={'8px 0px'} letterSpacing={'0.8px'}>
            Depending on the Overview of the Scope above, the Consultant may
            deliver the final digital construction documents, sealed by a
            licensed professional engineer during this phase. The Consultant
            does not submit documents to jurisdiction.
            <br />
            The deliverable date for the final construction drawings may be
            amended  according to the Consultant's discretion.
          </Text>
        </View>
        <View margin={"10px"}>
          <Heading level="3">Construction Administration</Heading>
          <Text margin={'8px 0px'} letterSpacing={'0.8px'}>
            The construction administration phase spans bidding, permitting, and
            construction and may require engineering consulting services for
            various reasons. The types of engineering consulting needed during
            this phase may include:
            <br />
            <ul>
              <li>Plan Review Comments</li>
              <li>Submittal Reviews & RFI Answers</li>
              <li>Drawing Revisions & Engineer Letters</li>
            </ul>
            The Consultant reserves the right to determine if any request by the
            Client, the authority having jurisdiction, or any other party
            affiliated with the construction project related to the Contract is
            within the scope of the Contract or the ethical and legal bounds of
            professional supervision on behalf of the Consultant and the
            engineers employed by the Consultant.
          </Text>
        </View>
      </Card>
      <View display="none" alignItems="center" justifyContent="center">
        <DesignPackagesComparison />
      </View>
    </View>
  );
}
