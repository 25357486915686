/* eslint-disable */
import * as React from 'react';
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useStateMutationAction,
} from '@aws-amplify/ui-react/internal';
import {
  Button,
  Divider,
  Flex,
  Text,
  useBreakpointValue,
} from '@aws-amplify/ui-react';
import GadgetIcons from '../ui-components/GadgetIcons';
export default function GadgetCompanyCard(props) {
  const {
    organization,
    iconType = 'bookmark_border',
    focusedIconType = 'bookmark_border-filled',
    buttonDisplay,
    iconDisplay = 'block',
    companyDetailsDisplayType = 'none',
    focusedOverride = {
      parent_props: {
        border: '5px SOLID white',
        boxShadow: '10px 10px 4px rgba(0, 0, 0, 0.75)',
      },
      iconType: focusedIconType,
    },
    overrides: overridesProp,
    ...restProp
  } = props;
  const variants = [
    {
      overrides: {
        OrgName: {},
        ActionIcon: {},
        HeaderFrame: {},
        Divider: {},
        DividerFrame: {},
        OrgTagLine: {},
        OrgAddress: {},
        OrgBillingEmail: {},
        OrgDetailsFrame: {},
        Button: {},
        BodyFrame: {},
        RootView: {},
        CompanyCard: {},
      },
      variantValues: { variant: 'default', breakpoint: 'small' },
    },
    {
      overrides: {
        OrgName: {},
        ActionIcon: {},
        HeaderFrame: {},
        Divider: {},
        DividerFrame: {},
        OrgTagLine: {},
        OrgAddress: {},
        OrgBillingEmail: {},
        OrgDetailsFrame: {},
        Button: {},
        BodyFrame: {},
        RootView: {},
        CompanyCard: { border: '1px SOLID rgba(174,179,183,1)' },
      },
      variantValues: { variant: 'outlined', breakpoint: 'small' },
    },
    {
      overrides: {
        OrgName: {},
        ActionIcon: {},
        HeaderFrame: {},
        Divider: {},
        DividerFrame: {},
        OrgTagLine: {},
        OrgAddress: {},
        OrgBillingEmail: {},
        OrgDetailsFrame: {},
        Button: {},
        BodyFrame: {},
        RootView: {},
        CompanyCard: { boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' },
      },
      variantValues: { variant: 'focused', breakpoint: 'small' },
    },
    {
      overrides: {
        OrgName: {
          fontSize: '24px',
          fontWeight: '600',
          lineHeight: '30px',
          alignSelf: 'stretch',
        },
        ActionIcon: {},
        HeaderFrame: { height: '70px' },
        Divider: {},
        DividerFrame: {},
        OrgTagLine: {},
        OrgAddress: {
          fontSize: '14px',
          lineHeight: '21px',
          shrink: '1',
          grow: '1',
          basis: '0',
        },
        OrgBillingEmail: { fontSize: '16px', lineHeight: '24px' },
        OrgDetailsFrame: { height: '100px' },
        Button: { size: 'default' },
        BodyFrame: {},
        RootView: {},
        CompanyCard: { width: '300px' },
      },
      variantValues: { variant: 'default', breakpoint: 'medium' },
    },
    {
      overrides: {
        OrgName: {
          fontSize: '24px',
          fontWeight: '600',
          color: 'rgba(13,26,38,1)',
          lineHeight: '30px',
          height: '60px',
        },
        ActionIcon: { type: 'more_vert' },
        HeaderFrame: { gap: '20px', height: '70px' },
        Divider: { height: '5px' },
        DividerFrame: { height: 'unset' },
        OrgTagLine: {},
        OrgAddress: {
          fontSize: '14px',
          lineHeight: '21px',
          shrink: '1',
          grow: '1',
          basis: '0',
        },
        OrgBillingEmail: { fontSize: '16px', lineHeight: '24px' },
        OrgDetailsFrame: { height: '100px' },
        Button: { size: 'default' },
        BodyFrame: {},
        RootView: {},
        CompanyCard: {
          width: '300px',
          border: '1px SOLID rgba(174,179,183,1)',
        },
      },
      variantValues: { variant: 'outlined', breakpoint: 'medium' },
    },
    {
      overrides: {
        OrgName: {
          fontSize: '24px',
          fontWeight: '600',
          lineHeight: '30px',
          height: '70px',
        },
        ActionIcon: { display: 'block', type: 'more_vert' },
        HeaderFrame: { gap: '0', height: '70px' },
        Divider: {},
        DividerFrame: {},
        OrgTagLine: {},
        OrgAddress: {
          fontSize: '14px',
          lineHeight: '21px',
          shrink: '1',
          grow: '1',
          basis: '0',
        },
        OrgBillingEmail: { fontSize: '16px', lineHeight: '24px' },
        OrgDetailsFrame: { height: '100px' },
        Button: { size: 'default' },
        BodyFrame: {},
        RootView: {},
        CompanyCard: {
          width: '300px',
          border: '2px SOLID rgba(0,0,0,0)',
          padding: '3px 3px 3px 3px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
      },
      variantValues: { variant: 'focused', breakpoint: 'medium' },
    },
    {
      overrides: {
        OrgName: {
          fontSize: '32px',
          fontWeight: '500',
          lineHeight: '40px',
          alignSelf: 'stretch',
        },
        ActionIcon: { display: 'block' },
        HeaderFrame: { height: '70px' },
        Divider: {},
        DividerFrame: {},
        OrgTagLine: { fontSize: '24px', lineHeight: '36px' },
        OrgAddress: {
          fontSize: '16px',
          lineHeight: '24px',
          shrink: '1',
          grow: '1',
          basis: '0',
        },
        OrgBillingEmail: { fontSize: '16px', lineHeight: '24px' },
        OrgDetailsFrame: { height: '100px' },
        Button: { size: 'default' },
        BodyFrame: {},
        RootView: {},
        CompanyCard: { width: '500px' },
      },
      variantValues: { variant: 'default', breakpoint: 'large' },
    },
    {
      overrides: {
        OrgName: {
          fontSize: '32px',
          fontWeight: '500',
          color: 'rgba(13,26,38,1)',
          lineHeight: '40px',
          height: '60px',
        },
        ActionIcon: { display: 'block', type: 'more_vert' },
        HeaderFrame: { gap: '20px', height: '70px' },
        Divider: { height: '5px' },
        DividerFrame: { height: 'unset' },
        OrgTagLine: { fontSize: '24px', lineHeight: '36px' },
        OrgAddress: {
          fontSize: '16px',
          lineHeight: '24px',
          shrink: '1',
          grow: '1',
          basis: '0',
        },
        OrgBillingEmail: { fontSize: '16px', lineHeight: '24px' },
        OrgDetailsFrame: { height: '100px' },
        Button: { size: 'default' },
        BodyFrame: {},
        RootView: {},
        CompanyCard: {
          width: '500px',
          border: '1px SOLID rgba(174,179,183,1)',
        },
      },
      variantValues: { variant: 'outlined', breakpoint: 'large' },
    },
    {
      overrides: {
        OrgName: {
          fontSize: '32px',
          fontWeight: '500',
          lineHeight: '40px',
          height: '70px',
        },
        ActionIcon: { display: 'block', type: 'more_vert' },
        HeaderFrame: { gap: '0', height: '70px' },
        Divider: {},
        DividerFrame: {},
        OrgTagLine: { fontSize: '20px', lineHeight: '30px' },
        OrgAddress: {
          fontSize: '16px',
          lineHeight: '24px',
          shrink: '1',
          grow: '1',
          basis: '0',
        },
        OrgBillingEmail: { fontSize: '16px', lineHeight: '24px' },
        OrgDetailsFrame: { height: '100px' },
        Button: { size: 'default' },
        BodyFrame: { gap: '16px' },
        RootView: {},
        CompanyCard: {
          width: '500px',
          border: '2px SOLID rgba(0,0,0,0)',
          padding: '3px 3px 3px 3px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
      },
      variantValues: { variant: 'focused', breakpoint: 'large' },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: 'small',
    small: 'small',
    small: 'small',
    small: 'small',
    medium: 'medium',
    medium: 'medium',
    medium: 'medium',
    large: 'large',
    large: 'large',
    large: 'large',
  });
  const rest = { style: { transition: 'all 0.25s' }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  const baseConfiguration = {
    parent_props: {
      border: '0px',
      boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
    },
    iconType: iconType,
  };
  const companyCardOnMouseEnter = () => {
    setmouseOverPropOverrides(focusedOverride);
  };
  const [mouseOverPropOverrides, setmouseOverPropOverrides] =
    useStateMutationAction(baseConfiguration);
  const companyCardOnMouseLeave = () => {
    setmouseOverPropOverrides(baseConfiguration);
  };

  return (
    <Flex
      gap='0'
      direction='column'
      width='250px'
      height='unset'
      justifyContent='flex-start'
      alignItems='center'
      borderRadius='10px'
      overflow='hidden'
      position='relative'
      padding='4px 4px 4px 4px'
      backgroundColor='rgba(255,255,255,1)'
      display='flex'
      {...mouseOverPropOverrides.parent_props}
      onMouseEnter={() => {
        companyCardOnMouseEnter();
      }}
      onMouseLeave={() => {
        companyCardOnMouseLeave();
      }}
      {...getOverrideProps(overrides, 'CompanyCard')}
      {...rest}>
      <Flex
        gap='0'
        direction='column'
        width='unset'
        height='unset'
        justifyContent='flex-start'
        alignItems='center'
        overflow='hidden'
        shrink='0'
        alignSelf='stretch'
        position='relative'
        padding='0px 0px 0px 0px'
        backgroundColor='rgba(255,255,255,1)'
        display='flex'
        {...getOverrideProps(overrides, 'RootView')}>
        <Flex
          gap='10px'
          direction='row'
          width='unset'
          height='unset'
          justifyContent='center'
          alignItems='flex-start'
          shrink='0'
          alignSelf='stretch'
          position='relative'
          padding='5px 8px 4px 8px'
          display='flex'
          {...getOverrideProps(overrides, 'HeaderFrame')}>
          <Text
            fontFamily='Inter'
            fontSize='20px'
            fontWeight='700'
            color='rgba(48,64,80,1)'
            lineHeight='25px'
            textAlign='left'
            display='block'
            direction='column'
            justifyContent='unset'
            width='unset'
            height='unset'
            gap='unset'
            alignItems='unset'
            grow='1'
            shrink='1'
            basis='0'
            position='relative'
            padding='0px 0px 0px 0px'
            whiteSpace='pre-wrap'
            children={organization?.name}
            {...getOverrideProps(overrides, 'OrgName')}></Text>
          <GadgetIcons
            width='24px'
            height='24px'
            display={iconDisplay}
            gap='unset'
            alignItems='unset'
            justifyContent='unset'
            overflow='hidden'
            shrink='0'
            position='relative'
            padding='0px 0px 0px 0px'
            backgroundColor='rgba(255,255,255,1)'
            type={mouseOverPropOverrides.iconType}
            {...getOverrideProps(overrides, 'ActionIcon')}></GadgetIcons>
        </Flex>
        <Flex
          gap='10px'
          direction='column'
          width='unset'
          height='5px'
          justifyContent='center'
          alignItems='center'
          overflow='hidden'
          shrink='0'
          alignSelf='stretch'
          position='relative'
          padding='0px 0px 0px 0px'
          display='flex'
          {...getOverrideProps(overrides, 'DividerFrame')}>
          <Divider
            width='unset'
            shrink='0'
            alignSelf='stretch'
            size='small'
            orientation='horizontal'
            {...getOverrideProps(overrides, 'Divider')}></Divider>
        </Flex>
        <Flex
          gap='5px'
          direction='column'
          width='unset'
          height='unset'
          justifyContent='flex-start'
          alignItems='center'
          shrink='0'
          alignSelf='stretch'
          position='relative'
          padding='0px 8px 8px 8px'
          backgroundColor='rgba(255,255,255,1)'
          display='flex'
          {...getOverrideProps(overrides, 'BodyFrame')}>
          <Flex
            gap='0'
            direction='column'
            width='unset'
            height='unset'
            justifyContent='flex-start'
            alignItems='flex-start'
            shrink='0'
            alignSelf='stretch'
            position='relative'
            padding='0px 0px 0px 0px'
            display='flex'
            {...getOverrideProps(overrides, 'OrgDetailsFrame')}>
            <Text
              fontFamily='Inter'
              fontSize='12px'
              fontWeight='400'
              color='rgba(13,26,38,1)'
              lineHeight='18px'
              textAlign='left'
              display='block'
              direction='column'
              justifyContent='unset'
              width='unset'
              height='unset'
              gap='unset'
              alignItems='unset'
              shrink='0'
              alignSelf='stretch'
              position='relative'
              padding='0px 0px 0px 0px'
              whiteSpace='pre-wrap'
              children={organization?.tagLine}
              {...getOverrideProps(overrides, 'OrgTagLine')}></Text>
            <Text
              fontFamily='Inter'
              fontSize='8px'
              fontWeight='400'
              color='rgba(13,26,38,1)'
              lineHeight='12px'
              textAlign='left'
              display='block'
              direction='column'
              justifyContent='unset'
              width='unset'
              height='unset'
              gap='unset'
              alignItems='unset'
              shrink='0'
              alignSelf='stretch'
              position='relative'
              padding='0px 0px 0px 0px'
              whiteSpace='pre-wrap'
              children={organization?.address}
              {...getOverrideProps(overrides, 'OrgAddress')}></Text>
            <Text
              fontFamily='Inter'
              fontSize='8px'
              fontWeight='400'
              color='rgba(13,26,38,1)'
              lineHeight='12px'
              textAlign='left'
              display='block'
              direction='column'
              justifyContent='unset'
              width='unset'
              height='unset'
              gap='unset'
              alignItems='unset'
              shrink='0'
              alignSelf='stretch'
              position='relative'
              padding='0px 0px 0px 0px'
              whiteSpace='pre-wrap'
              children={organization?.billingEmail}
              {...getOverrideProps(overrides, 'OrgBillingEmail')}></Text>
          </Flex>
          <Button
            shrink='0'
            alignSelf='stretch'
            size='small'
            isDisabled={false}
            variation='primary'
            display={buttonDisplay}
            children='Attach to Instant Quote'
            {...getOverrideProps(overrides, 'Button')}></Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
