/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  useAuthSignOutAction,
} from "@aws-amplify/ui-react/internal";
import { Button, Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function HeaderFramelarge(props) {
  const { permitZipLogoSrc, userProfileSrc, profileCard, overrides, ...rest } =
    props;
  const buttonFourFourSixSevenFiveOneFourFiveOnClick = useAuthSignOutAction({
    global: false,
  });
  return (
    <Flex
      gap="-224px"
      direction="row"
      width="1389px"
      height="unset"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      padding="16px 16px 16px 16px"
      backgroundColor="rgba(149,4,149,1)"
      {...getOverrideProps(overrides, "HeaderFramelarge")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 20px 0px 20px"
        {...getOverrideProps(overrides, "LogoAndTitleFrame")}
      >
        <Image
          width="101px"
          height="97px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={permitZipLogoSrc}
          {...getOverrideProps(overrides, "PermitZIPLogo")}
        ></Image>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="16px 16px 16px 16px"
          {...getOverrideProps(overrides, "HeadingText")}
        >
          <Text
            fontFamily="Inter"
            fontSize="48px"
            fontWeight="300"
            color="rgba(255,255,255,1)"
            lineHeight="60px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="PermitZIP Prime"
            {...getOverrideProps(overrides, "WebsiteTitle")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="700"
            color="rgba(255,255,255,1)"
            lineHeight="25px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="small projects deserve big attention"
            {...getOverrideProps(overrides, "WebsiteSlogan")}
          ></Text>
        </Flex>
      </Flex>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="3px 22px 13px 22px"
        {...getOverrideProps(overrides, "SubHeaderUserContextFrame")}
      >
        <Button
          width="unset"
          height="unset"
          shrink="0"
          size="large"
          isDisabled={false}
          variation="primary"
          children="View Previous Estimates"
          {...getOverrideProps(overrides, "Button39447426")}
        ></Button>
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 40px 10px 40px"
          {...getOverrideProps(overrides, "profilePictureframe")}
        >
          <View
            width="45px"
            height="45px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            borderRadius="160px"
            padding="0px 0px 0px 0px"
            children={profileCard}
            {...getOverrideProps(overrides, "profileCard")}
          ></View>
          <View
            width="45px"
            height="45px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            borderRadius="160px"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "ProfilePicture")}
          >
            <Image
              width="45px"
              height="45px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="0px"
              borderRadius="160px"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src={userProfileSrc}
              {...getOverrideProps(overrides, "image")}
            ></Image>
          </View>
        </Flex>
        <Button
          width="unset"
          height="unset"
          shrink="0"
          size="large"
          isDisabled={false}
          variation="primary"
          children="Sign Out"
          onClick={() => {
            buttonFourFourSixSevenFiveOneFourFiveOnClick();
          }}
          {...getOverrideProps(overrides, "Button44675145")}
        ></Button>
      </Flex>
    </Flex>
  );
}
