// React
import { useEffect, useContext } from "react";

// Amplify
import { DataStore } from "aws-amplify";
import { Project, TradeSupervision } from "../models";
import { useTheme } from "@aws-amplify/ui-react";
import { TradeSupervisionSelectionCard } from "../ui-components";

// Gadget Engineering Imports
import { InstantQuoteContext } from "../Context/ProposalContext";
import InfoTooltip from "./InfoTooltip";

export default function EngineeringSupervisionInputs(props) {
  const { ...rest } = props;
  // Context
  const {
    discipline,
    setDiscipline,
    setChangeInPackageCalculation,
    changeInPackageCalculation,
    projectRecord,
    setProjectRecord,
    projectId,
    projectHasMinimumRequiredInputs,
    userInputProgress
  } = useContext(InstantQuoteContext);

  // Constants
  const { tokens } = useTheme();

  // React Effects (execute after render completion) ---------------------------
  useEffect(
    () => {
      /**
       * This keeps the contract updated in memory for a save.
       */
      const projectSubscription = DataStore.observeQuery(
        Project,
        (project) => project.id.eq(projectId)
      ).subscribe(({ items }) => {
        setProjectRecord(items[0]);
      });

      return () => {
        projectSubscription.unsubscribe(); // clean up when done
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function toggleSupervisionSelection(event, disciplineEnum) {
    let disciplineKey = "";
    if (disciplineEnum === TradeSupervision.MECH) {
      disciplineKey = "mechanical";
    } else if (disciplineEnum === TradeSupervision.PLUMB) {
      disciplineKey = "plumbing";
    } else if (disciplineEnum === TradeSupervision.ELEC) {
      disciplineKey = "electrical";
    } else {
      throw new TypeError("The value must be a valid TradeSupervision value.");
    }

    if (userInputProgress.isEditProject || projectHasMinimumRequiredInputs) {
      // connect to datastore
      setProjectRecord(
        Project.copyOf(
          projectRecord,
          function setDisciplineWithDataStore(projectCopy) {
            let newTradeSupervisions = Array.from(
              projectCopy.tradeSupervisions
            );
            if (event.target.checked) {
              discipline[disciplineKey] = 1;
              newTradeSupervisions.push(disciplineEnum);
            } else {
              discipline[disciplineKey] = 0;
              newTradeSupervisions = projectCopy.tradeSupervisions.filter(
                function (item) {
                  return item !== disciplineEnum;
                }
              );
            }

            projectCopy.tradeSupervisions = newTradeSupervisions;
            projectCopy.projectInputs.tradeSupervisions =
              newTradeSupervisions;

            setDiscipline(discipline);

          }
        )
      );
    } else {
      // do not connect to datastore
      setDiscipline(function setDisciplineLocalOnly() {
        if (event.target.checked) {
          discipline[disciplineKey] = 1;
        } else {
          discipline[disciplineKey] = 0;
        }
        return discipline;
      });
    }
    setChangeInPackageCalculation(!changeInPackageCalculation);
  }

  const tooltipMessage ="Select all of the trades that you want in PermitZIP’s scope of work";
  return (
    <TradeSupervisionSelectionCard
      {...rest}
      display="flex"
      gap="10px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      shrink="0"
      alignSelf="stretch"
      position="relative"
      border="1px SOLID rgba(174,179,183,1)"
      borderRadius="10px"
      padding="19px 19px 19px 19px"
      backgroundColor={tokens.colors.brand.secondary[90]}
      tooltip={<InfoTooltip visibility='true' message={tooltipMessage} color={"#fff"} />}
      overrides={{
        HeaderText:{
          children:"Scope of Work"
        },
        isMechanical: {
          label: "Mechanical",
          defaultChecked: false,
          value: "yes",
          checked: discipline.mechanical,
          onChange: (e) => toggleSupervisionSelection(e, TradeSupervision.MECH),
        },
        isElectrical: {
          label: "Electrical",
          defaultChecked: false,
          value: "yes",
          checked: discipline.electrical,
          onChange: (e) => toggleSupervisionSelection(e, TradeSupervision.ELEC),
        },
        isPlumbing: {
          label: "Plumbing",
          defaultChecked: false,
          value: "yes",
          checked: discipline.plumbing,
          onChange: (e) =>
            toggleSupervisionSelection(e, TradeSupervision.PLUMB),
        },
        isGas:{
          display:'none'
        }
      }}
      id="TradeSupervisionSelectionCard"
    ></TradeSupervisionSelectionCard>
  );
}
