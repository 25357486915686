import React from "react";

import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";

import QuoteSummaryCard from "./QuoteSummaryCard";

const QuoteViewCard = (props) => {
  const {
    quoteOpen,
    setQuoteOpen,
    quoteDetails,
    quoteDisciplines,
    quoteSqft,
    ...rest
  } = props;

  const css= `
  .MuiModal-backdrop{
    backdrop-filter:blur(2px);
    transition:2s ease;
  }
  `
  return (
    <>
      <style>{css}</style>
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={quoteOpen}
      onClose={() => {
        setQuoteOpen(false);
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      {...rest}
    >
      <>
        <ModalClose
          variant="outlined"
          sx={{
            top: "calc(-1/90 * var(--IconButton-size))",
            right: "calc(-1/90 * var(--IconButton-size))",
            boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
            borderRadius: "50%",
            bgcolor: "background.body",
          }}
        />
        <QuoteSummaryCard
          quoteDetails={quoteDetails}
          quoteDisciplines={quoteDisciplines}
          quoteSqft={quoteSqft}
        />
      </>
    </Modal>
  </>
  );

};

export default QuoteViewCard;
