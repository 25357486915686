/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { CheckboxField, Flex, Text, View } from "@aws-amplify/ui-react";
export default function TradeSupervisionSelectionCard(props) {
  const { tooltip, overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="300px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      border="1px SOLID rgba(174,179,183,1)"
      borderRadius="10px"
      padding="19px 19px 19px 19px"
      backgroundColor="rgba(0,85,102,1)"
      {...getOverrideProps(overrides, "TradeSupervisionSelectionCard")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Header")}
      >
        <Text
          fontFamily="Inter"
          fontSize="24px"
          fontWeight="600"
          color="rgba(255,255,255,1)"
          lineHeight="30px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="What kind of engineered permit drawings do you need?"
          {...getOverrideProps(overrides, "HeaderText")}
        ></Text>
        <View
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          children={tooltip}
          {...getOverrideProps(overrides, "tooltip")}
        ></View>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        border="1px SOLID rgba(174,179,183,1)"
        borderRadius="10.5px"
        padding="19.5px 29.5px 19.5px 29.5px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "TradeSupervision")}
      >
        <CheckboxField
          width="unset"
          height="unset"
          label="Mechanical"
          justifyContent="space-between"
          shrink="0"
          alignSelf="stretch"
          size="large"
          defaultChecked={true}
          isDisabled={false}
          labelPosition="end"
          {...getOverrideProps(overrides, "isMechanical")}
        ></CheckboxField>
        <CheckboxField
          width="unset"
          height="unset"
          label="Electrical"
          justifyContent="space-between"
          shrink="0"
          alignSelf="stretch"
          size="large"
          defaultChecked={true}
          isDisabled={false}
          labelPosition="end"
          {...getOverrideProps(overrides, "isElectrical")}
        ></CheckboxField>
        <CheckboxField
          width="unset"
          height="unset"
          label="Plumbing"
          justifyContent="space-between"
          shrink="0"
          alignSelf="stretch"
          size="large"
          defaultChecked={true}
          isDisabled={false}
          labelPosition="end"
          {...getOverrideProps(overrides, "isPlumbing")}
        ></CheckboxField>
        <CheckboxField
          width="unset"
          height="unset"
          label="Plumbing"
          justifyContent="space-between"
          shrink="0"
          alignSelf="stretch"
          size="large"
          defaultChecked={true}
          isDisabled={false}
          labelPosition="end"
          {...getOverrideProps(overrides, "isGas")}
        ></CheckboxField>
      </Flex>
    </Flex>
  );
}
