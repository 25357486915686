import { useContext, useState, useEffect } from "react";

// Third-party libraries
import Swal from "sweetalert2";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";

import axios from "axios";

// Gadget Engineering libraries
import ProjectSnapshotCardCollection from "../ui-components/ProjectSnapshotCardCollection";
import { Project } from "../models";
import { InstantQuoteContext } from "../Context/ProposalContext";

// Amplify
import {
  Flex,
  View,
  Image,
  Heading,
  Button,
} from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";

const ExistingProjectAddressModal = (props) => {
  const { ...rest } = props;

  const {
    user,
    addCommaToPrice,
    projectAddressList,
    setExistingProjectAddressOpen,
    existingProjectAddressOpen,
    instantQuoteApi,
    address,
  } = useContext(InstantQuoteContext);

  useEffect(() => {
    if (user["signInUserSession"]) {
      const userGroup =
        user["signInUserSession"]["idToken"]["payload"]["cognito:groups"];
      if (userGroup.includes("Admin")) {
        setIsAdmin(true);
      }
    }
  }, [existingProjectAddressOpen]);

  const [isAdmin, setIsAdmin] = useState(false);

  const [indexOfItem, setIndexOfItem] = useState(-1);

  async function deleteProject(item) {
    const currentProject = await DataStore.query(Project, item.id);
    if (currentProject.pipedriveID) {
      const sendEmailFields = {
        pipedriveID: currentProject.pipedriveID,
        creatorEmail: user.attributes.email,
        isDeleteProject: true,
      };
      Swal.fire({
        icon: "info",
        title: "Deleting your project...",
        // text: "Once the email is sent, the confirmation message will be shown",
        showConfirmButton: false,
      });
      try {
        await axios
          .post(
            `${instantQuoteApi}/proposals/pipedrive`,
            sendEmailFields
          )
          .then((res) => {
            if (res.data.errorMessage)
              Swal.fire({
                icon: "error",
                title: "Something went wrong!",
                text: "Check the entered mail ID's",
              });
          });
      } catch (error) {
        console.log(error);
      }
    }
    await DataStore.delete(currentProject).then(() => {
      Swal.fire({
        icon: "success",
        title: "Project Deleted!",
        text: "Project was deleted successfully!",
      }).then(() => window.location.reload());
    });
  }

  function textReveal(name, index, maxLength) {
    if (index === indexOfItem) return [index, name];
    if (name.length > maxLength) {
      const val = name.slice(0, maxLength - 3) + "...";
      return [index, val];
    } else return [index, name];
  }

  function saveProjectId(id) {
    if (localStorage.getItem("projectId")) localStorage.removeItem("projectId");
    if (localStorage.getItem("quoteId")) localStorage.removeItem("quoteId");
    localStorage.setItem("projectId", id);
    window.location.reload();
  }

  function isAuthor(project){
    return isAdmin || (project.editors && project.editors.includes(user.username))
  }

  const Css = `
      .swal2-container.swal2-center.swal2-backdrop-show {
        z-index:9999;
    }
    
     .amplify-pagination__item-button, .amplify-pagination__item-button--link{
      color: black;
    }
    .amplify-pagination__item-button:hover{
      color: black;
    }
    
    .amplify-flex {
      justify-content:center;
    }
    .amplify-flex.amplify-collection-search {
      justify-content:center;
    }
    
    .iconHoverToChange:hover{
      background: lightblue;
      cursor: pointer;
     border-radius: 10px;
      transition:0.2s ease-out;
    }
    `;

  return (
    <>
      <style>{Css}</style>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={existingProjectAddressOpen}
        onClose={() => {
          setExistingProjectAddressOpen(false);
          window.location.reload();
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        {...rest}
      >
        <View
          display="flex"
          backgroundColor="lightblue"
          borderRadius="10px"
          direction="column"
          alignItems="center"
        //   justifyContent='center'
          width="100%"
          maxWidth="1000px"
          height="100%"
          maxHeight="95%"
          margin="10px"
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/90 * var(--IconButton-size))",
              right: "calc(-1/90 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.body",
            }}
          />
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            padding="10px"
            backgroundColor={"white"}
            borderRadius="10px 10px 0px 0px"
            width="100%"
            marginBottom={'20px'}
          >
            <Image
              alt="PermitZip logo"
              src={require("../Assets/permitZIP.png")}
              height="55px"
              margin={"0px 10px"}
            />
            <Heading level="4">
              Existing projects at {address}:
            </Heading>
          </Flex>
<View
    display='flex'
    direction='column'
    padding='20px'
>
          <ProjectSnapshotCardCollection
            items={projectAddressList}
            // isSearchable={false}
            // backgroundColor={"red"}
            height={"100%"}
            itemsPerPage={6}
            overrideItems={({ item, index }) => ({
              overrides: {
                FrameOfReference: {
                height:'auto',
              },
                ProjectName: {
                  children:
                    textReveal(item.name, index, 18)[0] === index &&
                    textReveal(item.name, index, 18)[1],
                  onMouseEnter: () => setIndexOfItem(index),
                  onMouseLeave: () => setIndexOfItem(-1),
                },
                ProjectAddress: {
                  children:
                    textReveal(item.projectInputs.projectLocation?.address, index, 50)[0] ===
                      index &&
                    textReveal(item.projectInputs.projectLocation?.address, index, 50)[1],
                  onMouseEnter: () => setIndexOfItem(index),
                  onMouseLeave: () => setIndexOfItem(-1),
                },
                packageTypeBadge: {
                  children: item.projectInputs.preferredContractPackage,
                  display:
                    !item.projectInputs.preferredContractPackage && "none",
                },
                TotalFee: {
                  children: item.totalValue
                    ? addCommaToPrice(item.totalValue)
                    : "$ 0",
                },
                GadgetIcons44605254: {
                  className: "iconHoverToChange",
                  display: isAuthor(item) ? 'block' : 'none',
                  onClick: () =>
                    Swal.fire({
                      title: `Do you want to edit your existing project for ${item.name}?`,
                      // text: "You won't be able to revert this!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: `Yes, edit ${item.name}`,
                      reverseButtons: true,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        saveProjectId(item.id);
                      }
                    }),
                },
                GadgetIcons44605255: {
                  className: "iconHoverToChange",
                  display: isAuthor(item) ? 'block' : 'none',
                  onClick: () =>
                    Swal.fire({
                      title: `Do you want to delete your existing project for ${item.name}?`,
                      text: "<strong> WARNING: Deleting a Project will delete any associated Quotes from your account. </strong> <br/> Are you sure you want to continue?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#d33",
                      cancelButtonColor: "#3085d6",
                      confirmButtonText: `Yes, delete ${item.name}`,
                      reverseButtons: true,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteProject(item);
                      }
                    }),
                },
              },
            })}
          />
        
</View>
  <Button 
          marginTop={'20px'}
            width={'60%'}
           onClick={() => setExistingProjectAddressOpen(false)}>
            Never mind, continue creating new project
          </Button>
        </View>
      </Modal>
    </>
  );
};

export default ExistingProjectAddressModal;
