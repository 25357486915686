// React
import { useEffect, useContext } from "react";

// Amplify
import { DataStore } from "aws-amplify";
import { useTheme, Text } from "@aws-amplify/ui-react";
import { Project, ConstructionType, SurveyType } from "../models";
import { ConstructionTypeAndSurveySelectionCard } from "../ui-components";

// Gadget Engineering Imports
import { InstantQuoteContext } from "../Context";
import InfoTooltip from "./InfoTooltip";

export default function FieldWorkInputs() {
  // Context
  const {
    constructionType,
    setConstructionType,
    surveyType,
    setSurveyType,
    changeInPackageCalculation,
    setChangeInPackageCalculation,
    projectId,
    projectRecord,
    setProjectRecord,
    projectHasMinimumRequiredInputs,
    userInputProgress,
    adaptiveReuse, 
    setAdaptiveReuse
  } = useContext(InstantQuoteContext);

  // Constants
  const css = `
  label.amplify-flex.amplify-radio.radiobox {
    align-items: center;
    justify-content: space-between;
    }
    `;
  const keyInversionTable = {
    cleanSlate: "recycledSystem",
    recycledSystem: "cleanSlate",
    isGroundUp: "isAdaptiveReuse",
    isAdaptiveReuse: "isGroundUp",
  };
  const { tokens } = useTheme();

  // State

  // Function Expressions
  const toggleAdaptiveReuse = function (event) {
    if(constructionType[event.target.value]){
      constructionType[event.target.value] = 0
      constructionType[keyInversionTable[event.target.value]] = 1
    }
    else{
      constructionType[event.target.value] = 1
      constructionType[keyInversionTable[event.target.value]] = 0
    }

    setConstructionType(constructionType);

    (projectHasMinimumRequiredInputs || userInputProgress.isEditProject) &&
      setChangeInPackageCalculation(!changeInPackageCalculation);
    (projectHasMinimumRequiredInputs || userInputProgress.isEditProject) &&
      setProjectRecord(
        Project.copyOf(projectRecord, (projectCopy) => {
          projectCopy.projectInputs.constructionType =
            constructionType.isAdaptiveReuse
              ? ConstructionType.ADAPTIVE_REUSE_RECYCLED_SYSTEMS
              : ConstructionType.GROUND_UP;
          projectCopy.projectInputs.surveyType =
            constructionType.isAdaptiveReuse ? SurveyType.IN_PERSON : null;
        })
      );
  };

  const toggleVirtualSurvey = function (event) {
    if(adaptiveReuse[event.target.value]){
      adaptiveReuse[event.target.value] = 0
      adaptiveReuse[keyInversionTable[event.target.value]] = 1
    }
    else{
      adaptiveReuse[event.target.value] = 1
      adaptiveReuse[keyInversionTable[event.target.value]] = 0
    }
    setAdaptiveReuse(adaptiveReuse);

    (projectHasMinimumRequiredInputs || userInputProgress.isEditProject) &&
      setChangeInPackageCalculation(!changeInPackageCalculation);
    (projectHasMinimumRequiredInputs || userInputProgress.isEditProject) &&
      setProjectRecord(
        Project.copyOf(projectRecord, (projectCopy) => {
          projectCopy.projectInputs.constructionType =
            constructionType.isAdaptiveReuse
              ? (adaptiveReuse.recycledSystem ? ConstructionType.ADAPTIVE_REUSE_RECYCLED_SYSTEMS : ConstructionType.ADAPTIVE_REUSE_CLEAN_SLATE)
              : ConstructionType.GROUND_UP;
          projectCopy.projectInputs.surveyType = SurveyType.IN_PERSON;
        })
      );
  };

  // Arrow Functions

  // Effects
  useEffect(
    () => {
      const projectSubscription = DataStore.observeQuery(
        Project,
        (project) => project.id.eq(projectId)
      ).subscribe(({ items }) => {
        setProjectRecord(items[0]);
      });

      return () => {
        projectSubscription.unsubscribe(); // clean up when done
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Memos

  // Function Declarations

  const tooltipMessage ={
    constructionType: 'If this is an existing building or structure, select Yes. The level of work required to renovate an existing building compared to designing a new building is higher. Selecting yes to this question will increase the fee to account for the increased complexity.',
    adaptiveReuse:"Engineering MEP systems to integrate with existing installations is typically more complex than a ground-up design, and often comes with increased costs. It is usually less expensive from a design perspective to gut the existing building and design all-new MEP systems."
  }

  return (
    <div>
      <ConstructionTypeAndSurveySelectionCard
        display="flex"
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        borderRadius="10px"
        padding="20px 10px 20px 10px"
        backgroundColor={tokens.colors.brand.secondary[90]}
        tooltip={<InfoTooltip visibility='true' message={tooltipMessage.constructionType} color={'#fff'} />}
        surveyTooltip={<InfoTooltip visibility='true' message={tooltipMessage.adaptiveReuse} color={'#fff'}/>}
        overrides={{
          GadgetIcons39214326: {
            children: "hii",
          },
          GadgetIcons39214324: {
            paths: [
              {
                d: "M9 5L11 5L11 7L9 7L9 5ZM9 9L11 9L11 15L9 15L9 9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z",
                fill: "#fff",
                fillRule: "nonzero",
                style: { transform: "translate(8.33%, 8.33%)" },
              },
            ],
          },
          // isGroundUp
          HeaderText39214091: {
            children: "Is this an Existing Building?",
          },
          SwitchField39214238: {
            defaultChecked: false,
            width: "75px",
            value: constructionType.isAdaptiveReuse
              ? "isGroundUp"
              : "isAdaptiveReuse",
            label: (
              <Text color={tokens.colors.font.inverse}>
                {constructionType.isAdaptiveReuse ? "Yes" : "No"}
              </Text>
            ),
            onChange: toggleAdaptiveReuse,
            isChecked: constructionType.isAdaptiveReuse,
          },
          // isVirtualSurvey
          isVirtualSurvey: {
            display: constructionType.isAdaptiveReuse ? "flex" : "none",
          },
          HeaderText39214285:{
            children: "Reusing/integrating existing MEP systems?"
          },
          SwitchField39214286: {
            defaultChecked: false,
            width: "75px",
            value: adaptiveReuse.recycledSystem ? "recycledSystem" : "cleanSlate",
            label: (
              <Text color={tokens.colors.font.inverse}>
                {adaptiveReuse.recycledSystem ? "Yes" : "No"}
              </Text>
            ),
            onChange: toggleVirtualSurvey,
            isChecked: adaptiveReuse.recycledSystem,
          },
        }}
        id="ConstructionTypeAndSurveySelectionCard"
      ></ConstructionTypeAndSurveySelectionCard>
    </div>
  );
}
