/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://c8tpb4d77c.execute-api.us-east-1.amazonaws.com/stagingii",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://kc7dhw3oonggrpwtksciwei6ky.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:fe6d8695-421b-498b-8f10-2b97b5277b7f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Q5mWkyakg",
    "aws_user_pools_web_client_id": "5uoaas418sv4saa8i4443ahnkc",
    "oauth": {
        "domain": "auth-stagingii.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://www.mygadget.io/",
        "redirectSignOut": "http://localhost:3000/,https://www.mygadget.io/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "instant-quote-dev133507-stagingii",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
