// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const SurveyType = {
  "VIRTUAL": "VIRTUAL",
  "IN_PERSON": "IN_PERSON"
};

const ConstructionType = {
  "GROUND_UP": "GROUND_UP",
  "ADAPTIVE_REUSE_CLEAN_SLATE": "ADAPTIVE_REUSE_CLEAN_SLATE",
  "ADAPTIVE_REUSE_RECYCLED_SYSTEMS": "ADAPTIVE_REUSE_RECYCLED_SYSTEMS"
};

const ContractPackageName = {
  "GIST": "GIST",
  "MINIMALIST": "MINIMALIST",
  "COLLABORATOR": "COLLABORATOR",
  "PERFECTIONIST": "PERFECTIONIST",
  "TWO_WEEK": "TWO_WEEK",
  "MINI": "MINI"
};

const DesignCategory = {
  "RESTAURANT": "RESTAURANT",
  "BREWERY": "BREWERY",
  "OFFICE": "OFFICE",
  "MULTIFAMILY": "MULTIFAMILY",
  "MEDICAL": "MEDICAL",
  "WAREHOUSE": "WAREHOUSE"
};

const ContractStatus = {
  "OPEN": "OPEN",
  "AUTHORIZED": "AUTHORIZED",
  "PAUSED": "PAUSED",
  "DEFAULT": "DEFAULT"
};

const TradeSupervision = {
  "MECH": "MECH",
  "ELEC": "ELEC",
  "PLUMB": "PLUMB"
};

const { User, Organization, CurrentContractPricingConfiguration, Project, Quote, Contract, Deliverable, UserOrganizationRelationships, ProjectLocation, ContractPricingConfiguration, Dependency, ProductVariation, ContractMetadata, ProjectInputs, CategoryMultiplier, DisciplinePricingConfiguration, ContractPricingConfigurationSnapshot, ContractPackage } = initSchema(schema);

export {
  User,
  Organization,
  CurrentContractPricingConfiguration,
  Project,
  Quote,
  Contract,
  Deliverable,
  UserOrganizationRelationships,
  SurveyType,
  ConstructionType,
  ContractPackageName,
  DesignCategory,
  ContractStatus,
  TradeSupervision,
  ProjectLocation,
  ContractPricingConfiguration,
  Dependency,
  ProductVariation,
  ContractMetadata,
  ProjectInputs,
  CategoryMultiplier,
  DisciplinePricingConfiguration,
  ContractPricingConfigurationSnapshot,
  ContractPackage
};