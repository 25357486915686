/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Badge,
  Button,
  Divider,
  Flex,
  Text,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function FooterFrame(props) {
  const {
    selectedPackageName,
    selectedPackagePrice,
    projectName = "Start entering data to view live pricing!",
    projectAddress,
    mechBadgeDisplay = "none",
    elecBadgeDisplay = "none",
    plumbBadgeDisplay = "none",
    preparedForInformation,
    overrides: overridesProp,
    ...restProp
  } = props;
  const variants = [
    {
      overrides: {
        projectName: {},
        projectAddress: {},
        organizationName: {},
        ProjectSummary: {},
        Divider40869551: {},
        theSelectedPackageName: {},
        nameAndFee: {},
        Mechanical: {},
        Electrical: {},
        Plumbing: {},
        Disciplines: {},
        projectSummary: {},
        Divider39168867: {},
        theSelectedPackagePrice: {},
        startOverButton: {},
        FeeAndStartOver: {},
        PriceSummaryAndMenuIcon: {},
        saveButton: {},
        sendCopyButton: {},
        buttons: {},
        FooterFrame: {},
      },
      variantValues: { breakpoint: "large" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "large",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="20px"
      direction="row"
      width="1169px"
      height="115px"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      border="1px SOLID rgba(0,0,0,1)"
      boxShadow="4px -5px 18px rgba(0, 0, 0, 0.15000000596046448)"
      padding="7px 19px 7px 19px"
      backgroundColor="rgba(149,4,149,1)"
      display="flex"
      {...getOverrideProps(overrides, "FooterFrame")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "ProjectSummary")}
      >
        <Text
          fontFamily="Inter"
          fontSize="24px"
          fontWeight="600"
          color="rgba(255,255,255,1)"
          lineHeight="30px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={projectName}
          {...getOverrideProps(overrides, "projectName")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.01px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={projectAddress}
          {...getOverrideProps(overrides, "projectAddress")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={preparedForInformation}
          {...getOverrideProps(overrides, "organizationName")}
        ></Text>
      </Flex>
      <Divider
        height="unset"
        shrink="0"
        alignSelf="stretch"
        backgroundColor="rgba(255,255,255,1)"
        size="small"
        orientation="vertical"
        {...getOverrideProps(overrides, "Divider40869551")}
      ></Divider>
      <Flex
        gap="20px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="center"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 8px 0px 8px"
        display="flex"
        {...getOverrideProps(overrides, "PriceSummaryAndMenuIcon")}
      >
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "projectSummary")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "nameAndFee")}
          >
            <Text
              fontFamily="Inter"
              fontSize="48px"
              fontWeight="300"
              color="rgba(255,255,255,1)"
              lineHeight="60px"
              textAlign="right"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={selectedPackageName}
              {...getOverrideProps(overrides, "theSelectedPackageName")}
            ></Text>
          </Flex>
          <Flex
            gap="5px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="space-between"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Disciplines")}
          >
            <Badge
              width="unset"
              height="unset"
              shrink="0"
              size="default"
              variation="default"
              display={mechBadgeDisplay}
              children="Mechanical"
              {...getOverrideProps(overrides, "Mechanical")}
            ></Badge>
            <Badge
              width="unset"
              height="unset"
              shrink="0"
              size="default"
              variation="default"
              display={elecBadgeDisplay}
              children="Electrical"
              {...getOverrideProps(overrides, "Electrical")}
            ></Badge>
            <Badge
              width="unset"
              height="unset"
              shrink="0"
              size="default"
              variation="default"
              display={plumbBadgeDisplay}
              children="Plumbing"
              {...getOverrideProps(overrides, "Plumbing")}
            ></Badge>
          </Flex>
        </Flex>
        <Divider
          height="unset"
          shrink="0"
          alignSelf="stretch"
          backgroundColor="rgba(255,255,255,1)"
          size="small"
          orientation="vertical"
          {...getOverrideProps(overrides, "Divider39168867")}
        ></Divider>
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="95px"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="3px 0px 1px 0px"
          display="flex"
          {...getOverrideProps(overrides, "FeeAndStartOver")}
        >
          <Text
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="500"
            color="rgba(255,255,255,1)"
            lineHeight="40px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={selectedPackagePrice}
            {...getOverrideProps(overrides, "theSelectedPackagePrice")}
          ></Text>
          <Button
            width="unset"
            height="unset"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            variation="default"
            children="Start Over"
            {...getOverrideProps(overrides, "startOverButton")}
          ></Button>
        </Flex>
      </Flex>
      <Flex
        gap="8px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "buttons")}
      >
        <Button
          width="unset"
          height="unset"
          shrink="0"
          alignSelf="stretch"
          size="default"
          isDisabled={false}
          variation="default"
          children="Save"
          {...getOverrideProps(overrides, "saveButton")}
        ></Button>
        <Button
          width="unset"
          height="unset"
          padding="0px 16px 0px 16px"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          size="default"
          isDisabled={false}
          variation="default"
          children="Send Copy"
          {...getOverrideProps(overrides, "sendCopyButton")}
        ></Button>
      </Flex>
    </Flex>
  );
}
