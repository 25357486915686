// React
import { useEffect, useState, useContext } from "react";

// Amplify
import { Flex, Button, Text, useTheme } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";

// Amplify Studio Figma Components
import { OrganizationCreateForm } from "../ui-components";

// Amplify Codegen
import {
  ContractStatus,
  ConstructionType,
  Project,
  ProjectInputs,
  ProjectLocation,
  Organization,
  User,
  UserOrganizationRelationships
} from "../models";

// Other Third Party Libraries
import Swal from "sweetalert2";

// Gadget Engineering Imports
import { InstantQuoteContext } from "../Context";
import { GadgetCompanyCard, GadgetCompanySearch } from ".";
import InfoTooltip from "./InfoTooltip";
export default function CompanySelectorInterfaces(props) {
  // Props
  const { overrides, ...rest } = props;

  // Context
  const {
    address,
    location,
    setProjectHasMinimumRequiredInputs,
    projectName,
    setProjectId,
    setOrganizationName,
    setProjectRecord,
    projectId,
    setOrganization,
    organization,
    organizationHasBeenSelected,
    setOrganizationHasBeenSelected,
    organizationName,
    userInputProgress,
    user,
    distanceMatrixData
  } = useContext(InstantQuoteContext);

  // State
  const [isOrganizationBeingCreated, setIsOrganizationBeingCreated] =
    useState(false);

  const [selectedOrganizaion, setSelectedOrganizaion] = useState(true);

  const [createdOrgId, setCreatedOrgId] = useState('');

  // Constants
  const { tokens } = useTheme();

  // Function Expressions
  const initiateProject = function () {
    let message = "";
    if (address.length === 0) message += "Project Address is Missing \n";
    if (projectName.length === 0) message += "Project Name is Missing \n";
    setOrganizationName(organization.name);
    setSelectedOrganizaion(true);
    if (!userInputProgress.isEditProject) {
      if (address.length > 0 && projectName.length > 0) {
        createProject();
        setProjectHasMinimumRequiredInputs(true);
        Swal.fire({
          icon: "success",
          title: "Your project has been Started!",
          text: "Enter the fields to see the real time pricing!",
        });
      } else
        Swal.fire({
          icon: "error",
          title: "Some Details are missing",
          text: message,
        });
    }
  };

  // Arrow Functions

  // Effects
  useEffect(
    () => {
      const projectSubscription = DataStore.observeQuery(Project, (project) =>
        project.id.eq(projectId)
      ).subscribe(({ items }) => {
        setProjectRecord(items[0]);
      });

      return () => {
        projectSubscription.unsubscribe(); // this invokes on teardown, before next render
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Memos

  // Function Declarations

  function CompanySearchCard() {
    const Css = `
    .amplify-pagination__item-button, .amplify-pagination__item-button--link{
      color: white;
    }
    .amplify-pagination__item-button:hover{
      color: white;
    }
    `;
    return (
      <Flex
        id="CompanyCardSearchContainer"
        direction="column"
        alignSelf="stretch"
        alignContent="stretch"
        grow={1}
        gap="30px"
        height="unset"
        width="100%"
      >
        <style>{Css}</style>

        <GadgetCompanySearch
          grow={1}
          type="list"
          display="flex"
          itemsPerPage={3}
          alignItems="stretch"
          alignContent="stretch"
          justifyContent="stretch"
          isPaginated={true}
          width="100%"
          overrides={{
            CompanyCardSearch: {
              searchPlaceholder: "Find a company",
            },
          }}
          overrideItems={({ item, index }) => ({
            iconType: "bookmark_border",
            iconDisplay: "block",
            buttonDisplay: "none",
            width: "100%",
            height: "unset",
            margin: "8px 0px 8px 0px",
            variant: "default",
            companyDetailsDisplayType: "block",
            breakpoint: "small",
            backgroundColor: tokens.colors.background.tertiary,
            alignSelf: "center",
            overrides: {
              OrgDetailsFrame: { display: "block" },
              OrgName: {
                isTruncated: true,
                overflow: "hidden",
                height: "unset",
                lineHeight: "unset",
                whiteSpace: "nowrap",
              },
              OrgTagLine: {
                isTruncated: true,
                whiteSpace: "nowrap",
              },
              OrgAddress: { isTruncated: true, as: "div" },
              RootView: {},
              BodyFrame: { display: "block" },
            },
            onClick: () => {
              setOrganization(item);
              setOrganizationHasBeenSelected(true);
            },
          })}
        />

        <Button
          variation="default"
          alignItems="center"
          color="white"
          alignSelf="stretch"
          justifyContent="center"
          textAlign={"center"}
          display={organizationHasBeenSelected ? "none" : "block"}
          onClick={() =>
            setIsOrganizationBeingCreated(!isOrganizationBeingCreated)
          }
        >
          {!isOrganizationBeingCreated && "I don't see my company"}
        </Button>
      </Flex>
    );
  }

  async function checkUser() {
    const userList = await DataStore.query(User, (person) =>
      person.and(person => [
        person.cognitoUserID.eq(user.attributes.sub),
        person.cognitoUserPoolID.eq(user['pool']['userPoolId'])
      ])
    );
    if (userList.length === 0)
      await DataStore.save(
        new User({
          email: user.attributes.email,
          name: user.attributes.name,
          cognitoUserID: user.attributes.sub,
          cognitoUserPoolID: user.pool.userPoolId
        })
      )
  }

  async function getOrganization(companyName) {
    const createdCompany = await DataStore.query(Organization, (company) =>
      company.name.eq(companyName)
    );
    checkUser()
    setOrganization(createdCompany[0]);
    setCreatedOrgId(createdCompany[0]?.id)
    setOrganizationHasBeenSelected(true);
  }

  async function validateOrganization(field, value, validationResponse) {
    const errorField = {
      name: "Name",
      address: "Address",
      billingEmail: "Billing Email",
      tagLine: "Tag Line",
    };
    const createdCompany = await DataStore.query(Organization, (company) =>
      company[field].eq(value)
    );
    if (createdCompany.length > 0)
      return {
        hasError: true,
        errorMessage: `Organization with this ${errorField[field]} already exist`,
      };
    else if (validationResponse) return validationResponse;
    else
      return {
        hasError: false,
        errorMessage: "",
      };
  }

  function AddNewCompanyForm() {
    const BtnColor = `
    button.amplify-button.amplify-field-group__control{
      color:white;
    }
    p.amplify-text.amplify-field__error-message{
      color:red;
    }
    `;
    return (
      <>
        <style>{BtnColor}</style>
        <OrganizationCreateForm
          overrides={{
            picture: {
              display: "none",
            },
            name: {
              label: "Company Name",
            },
          }}
          onSuccess={(data) => {
            getOrganization(data.name);
            setIsOrganizationBeingCreated(!isOrganizationBeingCreated);
          }}
          onValidate={{
            name: (value, validationResponse) => {
              return validateOrganization("name", value);
            },
            address: (value, validationResponse) => {
              return validateOrganization("address", value);
            },
            billingEmail: (value, validationResponse) => {
              return validateOrganization(
                "billingEmail",
                value,
                validationResponse
              );
            },
            tagLine: (value, validationResponse) => {
              return validateOrganization("tagLine", value);
            },
          }}
          onSubmit={(fields) => {
            // Example function to trim all string inputs
            Object.keys(fields).forEach((key) => {
              console.log(key);
            });
            return fields;
          }}
          onCancel={() => {
            setIsOrganizationBeingCreated(false);
          }}
        />
      </>
    );
  }

  function ConfirmCompanySelection() {
    return (
      <Flex direction="row" alignSelf="stretch">
        <GadgetCompanyCard
          breakpoint="small"
          companyDetailsDisplayType="flex"
          variant="default"
          width="100%"
          alignSelf="center"
          height="unset"
          margin="8px 0px 8px 0px"
          backgroundColor={tokens.colors.background.tertiary}
          organization={organization}
          overrides={{
            OrgDetailsFrame: { display: "block" },
            OrgName: {
              overflow: "hidden",
              isTruncated: true,
              height: "unset",
              lineHeight: "unset",
              whiteSpace: "nowrap",
            },
            OrgTagLine: {
              isTruncated: true,
              whiteSpace: "nowrap",
            },
            OrgAddress: { isTruncated: true, as: "div" },
            BodyFrame: { gap: "20px" },
            ActionIcon: {
              type: "close",
              display: "block",
              onClick: () => {
                setSelectedOrganizaion(false);
                setOrganizationHasBeenSelected(false);
              },
            },
            Button: {
              display:
                selectedOrganizaion && organizationName ? "none" : "block",
              onClick: initiateProject,
              children: "Confirm",
              size: "large",
            },
          }}
        />
      </Flex>
    );
  }

  function CompanySelectorHeader() {
    const tooltipMessage = {
      selectYourCompany:
        "This will be the organization responsible for authorizing the contract.Select from existing options or choose ‘I don't see my company’ to add them to our system.",
      confirmYourCompany:
        "Please confirm if this is the authorizing party for the new proposal. Selecting an authorizing party does not obligate you to hire PermitZip.",
    };

    return (
      <Flex
        gap="10px"
        direction="row"
        width="100%"
        height="unset"
        justifyContent="stretch"
        alignItems="stretch"
        alignContent="stretch"
        alignSelf="stretch"
        overflow="hidden"
        grow="1"
        display="flex"
        shrink="0"
        position="relative"
        padding="0px"
        id="companySelectionCardHeader"
      >
        <Text
          fontFamily="Inter"
          fontSize="24px"
          fontWeight="600"
          color="rgba(255,255,255,1)"
          lineHeight="30px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="100%"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={
            organizationHasBeenSelected
              ? organizationName
                ? "Selected Company"
                : "Confirm your Company"
              : "Select your Company"
          }
          id="HeaderText"
        ></Text>
        <InfoTooltip
          visibility="true"
          message={
            organizationHasBeenSelected
              ? tooltipMessage["confirmYourCompany"]
              : tooltipMessage["selectYourCompany"]
          }
          color={"#fff"}
        />
      </Flex>
    );
  }

  async function createProject() {
    checkUser();
    let projectLocation = {
      latitude: location.lat,
      longitude: location.lng,
      address: address,
      driveDistance: distanceMatrixData.distance,
      driveDuration: distanceMatrixData.time
    };

    const userList = await DataStore.query(User, (person) =>
      person.and(person => [
        person.cognitoUserID.eq(user.attributes.sub),
        person.cognitoUserPoolID.eq(user['pool']['userPoolId'])
      ])
    );

    const newProject = await DataStore.save(
      new Project({
        isTwoWeek: false,
        name: projectName,
        organizationProjectsId: organization?.id,
        scopeSummary: "<<add details to scope>>",
        status: ContractStatus.OPEN,
        // createdAt:new Date().toISOString(),
        tradeSupervisions: [],
        projectInputs: new ProjectInputs({
          projectLocation: new ProjectLocation(projectLocation),
        }),
        userEmail: user.attributes.email,
        userProjectsId: userList[0]?.id,
        editors: [`${user.attributes.sub}::${user.username}`]
      })
    );

    if (createdOrgId)
      await DataStore.save(
        new UserOrganizationRelationships({
          userID: userList[0]?.id,
          organizationID: createdOrgId
        })
      )
    setCreatedOrgId('')

    setProjectId(newProject.id);

    setProjectRecord(
      Project.copyOf(newProject, (projectCopy) => {
        projectCopy.projectInputs.constructionType =
          ConstructionType.GROUND_UP;
        projectCopy.projectInputs.surveyType = null;
      })
    );
  }

  return (
    <Flex
      display="flex"
      gap="24px"
      direction="column"
      width="100%"
      height="unset"
      justifyContent="flex-start"
      alignItems="stretch"
      overflow="hidden"
      shrink="1"
      grow="1"
      position="relative"
      border="1px SOLID rgba(174,179,183,1)"
      borderRadius="10px"
      padding="16px"
      backgroundColor={tokens.colors.brand.secondary[90]}
      id="CompanySelectionCard"
    >
      <CompanySelectorHeader />
      {isOrganizationBeingCreated ? (
        <AddNewCompanyForm id="AddNewCompanyForm" />
      ) : organizationHasBeenSelected ? (
        <ConfirmCompanySelection id="ConfirmCompanySelection" />
      ) : (
        <CompanySearchCard id="CompanySearchCard" />
      )}
    </Flex>
  );
}