// React
import { useEffect, useContext } from "react";

// Amplify
import { Autocomplete, useTheme, Text, View } from "@aws-amplify/ui-react";
import { ProjectNameAndLocationSelectionCard } from "../ui-components";
import { DataStore } from "aws-amplify";
import { Project } from "../models";

// Third Party Libraries
import Swal from "sweetalert2";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  DistanceMatrixService,
} from "@react-google-maps/api";

// Gadget Engineering Libraries
import { InstantQuoteContext } from "../Context/ProposalContext";
import ExistingProjectAddressModal from "./ExistingProjectAddressModal";

const libraries = ["places"];
export function ProjectAddressAndMapViewer(props) {
  // Props
  const { visible, ...rest } = props;
  // Constants and Context
  const {
    changeInLocation,
    setChangeInLocation,
    location,
    distanceMatrixData,
    setDistanceMatrixData,
  } = useContext(InstantQuoteContext);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: libraries,
  });

  // State

  // React Effects
  useEffect(() => {
    setChangeInLocation(false);
  }, [changeInLocation, setChangeInLocation]);

  // React Memos

  // Arrow Functions


  function getNextMonday() {
    const now = new Date()
    const today = new Date(now)
    today.setMilliseconds(0)
    today.setSeconds(0)
    today.setMinutes(0)
    today.setHours(8)

    const nextMonday = new Date(today)

    do {
      nextMonday.setDate(nextMonday.getDate() + 1) // Adding 1 day
    } while (nextMonday.getDay() !== 1)
    return nextMonday
  }
  // Function Expressions

  // Function Declarations

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <View id="Map Viewer">
      {!visible && (
        <>
          <GoogleMap
            zoom={15}
            center={location}
            options={{
              disableDefaultUI: "true",
            }}
            mapContainerClassName="google-map-container"
          >
            {location && <MarkerF position={location} />}
          </GoogleMap>
          <DistanceMatrixService
            options={{
              destinations: [location],
              origins: [{ lat: 37.5202399, lng: -77.48918379999999 }],
              travelMode: "DRIVING",
              drivingOptions: {
                departureTime: getNextMonday(),
                trafficModel: "pessimistic",
              },
            }}
            callback={(response) => {
              console.log(response.rows[0].elements[0].status);
              if (response.rows[0].elements[0].status === "OK") {
                distanceMatrixData.distance =
                  response.rows[0].elements[0].distance.value;
                distanceMatrixData.time =
                  response.rows[0].elements[0].duration_in_traffic.value;
                setDistanceMatrixData(distanceMatrixData);
                console.log(response.rows[0].elements[0].distance.text);
                console.log(response.rows[0].elements[0].duration_in_traffic);
              }
            }}
          />
        </>
      )}
      {visible && <AddressFieldAutoComplete />}
    </View>
  );
}

export function AddressFieldAutoComplete(props) {
  const { ...rest } = props;
  // Context
  const {
    setLocation,
    setChangeInLocation,
    setAddress,
    userInputProgress,
    setUserInputProgress,
    address,
  } = useContext(InstantQuoteContext);

  // Constants
  const { tokens } = useTheme();

  const {
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  // State

  // Effects

  // Memos

  // Function Expressions

  // Arrow Functions

  // Function Declarations

  const handleSelect = async function (address) {
    setAddress(address);
    setValue(address, false);
    clearSuggestions();
    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    console.log({ lat: lat, lng: lng });
    setLocation({ lat, lng });
    setChangeInLocation(true);
  };

  const collectPlaces = function (data) {
    let mapList = [];
    status === "OK" &&
      data.map(({ place_id, description }) =>
        mapList.push({ id: place_id, label: description })
      );
    return mapList;
  };

  // Arrow Functions

  // Effects

  // Memos

  // Function Declarations

  return (
    <>
      <Autocomplete
        {...rest}
        id="AddressAutoCompleteField"
        variation="quiet"
        labelHidden={false}
        label={<Text color={tokens.colors.font.inverse}>Project Location</Text>}
        isDisabled={
          !(
            userInputProgress.isProjectNameSet ||
            userInputProgress.isEditProject
          )
        }
        hasSearchIcon={false}
        onClear={() => {
          setAddress("");
          userInputProgress.isProjectLocationSet = false;
          setUserInputProgress(userInputProgress);
        }}
        onSelect={(e) => handleSelect(e.label)}
        onChange={(e) => {
          setValue(e.target.value);
          setAddress("");
          userInputProgress.isProjectLocationSet = false;
          setUserInputProgress(userInputProgress);
        }}
        options={collectPlaces(data)}
        placeholder={address ? address : "1212 Westover Hills Blvd Richmond Va"}
      />
    </>
  );
}

export default function NameAndAddressSelector(props) {
  // Constants and Context

  const { ...rest } = props;
  const {
    setUserInputProgress,
    userInputProgress,
    distanceMatrixData,
    projectName,
    address,
    setProjectAddressList,
    setProjectName,
    setExistingProjectAddressOpen,
  } = useContext(InstantQuoteContext);


  async function checkAddress(addr) {
    const projects = await DataStore.query(Project);
    var flag = false;
    var addressList = [];
    for (var project of projects) {
      if (project.projectInputs.projectLocation?.address === addr) {
        flag = true;
        addressList.push(project);
      }
    }
    addressList.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    setProjectAddressList(addressList);
    if (flag)
      Swal.fire({
        title: "One or more projects at this location already exist",
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "See Existing Projects",
        cancelButtonText: "Continue Creating New Project",
        allowOutsideClick: false,
        allowEscapeKey: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setExistingProjectAddressOpen(true);
          return <ExistingProjectAddressModal />;
        }
      });
  }
  const { tokens } = useTheme();

  return (
    <View {...props}>
      <ProjectNameAndLocationSelectionCard
        projectLocationTextField={<ProjectAddressAndMapViewer visible={true} />}
        mapsPlaceholder={<ProjectAddressAndMapViewer visible={false} />} // LOL i see what you mean now - kms
        display="flex"
        gap="20px"
        direction="column"
        width="unset"
        height={userInputProgress.isEditProject ? '100%' : "590px"}
        justifyContent="stretch"
        alignItems="stretch"
        alignContent="stretch"
        shrink="0"
        position="relative"
        borderRadius="10px"
        padding="20px 20px 20px 20px"
        backgroundColor={tokens.colors.brand.secondary[90]}
        overrides={{
          ProjectNameTextField: { height: "unset" },
          ProjectLocationTextField: {
            height: "unset",
            width: "unset",
          },
          HeaderText: {
            children: "Project Overview",
          },
          ProjectName: {
            placeholder: "Sunset Boulevard",
            width: "unset",
            label: (
              <Text color={tokens.colors.font.inverse}>Project Nickname</Text>
            ),
            labelHidden: false,
            position: "relative",
            defaultValue: projectName,
            onChange: (e) => {
              setProjectName(e.currentTarget.value);
              userInputProgress.isProjectNameSet =
                e.currentTarget.value.length > 0;
              setUserInputProgress(userInputProgress);
            },
          },
          MapsPlaceholder: {
            width: "unset",
            height: "250px",
          },
          confirmLocationView: {
            display: userInputProgress.isEditProject ? 'none' : 'flex',
          },
          confirmButton: {
            children: "Confirm Location",
            isDisabled: address ? false : true,
            onClick: () => {
              distanceMatrixData.distance === 0 &&
                Swal.fire({
                  icon: "info",
                  title: "Location Invalid",
                  text: "Our services are currently not available in your location! Contact sales@permitzip.com to check in on our expansion plans and request our services in your area!",
                });
              if (distanceMatrixData.distance !== 0) {
                userInputProgress.isProjectLocationSet = true;
                setUserInputProgress(userInputProgress);
                checkAddress(address);
              }
            },
          },
        }}
        id="ProjectNameAndLocationSelectionCard"
      />
    </View>
  );
}
