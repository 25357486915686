/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Alert,
  Badge,
  Divider,
  Flex,
  Text,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import GadgetIcons from "./GadgetIcons";
export default function QuoteSnapshotCard(props) {
  const {
    projectAddress,
    packageName,
    quote,
    notificationDisplay = "none",
    overrides: overridesProp,
    ...restProp
  } = props;
  const variants = [
    {
      overrides: {
        proposalExpirationWarning: {},
        ProjectName: {},
        DividerFrame44595011: {},
        ProjectAddress: {},
        DividerFrame44745451: {},
        QuoteCreatedDate: {},
        SummaryFrame: {},
        GadgetIcons44595014: {},
        GadgetIcons44595015: {},
        GadgetIcons44595016: {},
        TestBuffer: {},
        ProjectSummary: {},
        packageTypeBadge: {},
        Divider: {},
        TotalFee: {},
        Fee: {},
        FrameOfReference: {},
        QuoteSnapshotCard: {},
      },
      variantValues: { breakpoint: "small", variant: "outlined" },
    },
    {
      overrides: {
        proposalExpirationWarning: {},
        ProjectName: {},
        DividerFrame44595011: {},
        ProjectAddress: {},
        DividerFrame44745451: {},
        QuoteCreatedDate: {},
        SummaryFrame: {},
        GadgetIcons44595014: {},
        GadgetIcons44595015: {},
        GadgetIcons44595016: {},
        TestBuffer: { width: "39px" },
        ProjectSummary: {
          border: "1px SOLID rgba(0,0,0,0)",
          borderRadius: "6px 6px 1px 1px",
        },
        packageTypeBadge: {},
        Divider: {},
        TotalFee: {},
        Fee: {
          border: "1px SOLID rgba(0,0,0,0)",
          borderRadius: "1px 1px 6px 6px",
          backgroundColor: "rgba(255,255,255,1)",
        },
        FrameOfReference: {},
        QuoteSnapshotCard: {},
      },
      variantValues: { breakpoint: "small", variant: "default" },
    },
    {
      overrides: {
        proposalExpirationWarning: { display: "none" },
        ProjectName: {},
        DividerFrame44595011: {},
        ProjectAddress: {},
        DividerFrame44745451: {},
        QuoteCreatedDate: {},
        SummaryFrame: {},
        GadgetIcons44595014: {},
        GadgetIcons44595015: {},
        GadgetIcons44595016: {},
        TestBuffer: { width: "39px" },
        ProjectSummary: { border: "2px SOLID rgba(77,0,77,1)" },
        packageTypeBadge: {},
        Divider: {},
        TotalFee: {},
        Fee: { border: "2px SOLID rgba(77,0,77,1)" },
        FrameOfReference: {},
        QuoteSnapshotCard: { boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" },
      },
      variantValues: { breakpoint: "small", variant: "focus" },
    },
    {
      overrides: {
        proposalExpirationWarning: {},
        ProjectName: {},
        DividerFrame44595011: {},
        ProjectAddress: {},
        DividerFrame44745451: {},
        QuoteCreatedDate: {},
        SummaryFrame: {},
        GadgetIcons44595014: {},
        GadgetIcons44595015: {},
        GadgetIcons44595016: {},
        TestBuffer: { width: "39px" },
        ProjectSummary: {
          height: "111px",
          border: "1px SOLID rgba(0,0,0,0)",
          borderRadius: "6px 6px 1px 1px",
        },
        packageTypeBadge: {},
        Divider: {},
        TotalFee: {},
        Fee: {
          border: "1px SOLID rgba(174,179,183,1)",
          borderRadius: "1px 1px 6px 6px",
          backgroundColor: "rgba(255,255,255,1)",
        },
        FrameOfReference: {},
        QuoteSnapshotCard: { width: "500px", height: "185px" },
      },
      variantValues: { breakpoint: "medium", variant: "default" },
    },
    {
      overrides: {
        proposalExpirationWarning: {},
        ProjectName: {},
        DividerFrame44595011: {},
        ProjectAddress: {},
        DividerFrame44745451: {},
        QuoteCreatedDate: {},
        SummaryFrame: {},
        GadgetIcons44595014: {},
        GadgetIcons44595015: {},
        GadgetIcons44595016: {},
        TestBuffer: { width: "39px" },
        ProjectSummary: {
          height: "117px",
          border: "1px SOLID rgba(174,179,183,1)",
          borderRadius: "6px 6px 1px 1px",
        },
        packageTypeBadge: {},
        Divider: {},
        TotalFee: {},
        Fee: {
          border: "1px SOLID rgba(174,179,183,1)",
          borderRadius: "1px 1px 6px 6px",
        },
        FrameOfReference: {},
        QuoteSnapshotCard: { width: "500px" },
      },
      variantValues: { breakpoint: "medium", variant: "outlined" },
    },
    {
      overrides: {
        proposalExpirationWarning: {},
        ProjectName: {},
        DividerFrame44595011: {},
        ProjectAddress: {},
        DividerFrame44745451: {},
        QuoteCreatedDate: {},
        SummaryFrame: {},
        GadgetIcons44595014: {},
        GadgetIcons44595015: {},
        GadgetIcons44595016: {},
        TestBuffer: { width: "39px" },
        ProjectSummary: {
          height: "119px",
          border: "2px SOLID rgba(77,0,77,1)",
        },
        packageTypeBadge: {},
        Divider: {},
        TotalFee: {},
        Fee: { border: "2px SOLID rgba(77,0,77,1)" },
        FrameOfReference: {},
        QuoteSnapshotCard: {
          width: "500px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        },
      },
      variantValues: { breakpoint: "medium", variant: "focus" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    small: "small",
    small: "small",
    medium: "medium",
    medium: "medium",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="300px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="10px"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "QuoteSnapshotCard")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "FrameOfReference")}
      >
        <Alert
          width="unset"
          height="70px"
          heading="Expires soon!"
          gap="5px"
          shrink="0"
          alignSelf="stretch"
          variation="warning"
          isDismissible={false}
          hasIcon={false}
          display={notificationDisplay}
          children="This proposal will expire in 2 days."
          {...getOverrideProps(overrides, "proposalExpirationWarning")}
        ></Alert>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          border="2px SOLID rgba(174,179,183,1)"
          borderRadius="7px 7px 2px 2px"
          padding="5px 20px 5px 20px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          {...getOverrideProps(overrides, "ProjectSummary")}
        >
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="3px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "SummaryFrame")}
          >
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="700"
              color="rgba(13,26,38,1)"
              lineHeight="25px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={quote?.name}
              {...getOverrideProps(overrides, "ProjectName")}
            ></Text>
            <Flex
              gap="0"
              direction="column"
              width="unset"
              height="6px"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="2px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "DividerFrame44595011")}
            ></Flex>
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="21px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={notificationDisplay}
              {...getOverrideProps(overrides, "ProjectAddress")}
            ></Text>
            <Flex
              gap="0"
              direction="column"
              width="unset"
              height="6px"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="2px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "DividerFrame44745451")}
            ></Flex>
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="700"
              color="rgba(48,64,80,1)"
              lineHeight="21px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Quote Created on: 07/19/2023"
              {...getOverrideProps(overrides, "QuoteCreatedDate")}
            ></Text>
          </Flex>
          <Flex
            gap="5px"
            direction="column"
            width="48px"
            height="unset"
            justifyContent="center"
            alignItems="flex-end"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(255,255,255,1)"
            display="flex"
            {...getOverrideProps(overrides, "TestBuffer")}
          >
            <GadgetIcons
              width="24px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              type="eye"
              {...getOverrideProps(overrides, "GadgetIcons44595014")}
            ></GadgetIcons>
            <GadgetIcons
              width="24px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              type="edit"
              {...getOverrideProps(overrides, "GadgetIcons44595015")}
            ></GadgetIcons>
            <GadgetIcons
              width="25px"
              height="25px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              type="delete"
              {...getOverrideProps(overrides, "GadgetIcons44595016")}
            ></GadgetIcons>
          </Flex>
        </Flex>
        <Flex
          gap="20px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          border="2px SOLID rgba(174,179,183,1)"
          borderRadius="2px 2px 7px 7px"
          padding="15px 20px 15px 20px"
          backgroundColor="rgba(239,240,240,1)"
          display="flex"
          {...getOverrideProps(overrides, "Fee")}
        >
          <Badge
            width="unset"
            height="unset"
            label="The Perfectionist"
            gap="16px"
            shrink="0"
            size="small"
            variation="info"
            children={packageName}
            {...getOverrideProps(overrides, "packageTypeBadge")}
          ></Badge>
          <Divider
            height="unset"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="vertical"
            {...getOverrideProps(overrides, "Divider")}
          ></Divider>
          <Text
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="600"
            color="rgba(13,26,38,1)"
            lineHeight="30px"
            textAlign="right"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={quote?.totalValue}
            {...getOverrideProps(overrides, "TotalFee")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
