// React
import { useEffect, useContext, useState } from "react";

// Amplify
import { DataStore } from "aws-amplify";
import { View } from "@aws-amplify/ui-react";

import { FooterFrame, GadgetIcons } from "../../ui-components";

import { Project, ProjectLocation } from "../../models";

// Gadget Engineering Imports
import { InstantQuoteContext } from "../../Context/ProposalContext";
import { SendQuoteModal } from "..";
import { Tooltip, Typography } from "@mui/joy";

import Swal from "sweetalert2";
import axios from "axios";
import ExistingProjectAddressModal from "../ExistingProjectAddressModal";

export default function Footer(props) {
  const { ...rest } = props;

  // Context
  const {
    instantQuoteApi,
    user,
    projectId,
    setProjectRecord,
    projectRecord,
    projectName,
    address,
    organizationName,
    userInputProgress,
    setUserInputProgress,
    discipline,
    sendQuoteModalSelected,
    setSendQuoteModalSelected,
    setIsQuoteView,
    projectHasMinimumRequiredInputs,
    addCommaToPrice,
    organization,
    authorizerEmail,
    authorizerName,
    location,
    squareFootage,
    constructionType,
    surveyType,
  } = useContext(InstantQuoteContext);

  // Variables

  // State

  // Function Expressions

  // Arrow Functions

  // React Effects
  useEffect(
    () => {
      const projectSubscription = DataStore.observeQuery(Project, (project) =>
        project.id.eq(projectId)
      ).subscribe(({ items }) => {
        setProjectRecord(items[0]);
      });

      return () => {
        projectSubscription.unsubscribe(); // this invokes on teardown, before next render
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // Function Declarations
  function titleCase(sentence) {
    var titleCaseSentence = sentence.toLowerCase().split(" ");
    for (var index = 0; index < titleCaseSentence.length; index++) {
      titleCaseSentence[index] =
        titleCaseSentence[index][0].toUpperCase() +
        titleCaseSentence[index].slice(1);
    }
    return titleCaseSentence.join(" ");
  }

  async function startOverFunc() {
    if (projectHasMinimumRequiredInputs && !userInputProgress.isSaveProject) {
      const currentProject = await DataStore.query(Project, projectId);
      DataStore.delete(currentProject);
    }
    window.location.reload();
  }

  async function saveCurrentProject() {
    userInputProgress.isSaveProject = true;
    setUserInputProgress(userInputProgress);
    Swal.fire({
      icon: "info",
      title: "Saving your project...",
      // text: "Once the email is sent, the confirmation message will be shown",
      showConfirmButton: false,
    });
    const updateProject = await DataStore.query(Project, projectId);
    if (updateProject.pipedriveID) {
      const sendEmailFields = {
        userName: user.attributes.name,
        address: address,
        authorizerName: authorizerName,
        authorizerEmail: authorizerEmail.toLowerCase(),
        packagePrice: projectRecord.totalValue,
        squareFootage: squareFootage,
        discipline: discipline,
        constructionType: constructionType,
        surveyType: surveyType,
        userEmail: user.attributes.email,
        organizationDetails: organization,
        projectName: projectName,
        pipedriveID: updateProject.pipedriveID,
        projectId: projectId,
        isNewQuote: false,
        isDeleteProject: false,
      };
    
      try {
        await axios
          .post(
            `${instantQuoteApi}/proposals/pipedrive`,
            sendEmailFields
          )
          .then((res) => {
            console.log(res)
            if (res.data.errorMessage)
              Swal.fire({
                icon: "error",
                title: "Something went wrong!",
                text: "Check the entered mail ID's",
              });
            else saveProject(res.data.deal_id);
          });
      } catch (error) {
        console.log(error);
      }
    } else saveProject(null);
  }

  async function saveProject(pipedriveID) {
    const updateProject = await DataStore.query(Project, projectId);
    await DataStore.save(
      Project.copyOf(updateProject, (projectCopy) => {
        projectCopy.name = projectName;
        projectCopy.organizationProjectsId = organization?.id;
        projectCopy.tradeSupervisions = [...projectRecord.tradeSupervisions];
        projectCopy.totalValue = projectRecord.totalValue;
        projectCopy.projectInputs = { ...projectRecord.projectInputs };
        projectCopy.contractPricingConfigurationSnapshot = {
          ...projectRecord.contractPricingConfigurationSnapshot,
        };
        projectCopy.authorizerEmail = authorizerEmail
          ? authorizerEmail.toLowerCase()
          : null;
        projectCopy.authorizerName = authorizerName;
        projectCopy.pipedriveID = pipedriveID;
      })
    ).then(() =>
      Swal.fire({
        icon: "success",
        title: "Project Saved!",
        text: "Your current work is saved successfully!!",
      })
    );
  }

  const [projectDetails, setProjectDetails] = useState({
    name: "",
    OrgName: "",
    address: "",
    totalValue: "",
    constructionType: "",
    surveyType: "",
    preferredContractPackage: "",
    authorizerName: authorizerName,
    authorizerEmail: authorizerEmail,
  });

  function liveProjectData() {
    setIsQuoteView(false);

    projectDetails["name"] = projectRecord.name;
    projectDetails["address"] = projectRecord.projectInputs?.projectLocation.address;
    projectDetails["OrgName"] = organizationName;
    projectDetails["totalValue"] = projectRecord.totalValue;
    projectDetails["preferredContractPackage"] =
      projectRecord.projectInputs.preferredContractPackage;
    projectDetails["constructionType"] =
      projectRecord.projectInputs.constructionType;
    projectDetails["surveyType"] = projectRecord.projectInputs.surveyType;

    setProjectDetails(projectDetails);
    setSendQuoteModalSelected(true);
  }

  return (
    // Do not delete the parent View wrapper. Set props for it at the app.jsx level
    <View {...rest}>
      <FooterFrame
        mechBadgeDisplay={discipline.mechanical ? "block" : "none"}
        elecBadgeDisplay={discipline.electrical ? "block" : "none"}
        plumbBadgeDisplay={discipline.plumbing ? "block" : "none"}
        selectedPackagePrice={
          projectRecord?.totalValue
            ? addCommaToPrice(projectRecord.totalValue)
            : "$ 0"
        }
        selectedPackageName={
          projectRecord?.totalValue // wait until total value has been set
            ? titleCase(projectRecord.projectInputs.preferredContractPackage)
            : ""
        }
        projectName={
          projectName ? (
            userInputProgress.isEditProject ? (
              <>
                <div style={{ margin: "5px 0 15px 0" }}> Editing Project:</div> {projectName}
              </>
            ) : (
              projectName
            )
          ) : (
            "Instant pricing is within your reach."
          )
        }
        projectAddress={
          userInputProgress.isEditProject ? "" : address ? address : ""
        }
        preparedForInformation={
          userInputProgress.isEditProject
            ? ""
            : organizationName
              ? organizationName
              : ""
        }
        width="100%"
        overrides={{
          Disciplines: {
            justifyContent: "flex-start",
          },
          FooterFrame: {
            height: "125px"

          },
          ProjectSummary: {
            fontSize: "10px",
            maxWidth: {
              base: "180px",
              small: "300px",
              medium: "300px",
              large: "400px",
              xl: "680px",
              xxl: '780px'
            },
          },
          projectName: {
            fontSize: {
              base: "22px",
              small: "22px",
              medium: "22px",
              large: "22px",
              xl: "28px",
              xxl: "30px",
            },
            margin: '10px 0px',
          },
          organizationName: {
            marginTop: '8px',
          },
          saveButton: {
            isDisabled:
              userInputProgress.isEditProject || projectHasMinimumRequiredInputs
                ? false
                : true,
            children: (
              <View display="flex" color="#fff" fontSize="18px">
                Save
                <GadgetIcons
                  width={"auto"}
                  height={"28px"}
                  type="bookmark_border-filled"
                  overrides={{
                    GadgetIcons: {
                      paths: [
                        {
                          d: "M12 0L2 0C0.9 0 0 0.9 0 2L0 18L7 15L14 18L14 2C14 0.9 13.1 0 12 0ZM12 15L7 12.82L2 15L2 2L12 2L12 15Z",
                          fill: "#fff",
                          fillRule: "nonzero",
                          style: { transform: "translate(20.83%, 12.5%)" },
                        },
                      ],
                    },
                  }}
                  margin={"0 8px"}
                />
              </View>
            ),
            onClick: () => saveCurrentProject(),
          },
          contractSummary: {
            display: {
              base: "none",
              small: "none",
              medium: "none",
              large: "flex",
              xl: "flex",
            },
          },
          theSelectedPackageName: {
            fontSize: {
              small: "20px",
              medium: "30px",
              large: "40px",
              xl: "43px",
            },
          },
          FeeAndStartOver: {
            display: {
              base: "none",
              small: "none",
              medium: "flex",
              large: "flex",
              xl: "flex",
            },
          },
          buttons: { alignItems: "flex-start" },
          startOverButton: {
            color: '#fff',
            fontSize: '18px',
            padding: '8px 12px 7px 12px',
            onClick: () => startOverFunc(),
            alignSelf: "unset",
          },
          sendCopyButton: {
            color: "#fff",
            alignSelf: "unset",
            isDisabled: projectRecord?.totalValue ? false : true,
            padding: "8px",
            width: "100%",
            children: <InfoToolTip />,
            onClick: () => {
              projectRecord?.totalValue && liveProjectData();
            },
          },
        }}
      />

      <SendQuoteModal
        open={sendQuoteModalSelected}
        projectDetails={projectDetails}
        setOpen={setSendQuoteModalSelected}
      />
      <ExistingProjectAddressModal />
    </View>
  );
}

function InfoToolTip() {
  const { projectRecord } = useContext(InstantQuoteContext);

  let message = "Send a copy to my mail";
  let alternateMessage =
    "Complete project configuration and select an engineering package level to complete your quote";

  const Css = `
  .JoyTooltip, .css-1r881y3-JoyTooltip-root{
  inset: 'auto auto 14px auto';
}
  `;
  return (
    <>
      <style>{Css}</style>
      <Tooltip
        sx={{
          bgcolor: "rgb(102,0,102,1)",
          color: "#fff",
        }}
        title={
          <Typography
            sx={{ m: 1, borderRadius: "12px", fontSize: "18px", color: "#fff" }}
          >
            {projectRecord?.totalValue ? message : alternateMessage}
          </Typography>
        }
        placement="top"
        arrow
        variant="solid"
        size="lg"
      >
        <View display="flex" fontSize="18px">
          Send
          <GadgetIcons
            transform={"rotate(-15deg)"}
            width={"auto"}
            overrides={{
              GadgetIcons: {
                paths: [
                  {
                    d: "M2.01 3.03L9.52 6.25L2 5.25L2.01 3.03L2.01 3.03ZM9.51 11.75L2 14.97L2 12.75L9.51 11.75L9.51 11.75ZM0.00999999 0L0 7L15 9L0 11L0.00999999 18L21 9L0.00999999 0Z",
                    fill: "#fff",
                    fillRule: "nonzero",
                    style: { transform: "translate(8.33%, 12.5%)" },
                  },
                ],
              },
            }}
            height={"28px"}
            type="send"
            margin={"0 8px"}
          />
        </View>
      </Tooltip>
    </>
  );
}
