// React
import { useContext } from "react";
import { BsBuilding, BsGeoAlt } from "react-icons/bs";

// Amplify
import {
  Card,
  Flex,
  Text,
  Badge,
  Icon,
  View,
  Heading,
} from "@aws-amplify/ui-react";

// Gadget Engineering Imports
import { InstantQuoteContext } from "../Context";
import package_perfectionist from "../Assets/package_perfectionist.jpg";
import package_gist from "../Assets/package_gist.jpg";
import package_minimalist from "../Assets/package_minimalist.jpg";
import package_collaborator from "../Assets/package_collaboratorV1.jpg";

const QuoteSummaryCard = (props) => {
  const { quoteDetails, quoteDisciplines, quoteSqft, ...rest } = props;

  // Constants and Context
  const { addCommaToPrice, isQuoteView } = useContext(InstantQuoteContext);

  const buildingName = {
    restaurant: "Restaurant",
    warehouse: "Shell or Warehouse",
    brewery: "Brewery, Coffee or Similar",
    office: "Office or Religious",
    multiFamily: "Multifamily",
    medical: "Medical",
  };

  const packageImage = {
    GIST: package_gist,
    MINIMALIST: package_minimalist,
    COLLABORATOR: package_collaborator,
    PERFECTIONIST: package_perfectionist,
  };
  // Function Declarations

  function properNaming(field) {
    if (field) {
      const putSpace = field.split("_").join(" ");
      let name = "";
      for (const word of putSpace.split(" ")) {
        name +=
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + " ";
      }
      return name;
    } else return "";
  }
  const Css = `
.container {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  overflow-y:scroll;
}
// .container::-webkit-scrollbar { 
//   width:18px;
// }
// .container::-webkit-scrollbar-track {
//   background: #ffffff;
// }

// .container::-webkit-scrollbar-thumb {
//   background-color: #d9d9d9;
//   border-radius: 10px;
//   border: 3px solid #ffffff;
// }
`;
  return (
    <>
      <style>{Css}</style>
      <Card
        className="container"
        minWidth={"550px"}
        maxHeight={"800px"}
        width="auto"
        margin="10px"
        border="12px solid #E8E8E8"
        borderRadius={"18px"}
        box-shadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      >
        <Flex borderRadius={"12px"} direction="column">
          <Flex width={"100%"} justifyContent={"space-around"}>
            <Text fontWeight={"bold"}>
              {quoteDetails.preferredContractPackage}
            </Text>
            <Text fontWeight={"bold"}>
              {addCommaToPrice(quoteDetails.totalValue)}
            </Text>
          </Flex>

          <Flex
            style={{
              backgroundImage: `url(${
                packageImage[quoteDetails.preferredContractPackage]
              })`,
              backgroundSize: "cover",
              opacity: "0.7",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
            direction="column"
            alignItems={"center"}
            backgroundColor={"grey"}
            borderRadius="11px"
          >
            <View
              display="flex"
              direction="column"
              justifyContent={"center"}
              minHeight="120px"
              width="100%"
              borderRadius="11px"
              textAlign="center"
              backgroundColor="hsl(0deg 0% 100% / 80%)"
            >
              <Text fontWeight={"bold"} color="#000">
                {quoteDetails.name}
              </Text>
              <Text fontWeight={"bold"} color="#000">
                <Icon as={BsGeoAlt} fontSize="25px" textAlign={"center"} />
                {quoteDetails.address}
              </Text>
              <Text fontWeight={"bold"} color="#000">
                <Icon as={BsBuilding} fontSize="25px" textAlign={"center"} />
                {quoteDetails.OrgName}
              </Text>
            </View>
          </Flex>
          {/* Authorizer Details -> to show only in quotes view */}
          {isQuoteView && (
            <Flex direction="column" alignItems={"center"}>
              <Text fontWeight={"bold"} color="#000">
                Requested Authorizer
              </Text>
              <Flex alignItems={"center"}>
                <Badge padding={"18px"}>
                  {quoteDetails.authorizerName} <br /> <br />{" "}
                  {quoteDetails.authorizerEmail}
                </Badge>
                {/* <Badge>{quoteDetails.authorizerEmail}</Badge> */}
              </Flex>
            </Flex>
          )}
          {/* Disciplines */}
          <Flex direction="column" alignItems={"center"}>
            <Text fontWeight={"bold"} color="#000">
              Trades
            </Text>
            <Flex>
              {Object.keys(quoteDisciplines).map((department) => {
                if (quoteDisciplines[department] === 1)
                  return <Badge>{properNaming(department)}</Badge>;
              })}
            </Flex>
          </Flex>

          {/* constructionType */}
          <Flex direction="row" justifyContent={"center"} alignItems={"center"}>
            <Text fontWeight={"bold"} color="#000">
              Construction Type :
            </Text>
            <Badge>{properNaming(quoteDetails.constructionType)}</Badge>
          </Flex>
          {quoteDetails.surveyType && (
            <Flex
              direction="row"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Text fontWeight={"bold"} color="#000">
                Site Visit :
              </Text>
              <Badge>{properNaming(quoteDetails.surveyType)}</Badge>
            </Flex>
          )}
          {/* quoteSqft */}
          <Flex direction="column" alignItems={"center"}>
            <Text fontWeight={"bold"} color="#000">
              Square Footage :
            </Text>
            <Flex wrap={"wrap"} direction={"column"} alignItems={"center"}>
              {Object.keys(buildingName).map((building) => {
                if (quoteSqft[building] > 0)
                  if (building === "multiFamily")
                    return (
                      <Badge>{`${buildingName[building]} (${quoteSqft[building]} sq.ft)(${quoteSqft["uniqueLayouts"]} MultiFam Unique Layouts)`}</Badge>
                    );
                  else
                    return (
                      <Badge>{`${buildingName[building]} (${quoteSqft[building]} sq.ft)`}</Badge>
                    );
              })}
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </>
  );
};

export default QuoteSummaryCard;
