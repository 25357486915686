/* eslint-disable */
import * as React from 'react';
import { Organization } from '../models';
import { SortDirection } from '@aws-amplify/datastore';
import {
  getOverrideProps,
  useDataStoreBinding,
} from '@aws-amplify/ui-react/internal';
import GadgetCompanyCard from './GadgetCompanyCard';
import { Collection } from '@aws-amplify/ui-react';
export default function CompanyCardSearch(props) {
  const { items: itemsProp, overrideItems, overrides, ...rest } = props;
  const itemsPagination = { sort: (s) => s.name(SortDirection.ASCENDING) };
  const [items, setItems] = React.useState(undefined);
  const itemsDataStore = useDataStoreBinding({
    type: 'collection',
    model: Organization,
    pagination: itemsPagination,
  }).items;
  React.useEffect(() => {
    if (itemsProp !== undefined) {
      setItems(itemsProp);
      return;
    }

    async function setItemsFromDataStore() {
      var loaded = await Promise.all(
        itemsDataStore.map(async (item) => ({
          ...item,
          projects: await item.projects.toArray(),
          quotes: await item.quotes.toArray(),
          contracts: await item.contracts.toArray(),
        }))
      );
      setItems(loaded);
    }

    setItemsFromDataStore();
  }, [itemsProp, itemsDataStore]);

  const Css = `
  .amplify-icon{
    color:#000;
  }
  `

  return (
    <>
    <style>{Css}</style>
    <Collection
      type='list'
      isSearchable={true}
      isPaginated={true}
      searchPlaceholder='Search...'
      itemsPerPage={3}
      alignItems='stretch'
      direction='column'
      justifyContent='stretch'
      items={items || []}
      {...getOverrideProps(overrides, 'CompanyCardSearch')}
      {...rest}>
      {(item, index) => (
        <GadgetCompanyCard
          organization={item}
          height='auto'
          width='250px'
          margin='16px 8px 16px 8px'
          key={item.id}
          {...(overrideItems &&
            overrideItems({ item, index }))}></GadgetCompanyCard>
      )}
    </Collection>
    </>
  );
}
